import React from 'react';
import { useField, UseFieldConfig } from 'react-final-form';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { chakra, Input } from '@chakra-ui/react';
import FormControl, { BaseProps } from '../FormControl';

const ChakraDatePicker = chakra(ReactDatePicker);

declare type DatePickerControlProps<FieldValue> = BaseProps &
  Pick<
    ReactDatePickerProps,
    | 'showYearDropdown'
    | 'selected'
    | 'showMonthDropdown'
    | 'dropdownMode'
    | 'startDate'
    | 'endDate'
    | 'maxDate'
    | 'minDate'
    | 'yearDropdownItemNumber'
    | 'scrollableYearDropdown'
    | 'dateFormatCalendar'
    | 'showTimeSelect'
    | 'showTimeSelectOnly'
    | 'timeIntervals'
    | 'timeCaption'
    | 'dateFormat'
    | 'maxTime'
    | 'minTime'
    | 'selectsRange'
    | 'highlightDates'
    | 'onChange'
  > & {
    config?: UseFieldConfig<FieldValue>;
    isDisabled?: boolean;
    variant?: string;
    placeholder?: string;
  };

export const DateRangePickerControl = ({
  name,
  label,
  helperText,
  config,
  formControlProps,
  formLabelProps,
  isDisabled,
  placeholder,
  ...props
}: DatePickerControlProps<Date>) => {
  const { input } = useField(name, config);

  return (
    <FormControl
      isDisabled={isDisabled}
      name={name}
      label={label}
      helperText={helperText}
      formLabelProps={formLabelProps}
      formControlProps={formControlProps}
    >
      <ChakraDatePicker
        inline
        placeholderText={placeholder}
        onChange={input.onChange}
        selected={input.value}
        disabled={isDisabled}
        customInput={<Input />}
        {...props}
      />
    </FormControl>
  );
};
