const baseStyle = {
  pointerEvents: 'none',
  pt: 2,
  px: 3,
  fontSize: 'xs',
  fontWeight: 'semibold',
  position: 'absolute',
  display: 'block',
  top: 0,
  left: 0,
  zIndex: 1,
  color: 'gray.900',
  transition: 'all 0.2s',
  opacity: 1,
  transitionProperty: 'common',
  transitionDuration: 'normal',
  _disabled: {
    opacity: 0.4,
  },
  _invalid: {
    color: 'red.500',
  },
};

export default {
  baseStyle,
};
