import React, { useEffect, useContext } from 'react';
import { Box } from '@chakra-ui/react';
import ReactGA from 'react-ga';
import { AbilityContext } from '@roo_src/context';
import { defineAbilityFor } from '@roo_src/config/ability';
import StateMessages from '@roo_components/molecules/StateMessages';
import { RouteComponentProps } from 'react-router';
import Loadable from 'react-loadable';
import index from '@roo_components/pages/index';
import CustomRoute from '@roo_src/Router';
import { useFacebookPixel } from '@roo_src/hooks';
import { setCookie } from '@roo_src/helpers';
import PrivateRoute from '@roo_src/PrivateRoute';

const contact = Loadable({
  loader: () => import('@roo_components/pages/staticPages/ContactUs'),
  loading: StateMessages.loading,
});

const about = Loadable({
  loader: () => import('@roo_components/pages/staticPages/AboutUs'),
  loading: StateMessages.loading,
});

const staticContent = Loadable({
  loader: () => import('@roo_components/pages/staticPages/StaticContent'),
  loading: StateMessages.loading,
});

const Tla = Loadable({
  loader: () => import('@roo_src/components/pages/tla'),
  loading: StateMessages.loading,
});

const RegisterAssist = Loadable({
  loader: () => import('@roo_src/components/pages/RegisterAssist'),
  loading: StateMessages.loading,
});

const Account = Loadable({
  loader: () => import('@roo_src/components/pages/user/account'),
  loading: StateMessages.loading,
});

const questions = Loadable({
  loader: () => import('@roo_src/components/pages/questions'),
  loading: StateMessages.loading,
});

const LandingPage = Loadable({
  loader: () => import('@roo_src/components/pages/Landing'),
  loading: StateMessages.loading,
});

const Reports = Loadable({
  loader: () => import('@roo_src/components/pages/reports'),
  loading: StateMessages.loading,
});

const Referrals = Loadable({
  loader: () => import('@roo_src/components/pages/ReferralUsers'),
  loading: StateMessages.loading,
});

const Invitations = Loadable({
  loader: () => import('@roo_src/components/pages/InviteUsers'),
  loading: StateMessages.loading,
});

const Dashboard = Loadable({
  loader: () => import('@roo_src/components/pages/AdminDashboard'),
  loading: StateMessages.loading,
});
const AdminAssists = Loadable({
  loader: () => import('@roo_src/components/pages/AdminDashboard/Assists'),
  loading: StateMessages.loading,
});

const CustomerService = Loadable({
  loader: () => import('@roo_src/components/pages/CustomerService'),
  loading: StateMessages.loading,
});
const TravelManager = Loadable({
  loader: () => import('@roo_src/components/pages/TravelManager'),
  loading: StateMessages.loading,
});

const App = ({ viewer }: RouteComponentProps & GenericProps) => {
  useFacebookPixel(process.env.REACT_APP_FACEBOOK_PIXEL);
  ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID);
  ReactGA.pageview(window.location.pathname + window.location.search);

  const ability = useContext(AbilityContext);

  useEffect(() => {
    if (!viewer) setCookie('redirectTo', window.location.href);
  }, [viewer]);

  useEffect(() => {
    if (viewer) {
      defineAbilityFor(ability, viewer);
    }
  }, [ability, viewer]);

  return (
    <Box>
      <PrivateRoute path="/account">
        <Account />
      </PrivateRoute>

      <CustomRoute path="/tla" Component={Tla} />
      <CustomRoute path="/about" exact Component={about} />
      <PrivateRoute
        exact
        path="/reports"
        authorize={(_, viewer) =>
          viewer?.roles.includes('account_manager') ||
          viewer?.roles.includes('travel_manager') ||
          viewer?.roles.includes('tmc') ||
          viewer?.roles.includes('user') ||
          viewer?.roles.includes('legaroo:admin') ||
          viewer?.roles.includes('legaroo:superadmin')
        }
      >
        <Reports />
      </PrivateRoute>
      <PrivateRoute
        path="/customer-service"
        authorize={(_, viewer) =>
          viewer?.roles.includes('legaroo:customer_service') ||
          viewer?.roles.includes('legaroo:admin') ||
          viewer?.roles.includes('legaroo:superadmin') ||
          viewer?.roles.includes('travel_manager')
        }
      >
        <CustomerService />
      </PrivateRoute>
      <PrivateRoute
        path="/dashboard"
        authorize={(_, viewer) =>
          viewer?.roles.includes('account_manager') ||
          viewer?.roles.includes('legaroo:admin') ||
          viewer?.roles.includes('legaroo:superadmin')
        }
      >
        <Dashboard />
      </PrivateRoute>
      <PrivateRoute
        path="/admin/assist"
        authorize={(_, viewer) =>
          viewer?.roles.includes('account_manager') ||
          viewer?.roles.includes('legaroo:admin') ||
          viewer?.roles.includes('legaroo:superadmin')
        }
      >
        <AdminAssists />
      </PrivateRoute>
      <PrivateRoute
        path="/travel-manager"
        authorize={(_, viewer) =>
          viewer?.roles.includes('account_manager') ||
          viewer?.roles.includes('tmc')
        }
      >
        <TravelManager />
      </PrivateRoute>

      <CustomRoute
        exact
        name="contact_us"
        path="/contact"
        Component={contact}
      />
      <CustomRoute
        name="terms-of-use"
        path="/terms-of-use"
        Component={staticContent}
      />
      <CustomRoute
        name="register-assist"
        path="/claim-assist"
        Component={RegisterAssist}
      />
      <CustomRoute
        name="privacy-policy"
        path="/privacy-policy"
        Component={staticContent}
      />
      <CustomRoute name="questions" path="/questions" Component={questions} />
      <CustomRoute path="/referrals" exact Component={Referrals} />
      <CustomRoute path="/invitations" exact Component={Invitations} />
      <CustomRoute path="/invitation/:token" Component={LandingPage} />
      <CustomRoute path="" exact Component={index} />
    </Box>
  );
};

export default App;
