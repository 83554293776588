/* eslint-disable @typescript-eslint/no-unused-vars */

import React from 'react';
import ReactSelect, {
  components as selectComponents,
  GroupTypeBase,
  OptionTypeBase,
  Props as ReactSelectProps,
  SelectComponentsConfig,
  StylesConfig,
  Theme,
} from 'react-select';
import {
  chakra,
  Flex,
  Tag,
  TagCloseButton,
  TagLabel,
  Divider,
  CloseButton,
  Center,
  StylesProvider,
  useMultiStyleConfig,
  useStyles,
  useTheme,
  useColorModeValue,
  Icon,
  RecursiveCSSObject,
  CSSWithMultiValues,
} from '@chakra-ui/react';
import { FiChevronDown } from 'react-icons/fi';

interface ItemProps extends CSSWithMultiValues {
  _disabled: CSSWithMultiValues;
  _focus: CSSWithMultiValues;
}

// eslint-disable-next-line
export interface SelectProps<
  OptionType extends OptionTypeBase = { label: string; value: string },
  IsMulti extends boolean = false,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
> extends ReactSelectProps<OptionType, IsMulti, GroupType> {}

const chakraStyles: SelectProps['styles'] = {
  input: provided => ({
    ...provided,
    color: 'inherit',
    lineHeight: 1,
  }),
  menu: provided => ({
    ...provided,
    boxShadow: 'none',
    zIndex: 2,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0.125rem 1rem',
  }),
};

const chakraComponents: SelectProps['components'] = {
  // Control components
  Control: ({
    children,
    innerRef,
    innerProps,
    isDisabled,
    isFocused,
    selectProps,
    getStyles,
    ...props
  }) => {
    const inputStyles = useMultiStyleConfig('Input', {
      ...selectProps.selectProps,
    });

    const {
      borderColor,
      borderStyle,
      borderWidth,
      boxShadow,
      minHeight,
      ...styles
    } = getStyles('control', props) as any;
    return (
      <StylesProvider value={inputStyles}>
        <Flex
          ref={innerRef}
          sx={{
            ...inputStyles.field,
            ...styles,
            p: 0,
            overflow: 'hidden',
          }}
          {...innerProps}
          {...(isFocused && { 'data-focus': true })}
          {...(isDisabled && { disabled: true })}
        >
          {children}
        </Flex>
      </StylesProvider>
    );
  },
  // eslint-disable-next-line
  MultiValueContainer: ({
    children,
    innerRef,
    innerProps,
    data: { isFixed },
  }) => (
    <Tag
      ref={innerRef}
      {...innerProps}
      m="0.125rem"
      variant={isFixed ? 'solid' : 'subtle'}
    >
      {children}
    </Tag>
  ),
  // eslint-disable-next-line
  MultiValueLabel: ({ children, innerRef, innerProps }) => (
    <TagLabel ref={innerRef} {...innerProps}>
      {children}
    </TagLabel>
  ),
  // eslint-disable-next-line
  MultiValueRemove: ({ children, innerRef, innerProps, data: { isFixed } }) => {
    if (isFixed) {
      return null;
    }

    return (
      <TagCloseButton ref={innerRef} {...innerProps}>
        {children}
      </TagCloseButton>
    );
  },
  // eslint-disable-next-line
  IndicatorSeparator: ({ innerProps }) => (
    <Divider {...innerProps} orientation="vertical" opacity="1" />
  ),
  // eslint-disable-next-line
  ClearIndicator: ({ innerProps }) => (
    <CloseButton {...innerProps} size="sm" mx={2} />
  ),
  // eslint-disable-next-line
  DropdownIndicator: ({ innerProps }) => {
    const { addon } = useStyles();

    return (
      <Center
        {...innerProps}
        sx={{
          ...addon,
          h: '100%',
          borderRadius: 0,
          borderWidth: 0,
          cursor: 'pointer',
          px: 2,
        }}
        pr={{ base: 2, sm: 1 }}
      >
        <Icon as={FiChevronDown} color="gray.900" h={5} w={5} />
      </Center>
    );
  },
  // Menu components
  // MenuPortal: ({ children }) => <Portal>{children}</Portal>,
  Menu: ({ children, ...props }) => {
    const menuStyles = useMultiStyleConfig('Menu', {});
    return (
      <selectComponents.Menu {...props}>
        <StylesProvider value={menuStyles}>{children}</StylesProvider>
      </selectComponents.Menu>
    );
  },
  // eslint-disable-next-line
  MenuList: ({
    innerRef,
    cx,
    getStyles,
    children,
    maxHeight,
    isMulti,
    className,
    ...props
  }) => {
    const { list } = useStyles();
    return (
      <chakra.div
        sx={{
          ...list,
          maxH: `${maxHeight}px`,
          overflowY: 'auto',
        }}
        __css={getStyles('menuList', props)}
        className={cx(
          {
            'menu-list': true,
            'menu-list--is-multi': isMulti,
          },
          className
        )}
        ref={innerRef}
      >
        {children}
      </chakra.div>
    );
  },
  // eslint-disable-next-line
  GroupHeading: ({ innerProps, children }) => {
    const { groupTitle } = useStyles();
    return (
      <chakra.div sx={groupTitle} {...innerProps}>
        {children}
      </chakra.div>
    );
  },
  // eslint-disable-next-line
  Option: ({ innerRef, innerProps, children, isFocused, isDisabled }) => {
    const { item } = useStyles();
    return (
      <chakra.div
        role="button"
        sx={{
          ...item,
          w: '100%',
          textAlign: 'left',
          bg: isFocused
            ? (item as RecursiveCSSObject<ItemProps>)._focus.bg
            : 'transparent',
          ...(isDisabled && (item as RecursiveCSSObject<ItemProps>)._disabled),
        }}
        ref={innerRef}
        {...innerProps}
        {...(isDisabled && { disabled: true })}
      >
        {children}
      </chakra.div>
    );
  },
};

export function Select<
  OptionType extends OptionTypeBase = { label: string; value: string },
  IsMulti extends boolean = false,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>({
  name = '',
  styles = {},
  components = {},
  theme,
  ...props
}: SelectProps<OptionType, IsMulti, GroupType>) {
  const chakraTheme = useTheme();
  const placeholderColor = useColorModeValue(
    chakraTheme.colors.gray[400],
    chakraTheme.colors.whiteAlpha[400]
  );

  return (
    <ReactSelect
      name={name}
      components={{
        ...((chakraComponents as unknown) as SelectComponentsConfig<
          OptionType,
          IsMulti,
          GroupType
        >),
        ...components,
      }}
      styles={{
        ...((chakraStyles as unknown) as StylesConfig<
          OptionType,
          IsMulti,
          GroupType
        >),
        ...styles,
      }}
      theme={baseTheme => ({
        ...baseTheme,
        borderRadius: chakraTheme.radii.md,
        colors: {
          ...baseTheme.colors,
          neutral50: placeholderColor, // placeholder text color
          neutral40: placeholderColor, // noOptionsMessage color
          ...(theme as Theme)?.colors,
        },
        spacing: {
          ...baseTheme.spacing,
          ...(theme as Theme)?.spacing,
        },
      })}
      {...props}
    />
  );
}
