import React from 'react';
import { useField, UseFieldConfig } from 'react-final-form';
import { Input, InputProps } from '@chakra-ui/react';
import { FormControl, BaseProps } from '../FormControl';

export type TextControlProps<FieldValue> = BaseProps &
  InputProps & {
    config?: UseFieldConfig<FieldValue>;
  };

export function TextControl<FieldValue extends InputProps['value']>({
  name,
  label,
  helperText,
  formControlProps,
  formLabelProps,
  config,
  variant,
  isDisabled,
  ...inputProps
}: TextControlProps<FieldValue>) {
  const { input } = useField<FieldValue>(name, config);
  return (
    <FormControl
      name={name}
      isDisabled={isDisabled}
      label={label}
      helperText={helperText}
      formLabelProps={formLabelProps}
      formControlProps={formControlProps}
    >
      <Input {...input} id={name} variant={variant} {...inputProps} />
    </FormControl>
  );
}
