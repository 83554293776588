import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { Button, VStack, Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { PasswordControl, TextControl } from '@roo_components/atoms/Fields';
import { useFormValidation } from '@roo_src/hooks';

export default function SignupForm({ onSubmit, initialValues }: any) {
  const { required, email } = useFormValidation();
  const { t } = useTranslation();
  return (
    <FinalForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting, pristine }) => (
        <form
          onSubmit={handleSubmit}
          defaultValue={initialValues}
          className="legaroo-form"
          noValidate
        >
          <VStack spacing={4}>
            <TextControl
              name="user_metadata.first_name"
              placeholder={t('fields.firstName.placeholder')}
              label={t('fields.firstName.label')}
              config={{ validate: required() }}
            />
            <TextControl
              name="user_metadata.last_name"
              placeholder={t('fields.lastName.placeholder')}
              label={t('fields.lastName.label')}
              config={{ validate: required() }}
            />
            <TextControl
              name="email"
              placeholder={t('fields.email.placeholder')}
              label={t('fields.email.label')}
              config={{
                validate: email(),
                format: value => value && value.trim(),
                formatOnBlur: true,
              }}
            />
            <PasswordControl
              name="password"
              placeholder={t('fields.password.placeholder')}
              label={t('fields.password.label')}
            />
          </VStack>

          <Box w="100%">
            <Button
              my={4}
              type="submit"
              variant="solid"
              colorScheme="primary"
              size="roo"
              isDisabled={pristine}
              isLoading={submitting}
            >
              Sign Up
            </Button>
          </Box>
        </form>
      )}
    />
  );
}
