import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  ReactNode,
} from 'react';
import { useHistory } from 'react-router-dom';
import SignupModal from '@roo_components/organisms/SignupModal';
import SigninModal from '@roo_components/organisms/SigninModal';
import InviteModal from '@roo_components/organisms/InviteModal';
import ForgotPasswordModal from '@roo_components/organisms/ForgotPasswordModal';
import ResendVerificationEmailModal from '@roo_src/components/organisms/ResendVerificationEmailModal';
import { useDisclosure, UseDisclosureReturn } from '@chakra-ui/react';

export interface AuthContext {
  signin: UseDisclosureReturn;
  signup: UseDisclosureReturn;
  invite: UseDisclosureReturn;
  forgotPassword: UseDisclosureReturn;
  resendEmailVerification: UseDisclosureReturn;
}

export const AuthModalContext = createContext<AuthContext | undefined>(
  undefined
);

export function useAuthModalContext() {
  const context = useContext(AuthModalContext);
  return context as AuthContext;
}

export function useSigninModal() {
  const { signin } = useAuthModalContext();
  const context = {
    open: signin.onOpen,
    close: signin.onClose,
  };
  return context;
}

export function useSignupModal() {
  const { signup } = useAuthModalContext();
  const context = {
    open: signup.onOpen,
    close: signup.onClose,
  };
  return context;
}
export function useInviteModal() {
  const { invite } = useAuthModalContext();
  const context = {
    open: invite.onOpen,
    close: invite.onClose,
  };
  return context;
}

export function useResendEmailVerificationModal() {
  const { resendEmailVerification } = useAuthModalContext();
  const context = {
    open: resendEmailVerification.onOpen,
    close: resendEmailVerification.onClose,
  };
  return context;
}

export function useForgotPasswordModal() {
  const { forgotPassword, signin } = useAuthModalContext();
  const onOpen = useCallback(() => {
    forgotPassword.onOpen();
    signin.onClose();
  }, [forgotPassword, signin]);
  const context = {
    open: onOpen,
    close: forgotPassword.onClose,
  };
  return context;
}

export type AuthModalContextProviderProps = { children: ReactNode };

export const AuthModalContextProvider = ({
  children,
}: AuthModalContextProviderProps) => {
  const history = useHistory();
  const signupModal = useDisclosure();
  const signinModal = useDisclosure();
  const inviteModal = useDisclosure();
  const forgotPasswordModal = useDisclosure();
  const resendEmailVerificationModal = useDisclosure();

  const contextValue = useMemo(() => {
    return {
      signup: signupModal,
      signin: signinModal,
      invite: inviteModal,
      forgotPassword: forgotPasswordModal,
      resendEmailVerification: resendEmailVerificationModal,
    };
  }, [signupModal, signinModal, inviteModal, forgotPasswordModal]);

  const handleSigninClick = useCallback(() => {
    signupModal.onClose();
    resendEmailVerificationModal.onClose();
    signinModal.onOpen();
  }, [signupModal, signinModal]);

  const handleSignupClick = useCallback(() => {
    signinModal.onClose();
    resendEmailVerificationModal.onClose();
    signupModal.onOpen();
  }, [signupModal, signinModal, resendEmailVerificationModal]);

  const handleResendEmailVerificationClick = useCallback(() => {
    signinModal.onClose();
    resendEmailVerificationModal.onOpen();
  }, [signinModal, resendEmailVerificationModal]);

  const signinOnClose = useCallback(() => {
    signinModal.onClose();
    history.replace({ ...history.location, state: {} });
  }, [signinModal, history]);

  const inviteOnClose = useCallback(() => {
    inviteModal.onClose();
  }, [inviteModal]);
  return (
    <AuthModalContext.Provider value={contextValue}>
      {children}
      <SignupModal
        isOpen={signupModal.isOpen}
        onSigninClick={handleSigninClick}
        onClose={signupModal.onClose}
      />
      <InviteModal
        onClose={inviteOnClose}
        isOpen={inviteModal.isOpen}
        onSigninClick={handleSigninClick}
      />
      <SigninModal
        isOpen={signinModal.isOpen}
        onSignupClick={handleSignupClick}
        onClose={signinOnClose}
        onResendEmailVerificationClick={handleResendEmailVerificationClick}
      />
      <ForgotPasswordModal />
      <ResendVerificationEmailModal
        isOpen={resendEmailVerificationModal.isOpen}
        onClose={resendEmailVerificationModal.onClose}
        onSignupClick={handleSignupClick}
        onSigninClick={handleSigninClick}
      />
    </AuthModalContext.Provider>
  );
};
