/* eslint-disable @typescript-eslint/camelcase */

import React, { createContext, ReactNode, useContext } from 'react';
import { AuthOptions } from 'auth0-js';

export const Auth0Context = createContext<AuthOptions | undefined>(undefined);

export function useAuth0Context() {
  const context = useContext(Auth0Context);
  return context as AuthOptions;
}
export interface Auth0ProviderProps extends AuthOptions {
  children: ReactNode;
}

export const Auth0Provider = ({ children, ...props }: Auth0ProviderProps) => {
  const overrides = {
    // __tenant: 'roodev',
    __token_issuer: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/`,
    __jwks_uri: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/.well-known/jwks.json`,
  };
  return (
    <Auth0Context.Provider value={{ ...props, overrides }}>
      {children}
    </Auth0Context.Provider>
  );
};
