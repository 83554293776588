import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import client from './apollo/ApolloClient';
import { ViewerDocument } from './apollo/_hooks.generated';

export type TRoute = RouteProps & {
  name?: string;
  exact?: boolean;
  path?: string;
  Component?: any;
  isPrivate?: boolean;
  hideAuth?: boolean;
  onRender?: any;
  parentProps?: any;
  redirectTo?: string;
  hardRedirect?: string;
  memberRedirect?: string;
};

const CustomRoute = ({
  Component,
  exact,
  name,
  path,
  isPrivate,
  hardRedirect,
  memberRedirect,
  onRender,
  parentProps,
}: TRoute) => {
  return (
    <Route
      exact={exact}
      path={`/:locale${path}`}
      render={props => {
        if (isPrivate) {
          const {
            match: { params: locale },
          } = props;
          try {
            const result: any = client.readQuery({ query: ViewerDocument });
            if (result.viewer === undefined) {
              return <Redirect to={{ pathname: `/${locale.locale}` }} />;
            }
          } catch (err) {
            return <Redirect to={{ pathname: `/${locale.locale}` }} />;
          }
        }
        if (hardRedirect) {
          return (
            <Redirect
              to={{
                pathname: hardRedirect,
              }}
            />
          );
        }

        if (memberRedirect) {
          const {
            match: { params: locale },
          } = props;
          try {
            const result: any = client.readQuery({ query: ViewerDocument });
            if (result.viewer && result.viewer.membership) {
              return (
                <Redirect
                  to={{ pathname: `/${locale.locale}/${memberRedirect}` }}
                />
              );
            }
          } catch (err) {
            return <Redirect to={{ pathname: `/${locale.locale}` }} />;
          }
        }

        if (onRender) {
          onRender(props);
        }

        return <Component {...props} {...parentProps} pageName={name} />;
      }}
    />
  );
};

export default CustomRoute;
