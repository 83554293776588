import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { formatDate } from '@roo_src/helpers';

export enum DateFormat {
  short = 'LL',
  readable = 'MMM Do, YYYY',
  default = 'YYYY-MM-DD',
  normal = 'MM-DD-YYYY',
}

interface DateProps extends BoxProps {
  date: string | Date;
  format?: 'short' | 'readable' | 'default' | string;
  className?: string;
}

const Date = ({ date, format, ...props }: DateProps) => {
  const finalFormat = DateFormat[format] ?? format;
  return (
    <Box as="span" {...props}>
      {formatDate(date, finalFormat)}
    </Box>
  );
};

Date.defaultProps = {
  className: '',
  format: 'default',
};

export default Date;
