import React, { useEffect, useMemo } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { useAuthenticate } from '@roo_src/hooks';
import Loader from '@roo_src/components/atoms/Loader';
import { getCookie } from '@roo_src/helpers';

const AuthCallback = (props: RouteComponentProps) => {
  const {
    location: { hash },
  } = props;

  const { authenticate, loading, error } = useAuthenticate();

  const redirectTo = useMemo(() => {
    const path = getCookie('path') ?? '/';
    const returnToPath = path.includes('/auth/callback') ? '/' : path;
    return returnToPath;
  }, []);

  useEffect(() => {
    (async function auth() {
      if (!hash) {
        return;
      }
      await authenticate({ hash });
    })();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <Redirect
        to={{
          pathname: redirectTo,
          state: { signinError: error },
        }}
      />
    );
  }

  return null;
};

export default AuthCallback;
