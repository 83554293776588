import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  validPhone as validPhoneUtils,
  composeValidators,
  minLength,
  maxLength,
  required as requiredUtils,
  validPassword as validPasswordUtils,
  validEmail as validEmailUtils,
} from '@roo_src/helpers';

export function useFormValidation() {
  const { t } = useTranslation();

  const validations = useMemo(() => {
    return {
      validPhone: () =>
        composeValidators(
          validPhoneUtils(t('validate.invalid_phone')),
          minLength(5, t('validate.min_length', { count: 5 })),
          maxLength(18, t('validate.max_length', { count: 18 }))
        ),
      password: (message = t('validate.invalid_password')) =>
        composeValidators(
          requiredUtils(t('validate.required')),
          minLength(8, t('validate.min_length', { count: 8 })),
          validPasswordUtils(message)
        ),
      email: (message = t('validate.invalid_email')) =>
        composeValidators(
          requiredUtils(t('validate.required')),
          validEmailUtils(message)
        ),
      required: (message = t('validate.required')) => requiredUtils(message),
    };
  }, [t]);

  return validations;
}
