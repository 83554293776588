import * as React from 'react';
import {
  FormControl,
  Input,
  Icon,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react';

export const TextInput: React.FC<{}> = ({
  input,
  meta,
  label,
  size,
  placeholder,
  prefix,
  variant,
}: any) => {
  return (
    <FormControl isInvalid={meta.touched && meta.error}>
      {label && <FormLabel>{label}</FormLabel>}
      <Input
        {...input}
        size={size || 'lg'}
        placeholder={placeholder}
        variant={variant || null}
        prefix={
          prefix ? (
            <Icon type={prefix} style={{ color: 'rgba(0,0,0,.25)' }} />
          ) : null
        }
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
