import React from 'react';
import { FormLabelProps } from '@chakra-ui/form-control';
import { chakra } from '@chakra-ui/system';
import {
  MultiValueContainer,
  MultiValueLabel,
  NoOptionsMessage,
  SelectControl,
  SelectMenu,
  SelectMenuList,
  SelectMulti,
  SelectOption,
  SelectSearchInput,
} from './SelectMulti';
import { Item, UseSelectMultipleProps } from './useSelectMultiple';
import { defaultOptionFilterFunc } from './utils';

export type AutocompleteProps<T = Item> = UseSelectMultipleProps<T> & {
  formLabelProps?: FormLabelProps;
};

export function Autocomplete<T = Item>(props: AutocompleteProps<T>) {
  const { getItemValue, formLabelProps } = props;

  return (
    <SelectMulti {...props}>
      {({
        isOpen,
        selectedItems,
        filteredItems,
        noOptionsMessage,
        inputValue,
      }) => {
        const noOptionsMsg = noOptionsMessage(inputValue);
        const showNoOptionsMsg = isOpen && filteredItems.length <= 0;
        return (
          <>
            {selectedItems && (
              <MultiValueContainer>
                {selectedItems.map((selectedItem, index) => (
                  <MultiValueLabel
                    key={`selected-item-${index}`}
                    index={index}
                    selectedItem={selectedItem}
                  />
                ))}
              </MultiValueContainer>
            )}
            <chakra.div pos="relative">
              <SelectControl formLabelProps={formLabelProps}>
                <SelectSearchInput />
              </SelectControl>
              <SelectMenu>
                <SelectMenuList>
                  {filteredItems.map((item, index) => (
                    <SelectOption
                      index={index}
                      item={item}
                      key={`${getItemValue(item)}-${index}`}
                    />
                  ))}
                  {showNoOptionsMsg && (
                    <NoOptionsMessage>{noOptionsMsg}</NoOptionsMessage>
                  )}
                </SelectMenuList>
              </SelectMenu>
            </chakra.div>
          </>
        );
      }}
    </SelectMulti>
  );
}

Autocomplete.defaultProps = {
  itemToString: i => (i === null ? '' : ((i as unknown) as Item).label),
  getItemValue: i => (i === null ? '' : ((i as unknown) as Item).value),
  noOptionsMessage: () => 'No options',
  filterOption: defaultOptionFilterFunc,
};
