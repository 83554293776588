import React, { useState } from 'react';
import 'react-dates/initialize';
import { Icon } from '@chakra-ui/react';
import {
  DateRangePicker,
  DateRangePickerShape,
  FocusedInputShape,
} from 'react-dates';
import { AiFillCloseCircle } from 'react-icons/ai';

export type DateRangePickerWrapperProps = DateRangePickerShape & {
  autoFocus?: boolean;
  autoFocusEndDate?: boolean;
  stateDateWrapper?: Function;
};

export function DateRangePickerWrapper({
  autoFocus,
  autoFocusEndDate,
  ...props
}: DateRangePickerWrapperProps) {
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    () => {
      if (autoFocus) {
        return 'startDate';
      }
      if (autoFocusEndDate) {
        return 'endDate';
      }
      return null;
    }
  );

  return (
    <DateRangePicker
      focusedInput={focusedInput}
      onFocusChange={focused => setFocusedInput(focused)}
      customCloseIcon={<Icon as={AiFillCloseCircle} />}
      {...props}
    />
  );
}
