import React, { useCallback } from 'react';
import { FORM_ERROR } from 'final-form';
import {
  useToast,
  Button,
  Box,
  Heading,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

// import AuthSidebar from '@roo_src/components/molecules/AuthSidebar';
import ForgotPasswordForm from './ForgotPasswordForm';
import { useTranslation } from 'react-i18next';
import { useAuthModalContext } from '@roo_src/context';
import { useAuth0 } from '@roo_src/hooks';

export default function ForgotPasswordModal() {
  const toast = useToast();
  const { t } = useTranslation();
  const { resetPassword } = useAuth0();
  const { signin, forgotPassword } = useAuthModalContext();
  const onOverlayClick = () => {
    forgotPassword.onClose();
    signin.onOpen();
  };

  const onSubmit = useCallback(
    async ({ email }) => {
      try {
        await resetPassword({ email });
        forgotPassword.onClose();
        toast({
          status: 'success',
          title: 'success',
          description: t('authentication.forgot_password_success', { email }),
        });
      } catch (error) {
        if (error.message === 'User not found') {
          forgotPassword.onClose();
          toast({
            duration: 10000,
            status: 'success',
            title: 'success',
            description: t('authentication.forgot_password_success', { email }),
          });
        } else {
          return { [FORM_ERROR]: error.message };
        }
      }
    },
    [toast, t, forgotPassword, resetPassword]
  );

  return (
    <div className="auth--page">
      <Modal size="xl" isOpen={forgotPassword.isOpen} onClose={onOverlayClick}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Box maxW="md" mx="auto">
              <Box px={4} py={10}>
                <Heading as="h3" textAlign="center" fontSize="3xl">
                  Reset Password
                </Heading>
                <Text my={4}>
                  We'll email you instructions on how to reset your password.
                </Text>
                <ForgotPasswordForm onSubmit={onSubmit} />
                <Text mt={4} textAlign="center">
                  Or have an account?{' '}
                  <Button
                    variant="link"
                    color="primary"
                    onClick={onOverlayClick}
                  >
                    Sign In
                  </Button>
                </Text>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}
