const colors = {
  // primary: '#0747a6',
  primary: '#005AE0',
  secondary: '#fd7622',
  'primary-darker': '#07357a ',
  'primary-lighter': '#60a8df',
  'navy-blue': '#024171',
  'segur-vending': '#006397',
  'dark-gray': '#505050',
  'sky-blue': '#E5F0FF',
  orange: '#FF991F',
  tealAlpha: {
    50: 'rgb(229, 240, 255, 0.96)',
  },
  teal: {
    50: '#e5f0ff',
  },
  skyBlue: {
    20: '#F2F7FF',
    50: '#E5F0FF',
    100: '#A0C4F9',
    200: '#DCEAFF',
  },
  'roo-orange': {
    DEFAULT: '#FF991F',
    '50': '#FFFFFF',
    '100': '#FFF6EB',
    '200': '#FFDFB8',
    '300': '#FFC785',
    '400': '#FFB052',
    '500': '#FF991F',
    '600': '#EB8000',
    '700': '#B86400',
    '800': '#854800',
    '900': '#522D00',
  },
  gray: {
    100: '#f9f9f9',
    200: '#f5f5f5',
    300: '#ececec',
    400: '#d9d9d9',
    500: '#bfbfbf',
    600: '#8c8c8c',
    700: '#595959',
    800: '#424242',
    900: '#212121',
  },
  'regal-blue': {
    DEFAULT: '#024172',
    50: '#5DB7FC',
    100: '#44ABFC',
    200: '#1295FB',
    300: '#047AD6',
    400: '#035EA4',
    500: '#024172',
    600: '#012440',
    700: '#00080E',
    800: '#000000',
    900: '#000000',
  },
  'royal-blue': {
    DEFAULT: '#005AE0',
    '50': '#C6DDFF',
    '100': '#ADCEFF',
    '200': '#7AAFFF',
    '300': '#4791FF',
    '400': '#1472FF',
    '500': '#005AE0',
    '600': '#0046AD',
    '700': '#00317A',
    '800': '#001D47',
    '900': '#000814',
  },
  blue: {
    50: '#C3DDFD',
    100: '#D2E3FB',
    200: '#80B1FA',
    300: '#4F92F8',
    400: '#1E74F6',
    500: '#095CD7',
    600: '#0747A6',
    700: '#053276',
    800: '#031D45',
    900: '#010814',
  },
};

export default colors;
