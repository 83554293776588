import React from 'react';
import { Route, Router, Switch, Redirect } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ability } from '@roo_src/config/ability';
import App from '@roo_components/App';
import Loader from '@roo_components/atoms/Loader';
import { useViewerQuery } from '@roo_apollo/_hooks.generated';
import { languages } from '@roo_src/config/i18n';
import history from '@roo_src/history';
import { AuthModalContextProvider } from '@roo_src/context/AuthModalContext';
import { Auth0Provider } from '@roo_src/context/Auth0Context';
import AuthCallback from './pages/Auth/Callback';
import { ReferralContextProvider } from '@roo_src/context/ReferralContext';
import { AbilityContext } from '@roo_src/context';

/**
 * TODO: test performance of this implementation
 */

function LegarooProvider() {
  const { i18n } = useTranslation();
  const { data, loading } = useViewerQuery();
  const viewer = data?.viewer;

  if (loading) {
    return <Loader />;
  }

  return (
    <Router history={history}>
      <Auth0Provider
        clientID={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
        domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
        redirectUri={`${window.location.origin}${process.env.REACT_APP_AUTH0_REDIRECT}`}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE}
        responseType={process.env.REACT_APP_AUTH0_RESPONSE_TYPE}
        scope={process.env.REACT_APP_AUTH0_SCOPE}
      >
        <AuthModalContextProvider>
          <ReferralContextProvider>
            <AbilityContext.Provider value={ability}>
              <Switch>
                <Route path="/auth/callback" component={AuthCallback} />
                <Route
                  path={`/:locale(${languages.join('|')})`}
                  render={props => <App {...props} viewer={viewer} />}
                />
                <Route
                  render={({ location }) => {
                    return (
                      <Redirect to={`/${i18n.language}${location.pathname}`} />
                    );
                  }}
                />
              </Switch>
            </AbilityContext.Provider>
          </ReferralContextProvider>
        </AuthModalContextProvider>
      </Auth0Provider>
    </Router>
  );
}

export default LegarooProvider;
