import { extendTheme } from '@chakra-ui/react';
import foundations from './foundations';
import components from './components';
import 'react-dates/lib/css/_datepicker.css';
import './cascader.css';
import './fields.css';
import 'react-datepicker/dist/react-datepicker.css';
import './datepicker-control.css';

const theme = extendTheme({
  ...foundations,
  components,
  styles: {
    global: () => ({
      '.hide-on-print': {
        '@media print': {
          display: 'none',
        },
      },
      '@media print': {
        '@page': {
          size: 'A4',
          margin: '11mm 17mm 17mm 17mm',
        },
      },
    }),
  },
  textStyles: {
    secondary: {
      color: 'gray.600',
      fontSize: 'sm',
    },
    strongBlue: {
      color: 'blue.600',
      fontWeight: 800,
    },
    strongYellow: {
      color: 'orange.500',
      fontWeight: 800,
    },
    h1: {
      fontSize: ['4xl', '5xl'],
      fontWeight: 'bold',
    },
    cursive: {
      fontFamily: `"Satisfy", cursive`,
      fontSize: { base: '2xl', sm: '2em' },
      fontWeight: 'medium',
    },
  },
});

export default theme;
