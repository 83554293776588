import React, { useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertProps,
  AlertTitle,
  CloseButton,
  Collapse,
  Icon,
  chakra,
} from '@chakra-ui/react';
import { ReactComponent as WarningSvg } from '@roo_src/icons/warning.svg';

export interface ErrorMessageProps extends AlertProps {
  title?: string;
  message?: string;
  description?: string;
  isClosable?: boolean;
}

export default function ErrorMessage({
  title,
  message,
  description,
  isClosable,
  ...alertProps
}: ErrorMessageProps) {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Collapse in={isOpen} animateOpacity>
      <Alert
        variant="roo"
        status="error"
        alignItems="start"
        textAlign="left"
        width="auto"
        rounded="xl"
        boxShadow="lg"
        {...alertProps}
      >
        <chakra.span display="inherit" mr={3} w={8} h={8} rounded="xl">
          <Icon viewBox="0 0 24 24" w="100%" h="100%" as={WarningSvg} />
        </chakra.span>
        <chakra.div flex="1">
          {title && <AlertTitle>{title}</AlertTitle>}
          <AlertDescription>{message || description}</AlertDescription>
        </chakra.div>
        {isClosable && (
          <CloseButton
            position="absolute"
            color="#6381A9"
            right={2}
            top={2}
            onClick={() => setIsOpen(false)}
          />
        )}
      </Alert>
    </Collapse>
  );
}
