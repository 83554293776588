import React, { createContext, ReactNode, useContext } from 'react';
import { FormLabel } from '@chakra-ui/form-control';
import { Input, InputProps } from '@chakra-ui/input';
import { Tag, TagCloseButton, TagLabel } from '@chakra-ui/tag';
import { dataAttr, runIfFn } from '@chakra-ui/utils';
import {
  chakra,
  FormLabelProps,
  HTMLChakraProps,
  StylesProvider,
  TagProps,
  useMultiStyleConfig,
  useStyles,
} from '@chakra-ui/react';
import {
  GetItemPropsOptions,
  UseMultipleSelectionGetSelectedItemPropsOptions,
} from 'downshift';
import {
  useSelectMultiple,
  UseSelectMultipleProps,
  Item,
  UseSelectMultipleReturn,
} from './useSelectMultiple';
import { defaultOptionFilterFunc } from './utils';

export type SelectMultiProps<T = Item> = UseSelectMultipleProps<T> & {
  children: MaybeRenderProp<{
    isOpen: boolean;
    highlightedIndex: number | null;
    onClose?(): void;
    inputValue: string | null;
    selectedItems: T[];
    filteredItems: T[];
    noOptionsMessage?(inputValue: string | null): string | null;
  }>;
};

const SelectContext = createContext(undefined);

function useSelect<T = Item>() {
  const context = useContext(SelectContext);

  return context as UseSelectMultipleReturn<T>;
}

function SelectProvider<T = Item>({
  value,
  ...props
}: {
  value: UseSelectMultipleReturn<T>;
  children: ReactNode;
}) {
  return <SelectContext.Provider value={value} {...props} />;
}

export type SelectControlProps = HTMLChakraProps<'div'> & {
  formLabelProps?: FormLabelProps;
};
export function SelectControl({
  formLabelProps,
  children,
  ...props
}: SelectControlProps) {
  const { getLabelProps, label } = useSelect();
  return (
    <chakra.div pos="relative" {...props}>
      {label && (
        <FormLabel {...formLabelProps} {...getLabelProps()}>
          {label}
        </FormLabel>
      )}
      {children}
    </chakra.div>
  );
}

export type SelectSearchInputProps = InputProps;
export function SelectSearchInput(props: SelectSearchInputProps) {
  const {
    getInputProps,
    getComboboxProps,
    getDropdownProps,
    isOpen,
  } = useSelect();
  return (
    <chakra.div {...getComboboxProps()}>
      <Input
        {...getInputProps(getDropdownProps({ preventKeyAction: isOpen }))}
        {...props}
      />
    </chakra.div>
  );
}

export type MultiValueContainerProps = HTMLChakraProps<'div'>;
export function MultiValueContainer(props: MultiValueContainerProps) {
  return (
    <chakra.div
      d="flex"
      alignItems="center"
      flexWrap="wrap"
      overflow="hidden"
      pos="relative"
      mb={1}
      {...props}
    />
  );
}

export type MultiValueLabel<
  T = Item
> = UseMultipleSelectionGetSelectedItemPropsOptions<T> & TagProps;
export function MultiValueLabel<T = Item>({
  selectedItem,
  index,
  ...props
}: MultiValueLabel<T>) {
  const { getSelectedItemProps, itemToString, removeSelectedItem } = useSelect<
    T
  >();
  return (
    <Tag
      colorScheme="royal-blue"
      variant="solid"
      m="2px"
      {...getSelectedItemProps({ selectedItem, index })}
      {...props}
    >
      <TagLabel>{itemToString(selectedItem)}</TagLabel>
      <TagCloseButton
        onClick={e => {
          e.stopPropagation();
          removeSelectedItem(selectedItem);
        }}
        aria-label="Remove menu selection badge"
      />
    </Tag>
  );
}

export type SelectMenuProps = HTMLChakraProps<'div'>;
export function SelectMenu(props: SelectMenuProps) {
  const styles = useStyles();
  return <chakra.div __css={styles.menu} {...props} />;
}

export type SelectMenuListProps = HTMLChakraProps<'ul'>;
export function SelectMenuList(props: SelectMenuListProps) {
  const styles = useStyles();
  const { isOpen, getMenuProps } = useSelect();
  return (
    <chakra.ul
      __css={styles.list}
      d={isOpen ? 'block' : 'none'}
      {...getMenuProps()}
      {...props}
    />
  );
}

export type SelectOptionProps<T> = HTMLChakraProps<'li'> &
  Omit<GetItemPropsOptions<T>, 'isSelected'>;
export function SelectOption<T = Item>({
  index,
  item,
  disabled,
  ...props
}: SelectOptionProps<T>) {
  const styles = useStyles();
  const {
    getItemProps,
    getItemValue,
    highlightedIndex,
    itemToString,
    selectedItemValues,
  } = useSelect<T>();
  return (
    <chakra.li
      data-disabled={dataAttr(disabled)}
      __css={styles.option}
      bg={highlightedIndex === index ? 'gray.100' : 'inherit'}
      key={`${getItemValue(item)}-${index}`}
      {...getItemProps({ item, index })}
      {...props}
    >
      <chakra.span
        fontWeight={
          selectedItemValues.includes(getItemValue(item))
            ? 'semibold'
            : 'normal'
        }
        d="block"
        isTruncated
      >
        {itemToString(item)}
      </chakra.span>
    </chakra.li>
  );
}

export type NoOptionsMessageProps = HTMLChakraProps<'li'>;
export function NoOptionsMessage(props: NoOptionsMessageProps) {
  const styles = useStyles();
  return <chakra.li __css={styles.option} {...props} />;
}

export function SelectMulti<T = Item>({
  children,
  ...props
}: SelectMultiProps<T>) {
  const styles = useMultiStyleConfig('SelectMultiple', {});
  const downshift = useSelectMultiple<T>(props);
  return (
    <StylesProvider value={styles}>
      <SelectProvider value={downshift as UseSelectMultipleReturn<T>}>
        {runIfFn(children, {
          inputValue: downshift.inputValue,
          isOpen: downshift.isOpen,
          highlightedIndex: downshift.highlightedIndex,
          selectedItems: downshift.selectedItems,
          filteredItems: downshift.filteredItems,
          noOptionsMessage: downshift.noOptionsMessage,
        })}
      </SelectProvider>
    </StylesProvider>
  );
}

SelectMulti.defaultProps = {
  itemToString: i => (i === null ? '' : ((i as unknown) as Item).label),
  getItemValue: i => (i === null ? '' : ((i as unknown) as Item).value),
  noOptionsMessage: () => 'No options',
  filterOption: defaultOptionFilterFunc,
};
