import React from 'react';
import { Button, Text, Box } from '@chakra-ui/react';
import { Field, FormRenderProps, Form as FinalForm } from 'react-final-form';
import { TextInput } from '@roo_components/atoms/Fields';
import { composeValidators, isEmail, required } from '@roo_src/helpers';

export default function ResendVerificationEmailForm({ onSubmit }) {
  return (
    <FinalForm
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, invalid }: FormRenderProps) => (
        <form onSubmit={handleSubmit}>
          <Box mb={4}>
            <Field
              name="email"
              component={TextInput}
              placeholder="Email"
              prefix="mail"
              validate={composeValidators(
                isEmail,
                required('Please input your email!')
              )}
            />
            <Text>
              If you didn't receive an email to verify your email address,
              submit it again and we'll send you a new verification email.
            </Text>
            <Button
              mt={4}
              colorScheme="primary"
              isFullWidth
              type="submit"
              isDisabled={invalid}
              isLoading={submitting}
            >
              Send
            </Button>
          </Box>
        </form>
      )}
    />
  );
}
