import { getColor, mode } from '@chakra-ui/theme-tools';

const parts = ['control', 'menu', 'list', 'option', 'button'];

const baseStyleMenu = {
  pos: 'absolute',
  mt: 2,
  w: 'full',
  zIndex: 10,
  rounded: 'md',
};

function baseStyleList(props: Dict) {
  return {
    maxH: 60,
    pos: 'relative',
    overflowY: 'auto',
    rounded: 'md',
    py: 1,
    w: 'full',
    bg: mode(`#fff`, `gray.700`)(props),
    boxShadow: mode(`lg`, `dark-lg`)(props),
    fontSize: { base: 'md', sm: 'sm' },
    ring: 1,
    ringColor: 'gray.300',
  };
}

function baseStyleOption(props: Dict) {
  return {
    py: 2,
    pl: 3,
    pr: { base: 6, sm: 9 },
    color: mode(`gray.900`, `gray.50`)(props),
    pos: 'relative',
    userSelect: 'none',
    cursor: 'default',
    _focus: {
      bg: mode(`gray.100`, `whiteAlpha.100`)(props),
    },
    _active: {
      bg: mode(`gray.100`, `whiteAlpha.200`)(props),
    },
    _expanded: {
      bg: mode(`gray.100`, `whitxeAlpha.100`)(props),
    },
    // _selected: {
    //   bg: 'gray.50',
    //   fontWeight: 'semibold',
    // },
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed',
    },
  };
}

function getDefaults(props: Dict) {
  const { focusBorderColor: fc, errorBorderColor: ec } = props;
  return {
    focusBorderColor: fc || mode('primary', 'blue.300')(props),
    errorBorderColor: ec || mode('red.500', 'red.300')(props),
  };
}

function baseStyleControl(props: Dict) {
  const { theme } = props;
  const { errorBorderColor: ec } = getDefaults(props);
  return {
    bg: 'white',
    fontWeight: 'semibold',
    position: 'relative',
    w: 'full',
    border: '1px',
    borderColor: 'transparent',
    rounded: 'md',
    shadow: 'base',
    textAlign: 'left',
    cursor: 'default',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    minH: 10,
    transition: 'all 0.2s',
    outline: 0,
    pt: 8,
    pb: 2,
    px: { base: 4, md: 5 },
    _focusWithin: {
      outline: 'none',
      borderColor: 'gray.400',
      boxShadow: `0 0 0 1px ${getColor(theme, 'gray.400')}`,
    },
    _focus: {
      outline: 'none',
      borderColor: 'gray.400',
      boxShadow: `0 0 0 1px ${getColor(theme, 'gray.400')}`,
    },
    _readOnly: { boxShadow: 'none !important', userSelect: 'all' },
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed',
    },
    _hover: {
      borderColor: 'gray.300',
      _disabled: {
        borderColor: 'gray.300',
      },
    },
    _invalid: {
      color: getColor(theme, ec),
      boxShadow: `0 0 0 1px ${getColor(theme, ec)}`,
      borderColor: getColor(theme, ec),
    },
  };
}

const baseStyle = (props: Dict) => ({
  menu: baseStyleMenu,
  list: baseStyleList(props),
  option: baseStyleOption(props),
  control: baseStyleControl(props),
  button: {
    zIndex: 0,
    pos: 'absolute',
    inset: 0,
    w: '100%',
    h: '100%',
    cursor: 'default',
    _focus: { outline: 'none' },
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed',
    },
  },
});

export default {
  parts,
  baseStyle,
};
