/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint no-undef: 0 */

import { useCallback, useRef } from 'react';
import {
  WebAuth,
  Auth0Error,
  DbSignUpOptions,
  AuthOptions,
  Auth0DecodedHash,
  Auth0ParseHashError,
  LogoutOptions,
  AuthorizeOptions,
} from 'auth0-js';
import { useAuth0Context } from '@roo_src/context/Auth0Context';

let auth0: WebAuth;

function getAuth0(options: AuthOptions) {
  if (typeof auth0 === `undefined`) {
    auth0 = new WebAuth(options);
  }
  return auth0;
}

export type SignupOptions = Omit<DbSignUpOptions, 'connection'>;

export type SigninOptions = {
  email: string;
  password: string;
};

export type ParseHashReturn = {
  accessToken: string;
};

export class Auth0UnknownHashError extends Error {}

export const useAuth0 = () => {
  const authRef = useRef<WebAuth | null>(null);
  const authOptions = useAuth0Context();

  const getAuth = useCallback(() => {
    if (authRef.current === null) {
      authRef.current = getAuth0(authOptions);
    }
    return authRef.current;
  }, [authOptions]);

  const signup = useCallback(
    ({ email, password, userMetadata }: SignupOptions) => {
      return new Promise<any>((done, reject) => {
        getAuth().signup(
          {
            email,
            password,
            connection: 'Username-Password-Authentication',
            userMetadata,
          },
          (error: Auth0Error | null, result) => {
            if (error) {
              reject(error);
            }
            done(result);
          }
        );
      });
    },
    [getAuth]
  );

  const signin = useCallback(
    ({ email, password }: SigninOptions) => {
      return new Promise((done, reject) => {
        getAuth().login(
          {
            email,
            password,
            realm: 'Username-Password-Authentication',
          },
          // eslint-disable-next-line
          (err: Auth0Error | null, data: any) => {
            if (err) {
              reject(err);
            }
            done(data);
          }
        );
      });
    },
    [getAuth]
  );

  const parseHash = useCallback(
    (hash: string): Promise<ParseHashReturn> => {
      return new Promise<ParseHashReturn>((done, reject) => {
        if (!/access_token|id_token|error/.test(hash)) {
          return reject(new Auth0UnknownHashError('Unknown Hash'));
        }
        getAuth().parseHash(
          (
            err: Auth0ParseHashError | null,
            authResult: Auth0DecodedHash | null
          ) => {
            if (err) {
              reject(err);
            }

            return done({
              accessToken: authResult?.accessToken as string,
            });
          }
        );
      });
    },
    [getAuth]
  );

  const signout = useCallback(
    (options: LogoutOptions) => {
      getAuth().logout(options);
    },
    [getAuth]
  );

  const authorize = useCallback(
    (options?: AuthorizeOptions) => {
      getAuth().authorize(options);
    },
    [getAuth]
  );
  const resetPassword = useCallback(
    ({ email }: { email: string }) => {
      return new Promise((done, reject) => {
        getAuth().changePassword(
          {
            email,
            connection: 'Username-Password-Authentication',
          },
          // eslint-disable-next-line
          (err: Auth0Error | null, data: any) => {
            if (err) {
              reject(err);
            }
            done(data);
          }
        );
      });
    },
    [getAuth]
  );

  return { signup, signin, parseHash, signout, authorize, resetPassword };
};

export default useAuth0;
