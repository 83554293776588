/* eslint-disable */
import client from '@roo_src/apollo/ApolloClient';
import translations from '@roo_src/apollo/translations.gql';

class Backend {
  type: string = 'backend';

  services: any = undefined;

  options: any = undefined;

  init(services: any, options: any) {
    this.services = services;
  }

  read(
    language: string,
    namespace: string,
    callback: (namespace: string, translations: GenericKeyValue) => void
  ) {
    // const loadURL = `${
    //   process.env.API
    // }/translations?language={{lng}}&namespace={{ns}}`;
    // const url = this.services.interpolator.interpolate(loadURL, {
    //   lng: language,
    //   ns: namespace,
    // });

    /**
     * TODO: update when api exists
     * this.loadURL(url, callback);
     */
    import(`@roo_src/i18n/${language || 'en'}`).then((data: any) => {
      callback(null, data.default);
    });
  }

  async loadURL(language: string, callback: any) {
    let res;
    let err;
    try {
      const { data } = client.readQuery({
        query: translations.GET,
        variables: {
          lang: language,
        },
      });
      res = data.translations;
    } catch (error) {
      err = `failed fetching url ${language}`;
    }
    if (err) callback(err, false);
    callback(null, res);
  }
}

export default Backend;
