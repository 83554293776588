import React from 'react';
import { useField, UseFieldConfig } from 'react-final-form';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react';
import { BaseProps } from '../FormControl';
import { Combobox, ComboboxProps, Item } from '../../Combobox';

export type ComboboxControlProps<FieldValue> = BaseProps &
  ComboboxProps<FieldValue> & {
    config?: UseFieldConfig<FieldValue>;
  };

export function ComboboxControl<FieldValue = Item>({
  name,
  helperText,
  formControlProps,
  config,
  isDisabled,
  ...comboboxProps
}: ComboboxControlProps<FieldValue>) {
  const {
    input,
    meta: { error, touched },
  } = useField(name, config);
  return (
    <FormControl
      name={name}
      isInvalid={!!error && touched}
      isDisabled={isDisabled}
      outline="none"
      appearance="none"
      d="inline-flex"
      verticalAlign="top"
      flexDirection="column"
      {...input}
      {...formControlProps}
    >
      <Combobox<FieldValue>
        selectedItem={input.value}
        onSelectedItemChange={({ selectedItem }) =>
          input.onChange(selectedItem)
        }
        {...comboboxProps}
      />
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
