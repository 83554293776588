import React, { forwardRef, useCallback, useMemo } from 'react';
import moment from 'moment';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import DateBox from '@roo_components/atoms/Date';
import {
  chakra,
  Text,
  Button,
  Switch,
  FormControl as ChakraFormControl,
  Input,
  useDisclosure,
  Icon,
  HStack,
  Divider,
} from '@chakra-ui/react';
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';
import { BsArrowRight } from 'react-icons/bs';
import { useField, UseFieldConfig } from 'react-final-form';
import { BaseProps, FormControl } from '../FormControl';
import { styles } from './style';
import { formatDate } from '@roo_src/helpers';

const ChakraDatePicker = chakra(ReactDatePicker);

export type DateRange = Date[];

export type DateRangeYearlyControlProps = BaseProps &
  Pick<
    ReactDatePickerProps,
    | 'showYearDropdown'
    | 'showMonthDropdown'
    | 'dropdownMode'
    | 'maxDate'
    | 'minDate'
    | 'yearDropdownItemNumber'
    | 'scrollableYearDropdown'
    | 'dateFormatCalendar'
    | 'showTimeSelect'
    | 'showTimeSelectOnly'
    | 'timeIntervals'
    | 'timeCaption'
    | 'dateFormat'
    | 'maxTime'
    | 'minTime'
    | 'inline'
  > & {
    config?: UseFieldConfig<DateRange>;
    isDisabled?: boolean;
    placeholder?: string;
    title?: string;
  };

const DEFAULT_FORMAT = 'DD/MM/YYYY';

const CustomInput = forwardRef(({ dates, ...props }: any, ref: any) => {
  const customValue = useMemo(() => {
    const [start, end] = dates;
    const startFormatted = start && formatDate(start, DEFAULT_FORMAT);
    const endFormatted = end && formatDate(end, DEFAULT_FORMAT);
    if (startFormatted) {
      return `${startFormatted} - ${endFormatted ? endFormatted : ''}`;
    }
    return '';
  }, [dates]);
  return <Input ref={ref} {...props} value={customValue} />;
});
CustomInput.displayName = 'CustomInput';

function renderCustomHeader({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) {
  return (
    <chakra.div
      d="flex"
      my={2}
      px={3}
      justifyContent="space-between"
      alignItems="center"
      bg="white"
    >
      <DateBox format="MMMM YYYY" date={date} fontSize="sm" />

      <div>
        <chakra.button
          type="button"
          d="inline-flex"
          alignItems="center"
          color="gray.600"
          _hover={{ color: 'gray.700' }}
          aria-label=""
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
        >
          <Icon as={HiOutlineChevronLeft} h={5} w={5} />
        </chakra.button>
        <chakra.button
          type="button"
          d="inline-flex"
          alignItems="center"
          color="gray.600"
          _hover={{ color: 'gray.700' }}
          aria-label=""
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
        >
          <Icon as={HiOutlineChevronRight} h={5} w={5} />
        </chakra.button>
      </div>
    </chakra.div>
  );
}

export function DateRangeYearlyControl({
  name,
  label,
  config,
  isDisabled,
  helperText,
  placeholder,
  formLabelProps,
  formControlProps,
  title = 'Select Date',
  ...props
}: DateRangeYearlyControlProps) {
  const { input } = useField<DateRange>(`${name}.range`, config);
  const { input: yearlyInput } = useField<boolean>(`${name}.yearlyPass`, {
    initialValue: false,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isYearly = yearlyInput.value;
  const onDatesChange = useCallback(
    dates => {
      if (isYearly) {
        const end = moment(dates)
          .add(1, 'year')
          .toDate();
        input.onChange([dates, end]);
      } else {
        const [start, end] = dates;
        input.onChange([start, end]);
      }
    },
    [isYearly]
  );
  const [start, end] = input.value ?? [];

  const handleYearlyPassToggle = useCallback(
    e => {
      yearlyInput.onChange(e.target.checked);
      input.onChange([undefined, undefined]);
    },
    [yearlyInput, input]
  );

  const DatePickerContainer = ({ className, children }) => {
    return (
      <chakra.div
        sx={styles}
        bg="white"
        overflow="hidden"
        className={className}
        boxShadow="0px 0px 20px rgba(0, 0, 0, 0.15)"
        rounded="lg"
        border={0}
        pb={4}
      >
        <chakra.div
          backgroundColor="primary"
          px={{ base: 4, sm: 6 }}
          py={4}
          color="white"
          textAlign="left"
        >
          <Text color="white" fontSize="sm" fontWeight="semibold">
            {title}
          </Text>
          <ChakraFormControl display="flex" alignItems="center" mt={1}>
            <chakra.label color="white" fontSize="sm" mb={0} marginEnd={2}>
              Annual Pass
            </chakra.label>
            <Switch
              size="sm"
              id="yearly"
              isChecked={isYearly}
              onChange={handleYearlyPassToggle}
              colorScheme="roo-orange"
            />
          </ChakraFormControl>
        </chakra.div>
        <chakra.div pos="relative" d="flex" flexDir="column">
          <chakra.div flex="1 1 0%" px={{ base: 4, sm: 6 }}>
            {children}
          </chakra.div>
          <chakra.div bg="white" flexShrink={0}>
            <Divider borderColor="#E7F1FF" />
            <chakra.div
              d="flex"
              justifyContent="space-around"
              alignItems="center"
              mt={4}
              px={8}
            >
              <chakra.div w="full" textAlign="center">
                {start ? (
                  <DateBox date={start} fontSize="sm" format={DEFAULT_FORMAT} />
                ) : (
                  <span>Start date</span>
                )}
              </chakra.div>
              <Icon as={BsArrowRight} h={6} w="auto" />
              <chakra.div w="full" textAlign="center">
                {end ? (
                  <DateBox date={end} fontSize="sm" format={DEFAULT_FORMAT} />
                ) : (
                  <span>End date</span>
                )}
              </chakra.div>
            </chakra.div>

            <HStack mt={4} justifyContent="center">
              <Button
                variant="outline"
                colorScheme="primary"
                onClick={onClose}
                size="sm"
                minW={20}
              >
                Cancel
              </Button>
              <Button
                variant="solid"
                colorScheme="primary"
                onClick={onClose}
                size="sm"
                minW={20}
              >
                Done
              </Button>
            </HStack>
          </chakra.div>
        </chakra.div>
      </chakra.div>
    );
  };

  return (
    <FormControl
      isDisabled={isDisabled}
      name={`${name}.range`}
      label={label}
      helperText={helperText}
      formLabelProps={formLabelProps}
      formControlProps={formControlProps}
    >
      <ChakraDatePicker
        open={isOpen}
        onFocus={onOpen}
        onCalendarClose={onClose}
        customInput={<CustomInput dates={input.value} />}
        onChange={onDatesChange}
        selected={start}
        {...(!isYearly && {
          selectsRange: true,
          endDate: end,
          startDate: start,
        })}
        renderCustomHeader={renderCustomHeader}
        showPopperArrow={false}
        shouldCloseOnSelect={false}
        calendarContainer={DatePickerContainer}
        placeholderText={placeholder}
        disabled={isDisabled}
        {...props}
      />
    </FormControl>
  );
}
