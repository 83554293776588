import React from 'react';
import report from '@roo_src/config/sentry';
import { node } from 'prop-types';

export default class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  };

  static get propTypes() {
    return {
      children: node,
    };
  }

  componentDidCatch(error: any) {
    this.setState({ hasError: true });
    report(error);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return <span> something went wrong </span>;
    }
    return children;
  }
}
