import React, { useCallback } from 'react';
import {
  useToast,
  Text,
  Button,
  Box,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useSendVerificationEmailMutation } from '@roo_src/apollo/_hooks.generated';
import { FORM_ERROR } from 'final-form';
import ResendVerificationEmailForm from './ResendVerificationEmailForm';

export interface ResendVerificationEmailModalProps {
  isOpen: boolean;
  onClose(): void;
  onSignupClick(): void;
  onSigninClick(): void;
}

export default function ResendVerificationEmailModal({
  isOpen,
  onClose,
  onSignupClick,
  onSigninClick,
}: ResendVerificationEmailModalProps) {
  const toast = useToast();
  const [resendVerificationEmail] = useSendVerificationEmailMutation();

  const handleSubmit = useCallback(
    async ({ email }) => {
      try {
        const { data } = await resendVerificationEmail({
          variables: { email },
        });
        if (!data?.sendVerificationEmail?.successful) {
          data?.sendVerificationEmail?.messages?.forEach(error => {
            toast({
              duration: 10000,
              status: 'error',
              title: 'Error',
              description: error?.message,
            });
          });
          return;
        }
        toast({
          duration: 10000,
          status: 'success',
          title: 'Email sent',
          description: 'We have sent a verification email to your inbox.',
        });
        onSigninClick();
      } catch (e) {
        return { [FORM_ERROR]: e.description };
      }
    },
    [toast]
  );
  return (
    <div className="auth--page">
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Box maxW="md" mx="auto">
              <Box
                px={4}
                py={10}
                className="h-100 d-flex flex-column justify-content-center py-5"
              >
                <Heading as="h3" textAlign="center" fontSize="3xl" mb={4}>
                  Resend Verification Email
                </Heading>
                <ResendVerificationEmailForm onSubmit={handleSubmit} />
                <Box
                  d="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDir="column"
                >
                  <Text fontWeight="semibold" className="text-center">
                    New to Legaroo?
                  </Text>
                  <Button
                    variant="link"
                    color="primary"
                    onClick={onSignupClick}
                  >
                    Click here to sign up
                  </Button>
                </Box>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}
