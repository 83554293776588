import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Icon,
  Heading,
  VStack,
  HStack,
  Text,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import ReactGA from 'react-ga';
import AuthSocialButtons from '@roo_components/molecules/AuthSocialButtons';

import { useAuth0 } from '@roo_src/hooks';
// import AuthSidebar from '@roo_src/components/molecules/AuthSidebar';
import FormSignUp from './SignupForm';
import { ReactComponent as LegarooIcon } from '@roo_src/icons/Legaroo-White.svg';
import { ReactComponent as TextureAbst } from '@roo_src/icons/abst-invert.svg';
const URL_TERMS_OF_USE = '/terms-of-use';
const URL_PRIVACY_TERMS = '/privacy-policy';
export interface SignupModalProps {
  isOpen: boolean;
  onClose(): void;
  onSigninClick(): void;
}

export default function SignUpModal({
  isOpen,
  onClose,
  onSigninClick,
}: SignupModalProps) {
  const modalSize = useBreakpointValue({ lg: '3xl' });
  const { t } = useTranslation();
  const { signup } = useAuth0();
  const toast = useToast();
  const classNameVariant = useBreakpointValue({
    base: 'clip-image-top-right',
    lg: '',
  });

  const onSubmit = useCallback(
    async (values: any) => {
      try {
        await signup({
          email: values.email,
          password: values.password,
          userMetadata: {
            ...values.user_metadata,
            // eslint-disable-next-line
            agree_to_policy_and_terms: 'true',
          },
        });
        onClose();
        ReactGA.event({
          category: 'User',
          action: 'Signed Up',
        });
        toast({
          duration: 10000,
          position: 'top-right',
          status: 'success',
          title: t('signup.notifications.success.title'),
          description: t('signup.notifications.success.description'),
        });
      } catch (error) {
        toast({
          duration: 10000,
          position: 'top-right',
          status: 'error',
          title: t('signup.notifications.error.title'),
          description:
            error.code === 'user_exists'
              ? error.description
              : t('signup.notifications.error.description'),
        });
      }
    },
    [onClose, signup, t, toast]
  );

  return (
    <Box className="auth--page">
      <Modal isOpen={isOpen} onClose={onClose} size={modalSize}>
        <ModalOverlay>
          <ModalContent rounded="3xl" mx={{ base: 4, lg: 0 }}>
            <ModalCloseButton
              zIndex={10}
              color={{ base: 'black', lg: 'white' }}
              top={{ base: 2, lg: 4 }}
              right={{ base: 3, lg: 6 }}
            />
            <ModalBody p={0}>
              <Box
                position="relative"
                bg="white"
                overflow={{ base: 'auto', lg: 'hidden' }}
                rounded="3xl"
              >
                <Box
                  position={{ lg: 'relative' }}
                  zIndex={{ lg: 10 }}
                  bg="white"
                  p={{ base: 6, lg: 10 }}
                  w={{ base: '100%', lg: '60%' }}
                >
                  <Icon
                    viewBox="0 0 100 100"
                    fill="currentColor"
                    preserveAspectRatio="none"
                    display="block"
                    position="absolute"
                    right={0}
                    insetY={0}
                    h="full"
                    w={12}
                    color="white"
                    transform="translateX(50%)"
                    d={{ base: 'none', lg: 'flex' }}
                  >
                    <polygon points="50,0 100,0 50,100 0,100" />
                  </Icon>
                  <Heading
                    as="h4"
                    fontWeight="bold"
                    fontSize="sm"
                    color="black"
                    lineHeight={6}
                  >
                    {t('signup.title')}
                  </Heading>
                  <Heading
                    fontSize="2xl"
                    fontWeight="400"
                    lineHeight={6}
                    color="blue.600"
                    my={2}
                    as="h2"
                  >
                    {t('commons.brand')}
                  </Heading>
                  <FormSignUp onSubmit={onSubmit} />
                  <VStack mt={5} spacing={4} align="flex-start">
                    <Text fontSize="sm" color="gray.600">
                      {t('signup.signupOptionsText')}
                    </Text>

                    <AuthSocialButtons />

                    <Box
                      width="100%"
                      d="flex"
                      flexDir="column"
                      justifyContent={{ base: 'flex-end', lg: 'flex-start' }}
                      fontSize="xs"
                    >
                      <Text color="gray.600">
                        {t('signup.footer')} <br />
                      </Text>
                      <HStack
                        spacing={1}
                        justifyContent={{ base: 'flex-end', lg: 'flex-start' }}
                        w="full"
                        fontSize="sm"
                      >
                        <a
                          href={URL_TERMS_OF_USE}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Text
                            color="primary"
                            fontWeight="bold"
                            textDecoration="underline"
                          >
                            {t('commons.termsOfUse')}
                          </Text>
                        </a>
                        <Text>and</Text>
                        <a
                          href={URL_PRIVACY_TERMS}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Text
                            color="primary"
                            fontWeight="bold"
                            textDecoration="underline"
                          >
                            {t('commons.privacy_policy')}
                          </Text>
                        </a>
                      </HStack>
                    </Box>
                  </VStack>
                </Box>
                <Box
                  display="block"
                  bg="primary"
                  position={{ lg: 'absolute' }}
                  insetY={{ lg: 0 }}
                  right={{ lg: 0 }}
                  color="white"
                  w={{ base: '100%', lg: '40%' }}
                  className={classNameVariant}
                >
                  <Icon
                    as={TextureAbst}
                    position="absolute"
                    right={0}
                    bottom={0}
                    transform="translateX(50%) translateY(-2.5rem)"
                    w={56}
                    h="auto"
                  />
                  <Box
                    pt={16}
                    px={6}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Icon as={LegarooIcon} w={48} h="auto" />

                    <Box
                      pt={{ base: 6, lg: 48 }}
                      d="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Text fontSize="sm" mt={4}>
                        {t('signup.alreadyHaveAccount')}
                      </Text>

                      <Button
                        variant="outline"
                        size="md"
                        px={10}
                        mt={4}
                        mb={6}
                        onClick={onSigninClick}
                        colorScheme="white"
                      >
                        {t('signup.signinHere')}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </Box>
  );
}
