import moment from 'moment';

export const formatDate = (date: string | Date, dateFormat = 'YYYY-MM-DD') =>
  moment(date).format(dateFormat);

export const DATE_FORMATS = {
  defaultFormat: 'YYYY/MM/DD',
  datePicker: 'YYYY-MM-DD',
  readableShortDate: 'MMM Do, YYYY',
  readableDate: 'LL',
};

export const minStartDate = moment()
  .add(1, 'days')
  .startOf('day');
export const maxEndDate = moment()
  .add(1, 'years')
  .endOf('day');

export const defaultDateFormat = 'MM/DD/YYYY';

export const maxDatePickerValidator = (
  value: Array<moment.Moment | null> // tslint:disable-line
): string | null => {
  if (value) {
    // eslint-disable-next-line
    const [_, end] = value;
    if (end) {
      return end > maxEndDate
        ? 'The end date must be less than 1 year from today'
        : null;
    }
    return `Dates must be between ${minStartDate.format(
      defaultDateFormat
    )} and ${maxEndDate.format(defaultDateFormat)}`;
  }
  return null;
};

export const minDatePickerValidator = (
  value: Array<moment.Moment | null> // tslint:disable-line
): string | null => {
  if (value) {
    const [start] = value;
    if (start) {
      return start < minStartDate
        ? 'The start date must be greater than the day after today'
        : null;
    }
    return `Dates must be between ${minStartDate.format(
      defaultDateFormat
    )} and ${maxEndDate.format(defaultDateFormat)}`;
  }
  return null;
};

export const isDatePickerDisabled = (day: moment.Moment): boolean => {
  return day.isBefore(minStartDate) || day.isAfter(maxEndDate);
};

export const formatDateToYMD = (date: string): string => {
  return moment(date).format('YYYY-MM-DD');
};
