import React from 'react';
import { Moment } from 'moment';
import { useField, UseFieldConfig } from 'react-final-form';
import {
  DateRangePickerWrapper,
  DateRangePickerWrapperProps,
} from '../../DateRangePicker';
import FormControl, { BaseProps } from '../FormControl';

export type DateRangeControlProps<FieldValue = Moment[]> = BaseProps &
  DateRangePickerWrapperProps & {
    config?: UseFieldConfig<FieldValue>;
  };

export function DateRangeControl({
  name,
  label,
  helperText,
  formControlProps,
  formLabelProps,
  config,
  ...props
}: DateRangeControlProps) {
  const { input } = useField<Moment[]>(name, config);
  const [startDate, endDate] = input.value;
  return (
    <FormControl
      name={name}
      label={label}
      helperText={helperText}
      formLabelProps={formLabelProps}
      formControlProps={formControlProps}
    >
      <DateRangePickerWrapper
        {...props}
        startDate={startDate}
        endDate={endDate}
        onDatesChange={({ startDate, endDate }) =>
          input.onChange([startDate, endDate])
        }
        startDateId="start-date-id"
        endDateId="end-date-id"
        startDatePlaceholderText="Start Date"
        endDatePlaceholderText="End Date"
      />
    </FormControl>
  );
}
