import { ApolloError } from '@apollo/client';
import { Auth0Error } from 'auth0-js';
import { loadStripe, StripeError } from '@stripe/stripe-js';

const rooFormatError = (error: any): string => {
  if (!error || !error.message) return '';
  if (error.graphQLErrors) {
    return error.graphQLErrors.map(({ message }: any) => message).join('\n');
  }
  return error.message.replace(/GraphQL error: /g, '');
};

export function formatError(
  error: Auth0Error | StripeError | ApolloError | Error
) {
  if ((error as Auth0Error).errorDescription) {
    return rooFormatError(error) ?? (error as Auth0Error).errorDescription;
  }
  return rooFormatError(error) ?? (error as Error).message;
}

export const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_TOKEN as string
);

interface CookieOptions {
  days?: number;
  domain?: string;
}

export function setCookie(
  name: string,
  value: string,
  options?: CookieOptions
) {
  const opts = { days: 1, ...options };
  let expires = '';
  if (opts.days) {
    const date = new Date();
    date.setTime(date.getTime() + opts.days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}
export function getCookie(name: string) {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function eraseCookie(name: string) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
