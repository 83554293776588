/* eslint-disable */
const env = process.env.NODE_ENV;
const sentry = require('@sentry/browser');

if (env === 'production' || env === 'staging') {
  sentry.init({ dsn: process.env.SENTRY_DSN });
}

function report(error: any) {
  const reportMessage = `[${env}]: ${error}`;

  if (env === 'production' || env === 'staging') {
    sentry.captureException(reportMessage);
  }
}

export default report;
