import Link from './link';
import Button from './button';
import Input from './input';
import Modal from './modal';
import Table from './table';
import FormLabel from './form-label';
import SelectMultiple from './select-multiple';
import SelectSingle from './select-single';
import Combobox from './combobox';
import Alert from './alert';

export default {
  Link,
  Button,
  Input,
  Modal,
  Table,
  FormLabel,
  SelectMultiple,
  SelectSingle,
  Combobox,
  Alert,
};
