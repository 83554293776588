import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { Box, Button, VStack } from '@chakra-ui/react';
import { PasswordControl, TextControl } from '@roo_components/atoms/Fields';
import { useForgotPasswordModal } from '@roo_src/context';
import ErrorMessage from '@roo_src/components/atoms/ErrorMessage';
import { useFormValidation } from '@roo_src/hooks';

export default function SigninForm({ onSubmit }) {
  const forgotPasswordModal = useForgotPasswordModal();
  const { email } = useFormValidation();
  return (
    <Box my={2} py={4}>
      <FinalForm
        onSubmit={onSubmit}
        render={({ handleSubmit, pristine, submitting, submitError }) => (
          <form onSubmit={handleSubmit} className="legaroo-form">
            {submitError && (
              <Box mb={1}>
                <ErrorMessage message={submitError} />
              </Box>
            )}
            <VStack spacing={4}>
              <TextControl
                label="Email"
                name="email"
                placeholder="Email"
                config={{ validate: email(), type: 'email' }}
              />
              <PasswordControl
                label="Password"
                name="password"
                type="password"
                placeholder="Password"
              />
            </VStack>

            <Box d="flex" alignItems="right" my={2}>
              <Button
                colorScheme="primary"
                variant="link"
                onClick={forgotPasswordModal.open}
              >
                Forgot password
              </Button>
            </Box>
            <Box py={4} w="100%" textAlign="left">
              <Button
                size="roo"
                variant="solid"
                colorScheme="primary"
                isDisabled={pristine}
                isLoading={submitting}
                type="submit"
              >
                Sign In
              </Button>
            </Box>
          </form>
        )}
      />
    </Box>
  );
}
