import React, { ReactNode } from 'react';
import {
  FormControl as ChakraFormControl,
  FormControlOptions,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  FormLabelProps,
} from '@chakra-ui/react';
import { useField } from 'react-final-form';

export interface BaseProps extends FormControlOptions {
  name: string;
  label?: string;
  helperText?: string;
  formControlProps?: FormControlProps;
  formLabelProps?: FormLabelProps;
  children?: ReactNode;
}

export const FormControl = (props: BaseProps) => {
  const {
    children,
    name,
    label,
    helperText,
    formControlProps,
    formLabelProps,
    ...rest
  } = props;
  const {
    meta: { error, touched },
  } = useField(name, { subscription: { touched: true, error: true } });

  return (
    <ChakraFormControl
      isInvalid={!!error && touched}
      outline="none"
      appearance="none"
      d="inline-flex"
      verticalAlign="top"
      flexDirection="column"
      {...formControlProps}
      {...rest}
    >
      {label && (
        <FormLabel htmlFor={name} {...formLabelProps}>
          {label}
        </FormLabel>
      )}
      {children}
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </ChakraFormControl>
  );
};

export default FormControl;
