import React from 'react';
import {
  chakra,
  useStyles,
  RecursiveCSSObject,
  CSSWithMultiValues,
  Image,
} from '@chakra-ui/react';
import {
  OptionTypeBase,
  GroupTypeBase,
  SelectComponentsConfig,
} from 'react-select';
import { SelectControl, SelectControlProps } from '../SelectControl';

interface ItemProps extends CSSWithMultiValues {
  _disabled: CSSWithMultiValues;
  _focus: CSSWithMultiValues;
}

const customComponents = {
  IndicatorSeparator: null,
  // eslint-disable-next-line
  Option: ({
    innerRef,
    innerProps,
    children,
    isFocused,
    isDisabled,
    ...props
  }) => {
    const { item } = useStyles();
    return (
      <chakra.div
        role="button"
        d="flex"
        alignItems="center"
        sx={{
          ...item,
          w: '100%',
          textAlign: 'left',
          bg: isFocused
            ? (item as RecursiveCSSObject<ItemProps>)._focus.bg
            : 'transparent',
          ...(isDisabled && (item as RecursiveCSSObject<ItemProps>)._disabled),
        }}
        ref={innerRef}
        {...innerProps}
        {...(isDisabled && { disabled: true })}
      >
        <Image src={props.data.flag} height="auto" width="20px" mr={1} />
        {children}
      </chakra.div>
    );
  },
};

const countryFlag = (url = '') => ({
  alignItems: 'center',
  display: 'flex',
  ':before': {
    background: `url(${url})`,
    backgroundColor: '#f9f9f9',
    content: '" "',
    display: 'block',
    marginRight: '8px',
    height: '16px',
    width: '20px',
  },
});

export function CountryControl<
  OptionType extends OptionTypeBase = CountryType,
  IsMulti extends boolean = false,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>({
  styles,
  components,
  ...props
}: SelectControlProps<OptionType, IsMulti, GroupType>) {
  return (
    <SelectControl<OptionType, IsMulti, GroupType>
      isSearchable
      components={{
        ...((customComponents as unknown) as SelectComponentsConfig<
          OptionType,
          IsMulti,
          GroupType
        >),
        ...components,
      }}
      styles={{
        input: styles => ({
          ...styles,
          color: 'inherit',
          lineHeight: 1,
          ...countryFlag(),
        }),
        placeholder: styles => ({
          ...styles,
          color: '#595959',
          ...countryFlag(),
        }),
        singleValue: (styles, { data }) => ({
          ...styles,
          ...countryFlag(data.flag),
        }),
        ...styles,
      }}
      {...props}
    />
  );
}
