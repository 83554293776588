import React from 'react';
import { RouteComponentProps } from 'react-router';
import Loadable from 'react-loadable';
import CustomRoute from '@roo_src/Router';
import { useViewerQuery } from '@roo_apollo/_hooks.generated';
import StateMessages from '@roo_components/molecules/StateMessages';
import Loader from '../atoms/Loader';

const MemberIndex = Loadable({
  loader: () => import('@roo_src/components/pages/user'),
  loading: StateMessages.loading,
});

const LandingPage = Loadable({
  loader: () => import('@roo_src/components/pages/Landing'),
  loading: StateMessages.loading,
});

const Index: React.FunctionComponent<RouteComponentProps> = () => {
  const { loading, data } = useViewerQuery();

  if (loading) return <Loader />;
  return (
    <CustomRoute
      path="/"
      exact
      Component={data?.viewer ? MemberIndex : LandingPage}
    />
  );
};

export default Index;
