import '@fontsource/satisfy/400.css';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/800.css';
import './config/i18n';

import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import ReactDOM from 'react-dom';

import ErrorBoundary from '@roo_components/ErrorBoundary';
import LegarooProvider from '@roo_components/LegarooProvider';
import apolloClient from './apollo/ApolloClient';

import * as serviceWorker from './serviceWorker';
import Loader from './components/atoms/Loader';
import { ChakraProvider } from '@chakra-ui/react';
import { LanguageContextProvider } from '@roo_src/context';
import theme from './theme';

ReactDOM.render(
  <ErrorBoundary>
    <Router>
      <ApolloProvider client={apolloClient}>
        <ChakraProvider theme={theme} resetCSS>
          <Suspense fallback={<Loader />}>
            <LanguageContextProvider>
              <Route path="/:locale?" component={LegarooProvider} />
            </LanguageContextProvider>
          </Suspense>
        </ChakraProvider>
      </ApolloProvider>
    </Router>
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
