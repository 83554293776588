import React, { useState } from 'react';
import { matchSorter } from 'match-sorter';
import {
  chakra,
  FormLabel,
  Input,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { useCombobox, UseComboboxProps } from 'downshift';

export interface Item {
  label: string;
  value: string;
}

export type ComboboxProps<T = Item> = UseComboboxProps<T> & {
  label: string;
  getItemValue?: (item?: T | null) => string;
  noOptionsMessage?(inputValue: string | null): string | null;
  filterOption?(args: FilterOptionArgs<T>): T[];
};

export interface FilterOptionArgs<T = Item> {
  items: T[];
  itemToString: (item: T) => string;
  inputValue: string | null;
}

function defaultOptionFilterFunc<T = Item>({
  items,
  inputValue,
  itemToString,
}: FilterOptionArgs<T>) {
  return matchSorter(items, inputValue, {
    keys: [itemToString],
  });
}

function defaultStateReducer(state, actionAndChanges) {
  const { changes, type } = actionAndChanges;
  switch (type) {
    case useCombobox.stateChangeTypes.InputChange:
      return {
        ...changes,
        isOpen: changes.inputValue.length > 0,
      };
    default:
      return changes;
  }
}

export function Combobox<T = Item>({
  items,
  filterOption,
  getItemValue,
  label,
  noOptionsMessage,
  ...props
}: ComboboxProps<T>) {
  const styles = useMultiStyleConfig('Combobox', {});
  const { itemToString } = props;
  const [filteredItems, setFilteredItems] = useState(items);
  const [inputValue, setInputValue] = useState('');
  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectItem,
  } = useCombobox<T>({
    inputValue,
    items: filteredItems,
    onInputValueChange: ({ inputValue }) => {
      const filteredItems = filterOption({ items, itemToString, inputValue });
      setFilteredItems(filteredItems);
      setInputValue(inputValue);
    },
    onStateChange: ({ inputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue);
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (selectedItem) {
            selectItem(selectedItem);
            setInputValue(itemToString(selectedItem));
          }
          break;

        default:
          break;
      }
    },
    initialSelectedItem: props.selectedItem,
    ...props,
  });

  const noOptionsMsg = noOptionsMessage(inputValue);
  const showNoOptionsMsg = isOpen && filteredItems.length <= 0;
  return (
    <chakra.div pos="relative">
      <FormLabel {...getLabelProps()}>{label}</FormLabel>
      <div {...getComboboxProps()}>
        <Input {...getInputProps()} />
      </div>
      <chakra.div __css={styles.menu}>
        <chakra.ul
          __css={styles.list}
          d={isOpen ? 'block' : 'none'}
          {...getMenuProps()}
        >
          {isOpen &&
            filteredItems.map((item, index) => (
              <chakra.li
                __css={styles.option}
                bg={highlightedIndex === index ? 'gray.100' : 'inherit'}
                key={`${getItemValue(item)}`}
                {...getItemProps({ item, index })}
              >
                <chakra.span d="block" isTruncated>
                  {itemToString(item)}
                </chakra.span>
              </chakra.li>
            ))}
          {showNoOptionsMsg && (
            <chakra.li __css={styles.option}>{noOptionsMsg}</chakra.li>
          )}
        </chakra.ul>
      </chakra.div>
    </chakra.div>
  );
}

Combobox.defaultProps = {
  itemToString: i => (i === null ? '' : ((i as unknown) as Item).label),
  getItemValue: i => (!i ? '' : ((i as unknown) as Item).value),
  noOptionsMessage: () => 'No options',
  defaultHighlightedIndex: 0,
  filterOption: defaultOptionFilterFunc,
  stateReducer: defaultStateReducer,
};
