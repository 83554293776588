export const styles = {
  '.react-datepicker__header ': {
    backgroundColor: 'white',
    borderColor: 'white',
  },
  '.react-datepicker__day-name': {
    color: '#96AECF',
    fontWeight: 'semibold',
    textTransform: 'uppercase',
    fontSize: 'sm',
  },
  '.react-datepicker__day--outside-month': {
    color: '#9FC4F9 !important',
  },
  '.react-datepicker__day--keyboard-selected': {
    fontWeight: 'semibold',
  },
  '.react-datepicker__day': {
    fontSize: 'sm',
    borderRadius: 'full',
    color: '#6381A9',
  },
  '.react-datepicker__day--range-start': {
    color: 'white',
  },
  '.react-datepicker__day--in-range': {
    color: 'white !important',
    backgroundColor: '#0067ff85',
  },
  '.react-datepicker__day--selected': {
    color: 'white',
    backgroundColor: 'blue.500',
  },
  '.react-datepicker__day--in-selecting-range': {
    color: 'white !important',
    backgroundColor: '#0067ff85',
  },
  '.react-datepicker__day--disabled': {
    color: 'gray.500',
  },
  '.react-datepicker__day--disabled::hover': {
    color: 'gray.500',
    backgroundColor: 'gray.200',
  },
};
