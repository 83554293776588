import React, { useMemo } from 'react';
import { Input, InputGroup } from '@chakra-ui/input';
import RCCascader from 'rc-cascader';
import arrayTreeFilter from 'array-tree-filter';
import {
  InputProps,
  useControllableState,
  InputRightElement,
  Icon,
} from '@chakra-ui/react';
import { FiChevronDown } from 'react-icons/fi';

export type CascaderValueType = (string | number)[];

export interface CascaderOption {
  value?: string | number;
  label?: React.ReactNode;
  disabled?: boolean;
  isLeaf?: boolean;
  loading?: boolean;
  children?: CascaderOption[];
  [key: string]: any;
}

export interface CascaderFieldNames {
  value?: string | number;
  label?: string;
  children?: string;
}

export interface CascaderProps {
  value?: CascaderValueType;
  defaultValue?: CascaderValueType;
  options?: CascaderOption[];
  onChange?: (
    value: CascaderValueType,
    selectOptions: CascaderOption[]
  ) => void;
  onPopupVisibleChange?: (popupVisible: boolean) => void;
  popupVisible?: boolean;
  disabled?: boolean;
  transitionName?: string;
  popupClassName?: string;
  popupPlacement?: string;
  prefixCls?: string;
  dropdownMenuColumnStyle?: React.CSSProperties;
  dropdownRender?: (menu: React.ReactElement) => React.ReactElement;
  loadData?: (selectOptions: CascaderOption[]) => void;
  changeOnSelect?: boolean;
  children?: React.ReactElement;
  onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;
  expandTrigger?: string;
  fieldNames?: CascaderFieldNames;
  expandIcon?: React.ReactNode;
  loadingIcon?: React.ReactNode;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
  inputProps?: InputProps;
}

export default function Cascader({
  value,
  onChange,
  inputProps,
  options,
  fieldNames,
  ...props
}: CascaderProps) {
  const [internalValue, setInternalValue] = useControllableState({
    value,
    onChange: value => onChange(value, options),
  });

  const label = useMemo(() => {
    return arrayTreeFilter(
      options,
      (o, level) => o[fieldNames.value] === value[level],
      { childrenKeyName: fieldNames.children }
    )
      .map(o => o[fieldNames.label])
      .join(', ');
  }, [options, value, fieldNames]);

  return (
    <RCCascader
      value={internalValue}
      onChange={setInternalValue}
      options={options}
      fieldNames={fieldNames}
      disabled={inputProps.isDisabled}
      {...props}
    >
      <InputGroup size={inputProps.size} variant="outline">
        <Input
          readOnly
          {...inputProps}
          value={label}
          _placeholder={{ color: '#595959' }}
        />
        <InputRightElement
          color={inputProps.isDisabled ? 'gray.500' : 'gray.900'}
        >
          <Icon as={FiChevronDown} h={5} w={5} />
        </InputRightElement>
      </InputGroup>
    </RCCascader>
  );
}
