import React from 'react';
import { Button } from '@chakra-ui/react';
import { Field, FormRenderProps, Form as FinalForm } from 'react-final-form';

import { TextInput } from '@roo_components/atoms/Fields';
import {
  trimValue,
  required,
  isEmail,
  composeValidators,
} from '@roo_src/helpers';

export default function ForgotPasswordForm({ onSubmit }) {
  return (
    <FinalForm
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, invalid }: FormRenderProps) => (
        <form onSubmit={handleSubmit}>
          <Field
            name="email"
            type="email"
            placeholder="Email"
            prefix="mail"
            component={TextInput}
            onBlur={trimValue}
            validate={composeValidators(
              isEmail,
              required('Please input your email!')
            )}
          />
          <Button
            mt={3}
            isFullWidth
            colorScheme="primary"
            type="submit"
            isDisabled={invalid || submitting}
          >
            Reset Password
          </Button>
        </form>
      )}
    />
  );
}
