import React, {
  createContext,
  useContext,
  useMemo,
  ReactNode,
  useState,
  useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';

export interface ReferralContext {
  referralCode: string;
}

const REFERRAL_KEY = '_referral';
export const ReferralContext = createContext<ReferralContext | undefined>(
  undefined
);

export function useReferralContext() {
  const context = useContext(ReferralContext);
  return context as ReferralContext;
}

export type ReferralContextProviderProps = { children: ReactNode };

export const ReferralContextProvider = ({
  children,
}: ReferralContextProviderProps) => {
  const location = useLocation();
  const [referralCode, setReferralCode] = useState(
    sessionStorage.getItem(REFERRAL_KEY) || ''
  );

  // Do this once, when it loads
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    if (searchParams.has('referral') && !!searchParams.get('referral')) {
      const code = searchParams.get('referral');
      setReferralCode(code);
      sessionStorage.setItem(REFERRAL_KEY, code);
    }
  }, [location, setReferralCode]);

  const contextValue = useMemo(() => ({ referralCode }), [referralCode]);

  return (
    <ReferralContext.Provider value={contextValue}>
      {children}
    </ReferralContext.Provider>
  );
};
