import { useMemo, useState } from 'react';
import {
  useCombobox,
  UseComboboxReturnValue,
  useMultipleSelection,
  UseMultipleSelectionProps,
  UseMultipleSelectionReturnValue,
} from 'downshift';

export interface Item {
  label: string;
  value: string;
}

export interface FilterOptionArgs<T = Item> {
  items: T[];
  itemToString: (item: T | null) => string;
  inputValue: string | null;
}

export interface UseSelectMultipleProps<T = Item>
  extends UseMultipleSelectionProps<T> {
  items: T[];
  label: string;
  getItemValue?: (item?: T) => string;
  noOptionsMessage?(inputValue: string | null): string | null;
  filterOption?(args: FilterOptionArgs<T>): T[];
}

export function useSelectMultiple<T = Item>(props: UseSelectMultipleProps<T>) {
  const {
    label,
    getItemValue,
    filterOption,
    items,
    itemToString,
    noOptionsMessage,
  } = props;
  const [inputValue, setInputValue] = useState('');
  const [filteredItems, setFilteredItems] = useState(items);
  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    selectedItems,
  } = useMultipleSelection<T>(props);

  const selectedItemValues = useMemo(
    () => selectedItems.map(item => getItemValue(item)),
    [selectedItems]
  );

  function stateReducer(state, actionAndChanges) {
    const { changes, type } = actionAndChanges;
    switch (type) {
      case useCombobox.stateChangeTypes.InputChange:
        return {
          ...changes,
          isOpen: changes.inputValue.length > 0,
        };
      default:
        return changes;
    }
  }

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectItem,
  } = useCombobox<T>({
    inputValue,
    defaultHighlightedIndex: 0,
    items: filteredItems,
    onInputValueChange: ({ inputValue, selectedItem }) => {
      const filteredItems = filterOption({ items, itemToString, inputValue });
      if (!selectedItem) {
        setFilteredItems(filteredItems);
      }
    },
    stateReducer: stateReducer,
    onStateChange: ({ inputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue);
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (selectedItem) {
            if (selectedItemValues.includes(getItemValue(selectedItem))) {
              removeSelectedItem(selectedItem);
            } else {
              addSelectedItem(selectedItem);
            }
            setInputValue('');
            selectItem(null);
          }

          break;
        default:
          break;
      }
    },
  });
  return {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectItem,
    getSelectedItemProps,
    getDropdownProps,
    filteredItems,
    inputValue,
    selectedItems,
    removeSelectedItem,
    selectedItemValues,
    getItemValue,
    itemToString,
    label,
    noOptionsMessage,
  };
}

export type UseSelectMultipleReturn<T = Item> = Partial<
  UseComboboxReturnValue<T>
> &
  Partial<UseMultipleSelectionReturnValue<T>> &
  Pick<UseMultipleSelectionProps<T>, 'itemToString'> & {
    inputValue: string;
    getItemValue?: (item?: T) => string;
    filteredItems: T[];
    selectedItemValues: string[];
    label?: string;
  };
