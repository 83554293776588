import { Item } from './useSelectMultiple';
import { matchSorter } from 'match-sorter';

export interface FilterOptionArgs<T = Item> {
  items: T[];
  itemToString: (item: T | null) => string;
  inputValue: string | null;
}

export function defaultOptionFilterFunc<T = Item>({
  items,
  inputValue,
  itemToString,
}: FilterOptionArgs<T>) {
  return matchSorter(items, inputValue, {
    keys: [itemToString],
  });
}
