import React from 'react';
import { useField, UseFieldConfig } from 'react-final-form';
import { OptionTypeBase, GroupTypeBase } from 'react-select';
import { Select, SelectProps } from '@roo_src/components/atoms/Select';
import FormControl, { BaseProps } from '../FormControl';

export type SelectControlProps<OptionType, IsMulti, GroupType> = BaseProps &
  // eslint-disable-next-line
  // @ts-ignore
  SelectProps<OptionType, IsMulti, GroupType> & {
    config?: UseFieldConfig<OptionType>;
  };

export function SelectControl<
  OptionType extends OptionTypeBase = { label: string; value: string },
  IsMulti extends boolean = false,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>({
  name,
  label,
  helperText,
  formControlProps,
  formLabelProps,
  config,
  ...selectProps
}: SelectControlProps<OptionType, IsMulti, GroupType>) {
  const { input } = useField(name, config);
  return (
    <FormControl
      name={name}
      label={label}
      helperText={helperText}
      formLabelProps={formLabelProps}
      formControlProps={formControlProps}
    >
      <Select
        id={input.name}
        value={input.value}
        onChange={input.onChange}
        onBlur={input.onBlur}
        onFocus={input.onFocus}
        {...selectProps}
      />
    </FormControl>
  );
}

export default SelectControl;
