import {
  ApolloClient,
  NormalizedCacheObject,
  createHttpLink,
} from '@apollo/client';
import { getContentFulQueries } from './contentful';
import cache from './cache';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
  credentials: 'include',
});

const apolloClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache,
  link: httpLink,
  resolvers: {
    Query: {
      ...getContentFulQueries(),
    },
  },
});

export default apolloClient;
