import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type ProfileFragment = { __typename?: 'UserProfile' } & Pick<
  UserProfile,
  'firstName' | 'lastName' | 'avatar' | 'dob' | 'phone'
>;

export type UserFragment = { __typename?: 'User' } & Pick<
  User,
  'email' | 'permissions' | 'roles'
> & {
    subscription?: Maybe<
      { __typename?: 'UserSubscription' } & Pick<UserSubscription, 'type'>
    >;
    affiliate?: Maybe<{ __typename?: 'Affiliate' } & Pick<Affiliate, 'id'>>;
    profile: { __typename?: 'UserProfile' } & Pick<
      UserProfile,
      'firstName' | 'lastName' | 'avatar' | 'dob' | 'phone'
    >;
  };

export type LanguageFragment = { __typename?: 'LanguageType' } & Pick<
  LanguageType,
  'code' | 'name' | 'slug'
>;

export type ValidationMessageFragment = {
  __typename?: 'ValidationMessage';
} & Pick<ValidationMessage, 'code' | 'message' | 'field' | 'template'> & {
    options?: Maybe<
      Array<
        Maybe<
          { __typename?: 'ValidationOption' } & Pick<
            ValidationOption,
            'key' | 'value'
          >
        >
      >
    >;
  };

export type PaymentMethodFragment = { __typename?: 'PaymentMethodType' } & Pick<
  PaymentMethodType,
  'id' | 'cardBrand' | 'cardLast4' | 'isDefault' | 'expMonth' | 'expYear'
>;

export type GetOwnersTlaQueryVariables = Exact<{
  input: Scalars['String'];
}>;

export type GetOwnersTlaQuery = { __typename?: 'RootQueryType' } & {
  getOwnersTla?: Maybe<
    { __typename?: 'TlaType' } & Pick<
      TlaType,
      'endDate' | 'referralCode' | 'startDate' | 'tlaNumber'
    > & {
        destinations: Array<
          Maybe<
            { __typename?: 'TlaDestinationType' } & {
              country: { __typename?: 'SkinnedCountryType' } & Pick<
                SkinnedCountryType,
                'code' | 'flag' | 'name' | 'slug'
              >;
            }
          >
        >;
        purchase?: Maybe<
          { __typename?: 'PurchaseType' } & Pick<
            PurchaseType,
            | 'bankStatement'
            | 'description'
            | 'invoiceUrl'
            | 'paid'
            | 'insertedAt'
          > & {
              details?: Maybe<
                { __typename?: 'PurchaseDetailsType' } & Pick<
                  PurchaseDetailsType,
                  'total'
                >
              >;
            }
        >;
        travelers?: Maybe<
          Array<
            { __typename?: 'TravelerType' } & Pick<
              TravelerType,
              'email' | 'firstName' | 'lastName'
            > & {
                countryOfResidency?: Maybe<
                  { __typename?: 'SkinnedCountryType' } & Pick<
                    SkinnedCountryType,
                    'code' | 'id' | 'flag' | 'name' | 'slug'
                  >
                >;
              }
          >
        >;
        user: { __typename?: 'User' } & Pick<User, 'email'> & {
            profile: { __typename?: 'UserProfile' } & Pick<
              UserProfile,
              'avatar' | 'dob' | 'firstName' | 'lastName' | 'phone'
            >;
          };
      }
  >;
};

export type GetPaymentMethodsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPaymentMethodsQuery = { __typename?: 'RootQueryType' } & {
  getMyPaymentSources: Array<
    Maybe<{ __typename?: 'PaymentMethodType' } & PaymentMethodFragment>
  >;
};

export type CreatePaymentSourceMutationVariables = Exact<{
  paymentMethodId: Scalars['String'];
  isDefault?: Maybe<Scalars['Boolean']>;
}>;

export type CreatePaymentSourceMutation = {
  __typename?: 'RootMutationType';
} & {
  createPaymentSource?: Maybe<
    { __typename?: 'PaymentMethodPayload' } & Pick<
      PaymentMethodPayload,
      'successful'
    > & {
        messages?: Maybe<
          Array<
            Maybe<
              { __typename?: 'ValidationMessage' } & ValidationMessageFragment
            >
          >
        >;
        result?: Maybe<
          { __typename?: 'PaymentMethodType' } & PaymentMethodFragment
        >;
      }
  >;
};

export type DeletePaymentSourceMutationVariables = Exact<{
  paymentMethodId: Scalars['String'];
}>;

export type DeletePaymentSourceMutation = {
  __typename?: 'RootMutationType';
} & {
  deletePaymentSource?: Maybe<
    { __typename?: 'BooleanPayload' } & Pick<
      BooleanPayload,
      'result' | 'successful'
    > & {
        messages?: Maybe<
          Array<
            Maybe<
              { __typename?: 'ValidationMessage' } & ValidationMessageFragment
            >
          >
        >;
      }
  >;
};

export type SetDefaultPaymentSourceMutationVariables = Exact<{
  paymentMethodId: Scalars['String'];
}>;

export type SetDefaultPaymentSourceMutation = {
  __typename?: 'RootMutationType';
} & {
  setDefaultPaymentSource?: Maybe<
    { __typename?: 'PaymentMethodPayload' } & Pick<
      PaymentMethodPayload,
      'successful'
    > & {
        messages?: Maybe<
          Array<
            Maybe<
              { __typename?: 'ValidationMessage' } & ValidationMessageFragment
            >
          >
        >;
        result?: Maybe<
          { __typename?: 'PaymentMethodType' } & PaymentMethodFragment
        >;
      }
  >;
};

export type GetQuestionAnswerForLocationQueryVariables = Exact<{
  input: AnswerInputType;
}>;

export type GetQuestionAnswerForLocationQuery = {
  __typename?: 'RootQueryType';
} & {
  results: { __typename?: 'AnswerType' } & Pick<
    AnswerType,
    'id' | 'answer' | 'publishedAt'
  > & {
      lawfirm?: Maybe<
        { __typename?: 'LawfirmType' } & Pick<
          LawfirmType,
          'commercialName' | 'id' | 'legalName' | 'logoUrl' | 'website'
        >
      >;
    };
};

export type GetCategoriesWithExistingAnswersQueryVariables = Exact<{
  input: CategoriesInput;
}>;

export type GetCategoriesWithExistingAnswersQuery = {
  __typename?: 'RootQueryType';
} & {
  results: Array<
    Maybe<
      { __typename?: 'CategoryType' } & Pick<CategoryType, 'name' | 'slug'> & {
          topics?: Maybe<
            Array<
              { __typename?: 'TopicType' } & Pick<TopicType, 'name' | 'slug'>
            >
          >;
        }
    >
  >;
};

export type GetClubMembershipQueryVariables = Exact<{
  filterBy?: Maybe<EnumMembershipFilterBy>;
  filterValue: Scalars['String'];
}>;

export type GetClubMembershipQuery = { __typename?: 'RootQueryType' } & {
  getClubMembership?: Maybe<
    Array<
      { __typename?: 'MembershipType' } & Pick<
        MembershipType,
        | 'id'
        | 'memberId'
        | 'email'
        | 'firstName'
        | 'lastName'
        | 'mailingCountry'
        | 'membershipEnrollDate'
        | 'membershipStatus'
        | 'membershipTier'
        | 'inactiveAt'
        | 'ownerId'
      >
    >
  >;
};

export type GetAllClubMembershipsQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;

export type GetAllClubMembershipsQuery = { __typename?: 'RootQueryType' } & {
  getAllClubMemberships?: Maybe<
    { __typename?: 'MembershipTypeConnection' } & {
      edges?: Maybe<
        Array<
          Maybe<
            { __typename?: 'MembershipTypeEdge' } & {
              node?: Maybe<
                { __typename?: 'MembershipType' } & Pick<
                  MembershipType,
                  | 'id'
                  | 'memberId'
                  | 'email'
                  | 'firstName'
                  | 'accountName'
                  | 'lastName'
                  | 'mailingCountry'
                  | 'membershipEnrollDate'
                  | 'membershipStatus'
                  | 'membershipTier'
                  | 'inactiveAt'
                >
              >;
            }
          >
        >
      >;
      pageInfo: { __typename?: 'PageInfo' } & Pick<
        PageInfo,
        'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'
      >;
    }
  >;
};

export type ClubMembershipReportQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filters: MembershipFilters;
}>;

export type ClubMembershipReportQuery = { __typename?: 'RootQueryType' } & Pick<
  RootQueryType,
  'clubMembershipReportCount'
> & {
    clubMembershipReport?: Maybe<
      { __typename?: 'MembershipTypeConnection' } & {
        edges?: Maybe<
          Array<
            Maybe<
              { __typename?: 'MembershipTypeEdge' } & Pick<
                MembershipTypeEdge,
                'cursor'
              > & {
                  node?: Maybe<
                    { __typename?: 'MembershipType' } & Pick<
                      MembershipType,
                      | 'accountName'
                      | 'certificateid'
                      | 'contactid'
                      | 'memberId'
                      | 'email'
                      | 'firstName'
                      | 'lastName'
                      | 'mailingCountry'
                      | 'membershipEnrollDate'
                      | 'membershipStatus'
                      | 'membershipTier'
                      | 'inactiveAt'
                      | 'providerid'
                    >
                  >;
                }
            >
          >
        >;
        pageInfo: { __typename?: 'PageInfo' } & Pick<
          PageInfo,
          'hasNextPage' | 'endCursor' | 'hasPreviousPage' | 'startCursor'
        >;
      }
    >;
  };

export type GetClubMembershipsByProviderIdPaginatedQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  providerid: Scalars['String'];
}>;

export type GetClubMembershipsByProviderIdPaginatedQuery = {
  __typename?: 'RootQueryType';
} & {
  getClubMembershipsByProvideridPaginated?: Maybe<
    { __typename?: 'MembershipTypeConnection' } & {
      edges?: Maybe<
        Array<
          Maybe<
            { __typename?: 'MembershipTypeEdge' } & Pick<
              MembershipTypeEdge,
              'cursor'
            > & {
                node?: Maybe<
                  { __typename?: 'MembershipType' } & Pick<
                    MembershipType,
                    | 'id'
                    | 'accountName'
                    | 'certificateid'
                    | 'contactid'
                    | 'memberId'
                    | 'email'
                    | 'firstName'
                    | 'lastName'
                    | 'mailingCountry'
                    | 'membershipEnrollDate'
                    | 'membershipStatus'
                    | 'membershipTier'
                    | 'inactiveAt'
                  >
                >;
              }
          >
        >
      >;
      pageInfo: { __typename?: 'PageInfo' } & Pick<
        PageInfo,
        'endCursor' | 'startCursor' | 'hasNextPage' | 'hasPreviousPage'
      >;
    }
  >;
};

export type GetClubMembershipsByCertificateIdPaginatedQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  certificateid: Scalars['String'];
}>;

export type GetClubMembershipsByCertificateIdPaginatedQuery = {
  __typename?: 'RootQueryType';
} & {
  getClubMembershipsByCertificateidPaginated?: Maybe<
    { __typename?: 'MembershipTypeConnection' } & {
      edges?: Maybe<
        Array<
          Maybe<
            { __typename?: 'MembershipTypeEdge' } & Pick<
              MembershipTypeEdge,
              'cursor'
            > & {
                node?: Maybe<
                  { __typename?: 'MembershipType' } & Pick<
                    MembershipType,
                    | 'id'
                    | 'accountName'
                    | 'certificateid'
                    | 'contactid'
                    | 'memberId'
                    | 'email'
                    | 'firstName'
                    | 'lastName'
                    | 'mailingCountry'
                    | 'membershipEnrollDate'
                    | 'membershipStatus'
                    | 'membershipTier'
                    | 'inactiveAt'
                    | 'tripDuration'
                  >
                >;
              }
          >
        >
      >;
      pageInfo: { __typename?: 'PageInfo' } & Pick<
        PageInfo,
        'endCursor' | 'startCursor' | 'hasNextPage' | 'hasPreviousPage'
      >;
    }
  >;
};

export type GetClubMembershipOwnersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetClubMembershipOwnersQuery = { __typename?: 'RootQueryType' } & {
  getClubMembershipOwners?: Maybe<
    Array<
      Maybe<
        { __typename?: 'User' } & Pick<User, 'id' | 'email'> & {
            profile: { __typename?: 'UserProfile' } & Pick<
              UserProfile,
              'fullName'
            >;
          }
      >
    >
  >;
};

export type UploadClubMembershipMutationVariables = Exact<{
  csv: Array<Maybe<ClubMembershipInputType>> | Maybe<ClubMembershipInputType>;
}>;

export type UploadClubMembershipMutation = {
  __typename?: 'RootMutationType';
} & {
  createClubMemberships?: Maybe<
    { __typename?: 'MembershipsPayload' } & Pick<
      MembershipsPayload,
      'successful'
    > & {
        result?: Maybe<
          Array<
            Maybe<
              { __typename?: 'MembershipType' } & Pick<
                MembershipType,
                'email' | 'memberId'
              >
            >
          >
        >;
        messages?: Maybe<
          Array<
            Maybe<
              { __typename?: 'ValidationMessage' } & Pick<
                ValidationMessage,
                'code' | 'field' | 'message'
              >
            >
          >
        >;
      }
  >;
};

export type DeleteMembershipMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type DeleteMembershipMutation = { __typename?: 'RootMutationType' } & {
  deleteMembership?: Maybe<
    { __typename?: 'BooleanPayload' } & Pick<
      BooleanPayload,
      'result' | 'successful'
    > & {
        messages?: Maybe<
          Array<
            Maybe<
              { __typename?: 'ValidationMessage' } & Pick<
                ValidationMessage,
                'code' | 'field' | 'message'
              >
            >
          >
        >;
      }
  >;
};

export type DeleteMembershipByMemberIdMutationVariables = Exact<{
  memberId: Scalars['String'];
}>;

export type DeleteMembershipByMemberIdMutation = {
  __typename?: 'RootMutationType';
} & {
  deleteMembershipByMemberId?: Maybe<
    { __typename?: 'BooleanPayload' } & Pick<
      BooleanPayload,
      'result' | 'successful'
    > & {
        messages?: Maybe<
          Array<
            Maybe<
              { __typename?: 'ValidationMessage' } & Pick<
                ValidationMessage,
                'code' | 'field' | 'message'
              >
            >
          >
        >;
      }
  >;
};

export type DeleteClubMembershipsMutationVariables = Exact<{
  emails: Array<Scalars['String']> | Scalars['String'];
}>;

export type DeleteClubMembershipsMutation = {
  __typename?: 'RootMutationType';
} & {
  deleteClubMemberships?: Maybe<
    { __typename?: 'BooleanPayload' } & Pick<
      BooleanPayload,
      'result' | 'successful'
    > & {
        messages?: Maybe<
          Array<
            Maybe<
              { __typename?: 'ValidationMessage' } & Pick<
                ValidationMessage,
                'code' | 'field' | 'message'
              >
            >
          >
        >;
      }
  >;
};

export type GetTlaContractQueryVariables = Exact<{ [key: string]: never }>;

export type GetTlaContractQuery = { __typename?: 'RootQueryType' } & {
  result?: Maybe<
    { __typename?: 'TlaContractType' } & Pick<
      TlaContractType,
      'bucketDays' | 'bucketMonths' | 'bucketYears' | 'startDate' | 'endDate'
    >
  >;
};

export type GetAllTlaContractsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllTlaContractsQuery = { __typename?: 'RootQueryType' } & {
  result: Array<
    Maybe<
      { __typename?: 'TlaContractOutputType' } & Pick<
        TlaContractOutputType,
        'startDate' | 'bucketDays' | 'bucketMonths' | 'bucketYears'
      > & {
          user: { __typename?: 'User' } & Pick<User, 'id' | 'email'> & {
              profile: { __typename?: 'UserProfile' } & Pick<
                UserProfile,
                'fullName'
              >;
            };
        }
    >
  >;
};

export type CreateTmMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type CreateTmMutation = { __typename?: 'RootMutationType' } & {
  result?: Maybe<
    { __typename?: 'BooleanPayload' } & Pick<
      BooleanPayload,
      'successful' | 'result'
    > & {
        messages?: Maybe<
          Array<
            Maybe<
              { __typename?: 'ValidationMessage' } & Pick<
                ValidationMessage,
                'message' | 'code'
              >
            >
          >
        >;
      }
  >;
};

export type CountriesQueryVariables = Exact<{ [key: string]: never }>;

export type CountriesQuery = { __typename?: 'RootQueryType' } & {
  countries?: Maybe<
    Array<
      Maybe<
        { __typename?: 'CountryType' } & Pick<
          CountryType,
          'id' | 'name' | 'slug' | 'flag' | 'code'
        >
      >
    >
  >;
};

export type GetAllActiveCountriesGroupedQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllActiveCountriesGroupedQuery = {
  __typename?: 'RootQueryType';
} & {
  getAllActiveCountriesGrouped?: Maybe<
    Array<
      Maybe<
        { __typename?: 'BlocType' } & Pick<BlocType, 'name' | 'slug'> & {
            countries?: Maybe<
              Array<
                { __typename?: 'CountryType' } & Pick<
                  CountryType,
                  'code' | 'flag' | 'id' | 'name' | 'slug' | 'tlaAvailable'
                > & {
                    regions?: Maybe<
                      Array<
                        { __typename?: 'CountryRegionType' } & Pick<
                          CountryRegionType,
                          'code' | 'id' | 'name' | 'slug' | 'tlaAvailable'
                        >
                      >
                    >;
                  }
              >
            >;
          }
      >
    >
  >;
};

export type GetAllCountriesWithAssistanceGroupedQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllCountriesWithAssistanceGroupedQuery = {
  __typename?: 'RootQueryType';
} & {
  getAllCountriesWithAssistanceGrouped?: Maybe<
    Array<
      Maybe<
        { __typename?: 'BlocType' } & Pick<BlocType, 'name' | 'slug'> & {
            countries?: Maybe<
              Array<
                { __typename?: 'CountryType' } & Pick<
                  CountryType,
                  'code' | 'flag' | 'id' | 'name' | 'slug' | 'tlaAvailable'
                > & {
                    regions?: Maybe<
                      Array<
                        { __typename?: 'CountryRegionType' } & Pick<
                          CountryRegionType,
                          'code' | 'id' | 'name' | 'slug' | 'tlaAvailable'
                        >
                      >
                    >;
                  }
              >
            >;
          }
      >
    >
  >;
};

export type GetAllCountriesWithAnswersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllCountriesWithAnswersQuery = {
  __typename?: 'RootQueryType';
} & {
  results?: Maybe<
    Array<
      Maybe<
        { __typename?: 'BlocType' } & Pick<BlocType, 'name' | 'slug'> & {
            countries?: Maybe<
              Array<
                { __typename?: 'CountryType' } & Pick<
                  CountryType,
                  'code' | 'flag' | 'id' | 'name' | 'slug' | 'tlaAvailable'
                > & {
                    regions?: Maybe<
                      Array<
                        { __typename?: 'CountryRegionType' } & Pick<
                          CountryRegionType,
                          'code' | 'id' | 'name' | 'slug' | 'tlaAvailable'
                        >
                      >
                    >;
                  }
              >
            >;
          }
      >
    >
  >;
};

export type GetCountryBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type GetCountryBySlugQuery = { __typename?: 'RootQueryType' } & {
  results?: Maybe<
    { __typename?: 'CountryType' } & Pick<
      CountryType,
      'code' | 'flag' | 'id' | 'name' | 'slug'
    >
  >;
};

export type SearchUserQueryVariables = Exact<{
  email: Scalars['String'];
}>;

export type SearchUserQuery = { __typename?: 'RootQueryType' } & {
  results?: Maybe<
    { __typename?: 'User' } & Pick<User, 'email'> & {
        profile: { __typename?: 'UserProfile' } & Pick<
          UserProfile,
          'firstName' | 'lastName'
        >;
      }
  >;
};

export type GrantRoleMutationVariables = Exact<{
  email: Scalars['String'];
  role: RoleGrants;
}>;

export type GrantRoleMutation = { __typename?: 'RootMutationType' } & {
  result?: Maybe<
    { __typename?: 'BooleanPayload' } & Pick<BooleanPayload, 'successful'> & {
        messages?: Maybe<
          Array<
            Maybe<
              { __typename?: 'ValidationMessage' } & Pick<
                ValidationMessage,
                'message'
              >
            >
          >
        >;
      }
  >;
};

export type GetEmergencyContactsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetEmergencyContactsQuery = { __typename?: 'RootQueryType' } & {
  result?: Maybe<
    Array<
      Maybe<
        { __typename?: 'UserContactsType' } & Pick<
          UserContactsType,
          'lastName' | 'firstName' | 'email' | 'phone' | 'id'
        >
      >
    >
  >;
};

export type GetTravelerContactsByEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;

export type GetTravelerContactsByEmailQuery = {
  __typename?: 'RootQueryType';
} & {
  result?: Maybe<
    Array<
      Maybe<
        { __typename?: 'UserContactsType' } & Pick<
          UserContactsType,
          'lastName' | 'firstName' | 'email' | 'phone' | 'id'
        >
      >
    >
  >;
};

export type CreateEmergencyContactMutationVariables = Exact<{
  input?: Maybe<UserContactsInput>;
}>;

export type CreateEmergencyContactMutation = {
  __typename?: 'RootMutationType';
} & {
  result?: Maybe<
    { __typename?: 'UserContactsPayload' } & Pick<
      UserContactsPayload,
      'successful'
    > & {
        messages?: Maybe<
          Array<
            Maybe<
              { __typename?: 'ValidationMessage' } & Pick<
                ValidationMessage,
                'message' | 'code' | 'field'
              >
            >
          >
        >;
      }
  >;
};

export type UpdateEmergencyContactMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<UserContactsInput>;
}>;

export type UpdateEmergencyContactMutation = {
  __typename?: 'RootMutationType';
} & {
  result?: Maybe<
    { __typename?: 'UserContactsPayload' } & Pick<
      UserContactsPayload,
      'successful'
    > & {
        messages?: Maybe<
          Array<
            Maybe<
              { __typename?: 'ValidationMessage' } & Pick<
                ValidationMessage,
                'message' | 'code' | 'field'
              >
            >
          >
        >;
      }
  >;
};

export type DeleteEmergencyContactMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;

export type DeleteEmergencyContactMutation = {
  __typename?: 'RootMutationType';
} & {
  result?: Maybe<
    { __typename?: 'BooleanPayload' } & Pick<BooleanPayload, 'successful'> & {
        messages?: Maybe<
          Array<
            Maybe<
              { __typename?: 'ValidationMessage' } & Pick<
                ValidationMessage,
                'message' | 'code' | 'field'
              >
            >
          >
        >;
      }
  >;
};

export type GetMyInvitedUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyInvitedUsersQuery = { __typename?: 'RootQueryType' } & {
  getMyInvitedUsers?: Maybe<
    Array<Maybe<{ __typename?: 'User' } & Pick<User, 'email'>>>
  >;
};

export type CreateUserInvitationMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type CreateUserInvitationMutation = {
  __typename?: 'RootMutationType';
} & {
  createUserInvitation?: Maybe<
    { __typename?: 'BooleanPayload' } & Pick<
      BooleanPayload,
      'result' | 'successful'
    > & {
        messages?: Maybe<
          Array<
            Maybe<
              { __typename?: 'ValidationMessage' } & Pick<
                ValidationMessage,
                'message'
              >
            >
          >
        >;
      }
  >;
};

export type GetInvitedUserByTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type GetInvitedUserByTokenQuery = { __typename?: 'RootQueryType' } & {
  getInvitedUserByToken?: Maybe<{ __typename?: 'User' } & Pick<User, 'email'>>;
};

export type AcceptUserInvitationMutationVariables = Exact<{
  authId: Scalars['String'];
  invitationToken: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
}>;

export type AcceptUserInvitationMutation = {
  __typename?: 'RootMutationType';
} & {
  acceptUserInvitation?: Maybe<
    { __typename?: 'BooleanPayload' } & Pick<
      BooleanPayload,
      'result' | 'successful'
    > & {
        messages?: Maybe<
          Array<
            Maybe<
              { __typename?: 'ValidationMessage' } & Pick<
                ValidationMessage,
                'message'
              >
            >
          >
        >;
      }
  >;
};

export type ActiveLanguagesQueryVariables = Exact<{ [key: string]: never }>;

export type ActiveLanguagesQuery = { __typename?: 'RootQueryType' } & {
  getActiveLanguages?: Maybe<
    Array<Maybe<{ __typename?: 'LanguageType' } & LanguageFragment>>
  >;
};

export type GetAllLanguagesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllLanguagesQuery = { __typename?: 'RootQueryType' } & {
  getAllLanguages?: Maybe<
    Array<Maybe<{ __typename?: 'LanguageType' } & LanguageFragment>>
  >;
};

export type GetMyPurchasesQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyPurchasesQuery = { __typename?: 'RootQueryType' } & {
  results: Array<
    { __typename?: 'PurchaseType' } & Pick<
      PurchaseType,
      'description' | 'id' | 'insertedAt' | 'invoiceUrl' | 'paid'
    > & {
        details?: Maybe<
          { __typename?: 'PurchaseDetailsType' } & Pick<
            PurchaseDetailsType,
            'total'
          > & {
              paymentSource: { __typename?: 'PaymentMethodType' } & Pick<
                PaymentMethodType,
                'cardBrand' | 'cardLast4' | 'id'
              >;
            }
        >;
      }
  >;
};

export type GetActiveQuestionsByCategoryTopicQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<QuestionFilterType>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<EnumSortOrder>;
}>;

export type GetActiveQuestionsByCategoryTopicQuery = {
  __typename?: 'RootQueryType';
} & {
  results?: Maybe<
    { __typename?: 'QuestionTypeConnection' } & {
      edges?: Maybe<
        Array<
          Maybe<
            { __typename?: 'QuestionTypeEdge' } & Pick<
              QuestionTypeEdge,
              'cursor'
            > & {
                node?: Maybe<
                  { __typename?: 'QuestionType' } & Pick<
                    QuestionType,
                    'id' | 'question'
                  >
                >;
              }
          >
        >
      >;
      pageInfo: { __typename?: 'PageInfo' } & Pick<
        PageInfo,
        'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'
      >;
    }
  >;
};

export type ListMyReferralCodesQueryVariables = Exact<{ [key: string]: never }>;

export type ListMyReferralCodesQuery = { __typename?: 'RootQueryType' } & {
  results: Array<
    Maybe<
      { __typename?: 'ReferralType' } & Pick<
        ReferralType,
        'code' | 'expires' | 'active'
      >
    >
  >;
};

export type CreatedReferralFragment = { __typename?: 'ReferralType' } & Pick<
  ReferralType,
  'code' | 'expires' | 'active'
> & { user?: Maybe<{ __typename?: 'User' } & Pick<User, 'email'>> };

export type ListCreatedReferralsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ListCreatedReferralsQuery = { __typename?: 'RootQueryType' } & {
  results: Array<
    Maybe<{ __typename?: 'ReferralType' } & CreatedReferralFragment>
  >;
};

export type GenerateReferralCodeMutationVariables = Exact<{
  email: Scalars['String'];
  input?: Maybe<CreateReferralInput>;
}>;

export type GenerateReferralCodeMutation = {
  __typename?: 'RootMutationType';
} & {
  results?: Maybe<
    { __typename?: 'ReferralPayload' } & Pick<ReferralPayload, 'successful'> & {
        result?: Maybe<
          { __typename?: 'ReferralType' } & Pick<ReferralType, 'code'>
        >;
        messages?: Maybe<
          Array<
            Maybe<
              { __typename?: 'ValidationMessage' } & Pick<
                ValidationMessage,
                'message'
              >
            >
          >
        >;
      }
  >;
};

export type ToggleReferralCodeMutationVariables = Exact<{
  referralCode: Scalars['String'];
}>;

export type ToggleReferralCodeMutation = { __typename?: 'RootMutationType' } & {
  results?: Maybe<
    { __typename?: 'ReferralPayload' } & Pick<ReferralPayload, 'successful'> & {
        result?: Maybe<
          { __typename?: 'ReferralType' } & CreatedReferralFragment
        >;
      }
  >;
};

export type ListMyTravelLegalAssistanceQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ListMyTravelLegalAssistanceQuery = {
  __typename?: 'RootQueryType';
} & {
  results: Array<
    { __typename?: 'TlaType' } & Pick<
      TlaType,
      | 'status'
      | 'endDate'
      | 'assistType'
      | 'referralCode'
      | 'startDate'
      | 'tlaNumber'
    > & {
        destinations: Array<
          Maybe<
            { __typename?: 'TlaDestinationType' } & {
              country: { __typename?: 'SkinnedCountryType' } & Pick<
                SkinnedCountryType,
                'code' | 'id' | 'flag' | 'name' | 'slug'
              >;
            }
          >
        >;
        purchase?: Maybe<
          { __typename?: 'PurchaseType' } & {
            details?: Maybe<
              { __typename?: 'PurchaseDetailsType' } & Pick<
                PurchaseDetailsType,
                'total'
              >
            >;
          }
        >;
      }
  >;
};

export type ListMyPurchasedTravelLegalAssistanceQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ListMyPurchasedTravelLegalAssistanceQuery = {
  __typename?: 'RootQueryType';
} & {
  results: Array<
    { __typename?: 'TlaType' } & Pick<
      TlaType,
      | 'status'
      | 'referralCode'
      | 'startDate'
      | 'tlaNumber'
      | 'endDate'
      | 'assistType'
      | 'insertedAt'
      | 'deducted'
    > & {
        destinations: Array<
          Maybe<
            { __typename?: 'TlaDestinationType' } & {
              country: { __typename?: 'SkinnedCountryType' } & Pick<
                SkinnedCountryType,
                'code' | 'id' | 'flag' | 'name' | 'slug'
              >;
            }
          >
        >;
        purchase?: Maybe<
          { __typename?: 'PurchaseType' } & Pick<PurchaseType, 'insertedAt'> & {
              details?: Maybe<
                { __typename?: 'PurchaseDetailsType' } & Pick<
                  PurchaseDetailsType,
                  'total'
                >
              >;
            }
        >;
        travelers?: Maybe<
          Array<
            { __typename?: 'TravelerType' } & Pick<
              TravelerType,
              'email' | 'fullName' | 'firstName' | 'lastName'
            > & {
                countryOfResidency?: Maybe<
                  { __typename?: 'SkinnedCountryType' } & Pick<
                    SkinnedCountryType,
                    'code' | 'name'
                  >
                >;
              }
          >
        >;
        user: { __typename?: 'User' } & Pick<User, 'email'> & {
            profile: { __typename?: 'UserProfile' } & Pick<
              UserProfile,
              'fullName' | 'firstName' | 'lastName'
            >;
          };
      }
  >;
};

export type GetTlaByRefNumberQueryVariables = Exact<{
  ref: Scalars['String'];
}>;

export type GetTlaByRefNumberQuery = { __typename?: 'RootQueryType' } & {
  getTlaByRefNumber?: Maybe<
    { __typename?: 'TlaType' } & Pick<
      TlaType,
      | 'status'
      | 'referralCode'
      | 'assistType'
      | 'startDate'
      | 'tlaNumber'
      | 'endDate'
      | 'insertedAt'
      | 'isMonthlyTla'
    > & {
        destinations: Array<
          Maybe<
            { __typename?: 'TlaDestinationType' } & {
              country: { __typename?: 'SkinnedCountryType' } & Pick<
                SkinnedCountryType,
                'code' | 'id' | 'flag' | 'name' | 'slug'
              >;
            }
          >
        >;
        purchase?: Maybe<
          { __typename?: 'PurchaseType' } & Pick<PurchaseType, 'insertedAt'> & {
              details?: Maybe<
                { __typename?: 'PurchaseDetailsType' } & Pick<
                  PurchaseDetailsType,
                  'total'
                >
              >;
            }
        >;
        travelers?: Maybe<
          Array<
            { __typename?: 'TravelerType' } & Pick<
              TravelerType,
              'email' | 'fullName' | 'firstName' | 'lastName'
            > & {
                countryOfResidency?: Maybe<
                  { __typename?: 'SkinnedCountryType' } & Pick<
                    SkinnedCountryType,
                    'code' | 'name'
                  >
                >;
              }
          >
        >;
        user: { __typename?: 'User' } & Pick<User, 'email'> & {
            profile: { __typename?: 'UserProfile' } & Pick<
              UserProfile,
              'firstName' | 'lastName'
            >;
          };
      }
  >;
};

export type GetTlaReportsQueryVariables = Exact<{
  first: Scalars['Int'];
  referralCode: Scalars['String'];
}>;

export type GetTlaReportsQuery = { __typename?: 'RootQueryType' } & {
  results?: Maybe<
    { __typename?: 'TlaNodeTypeConnection' } & {
      edges?: Maybe<
        Array<
          Maybe<
            { __typename?: 'TlaNodeTypeEdge' } & {
              node?: Maybe<
                { __typename?: 'TlaNodeType' } & Pick<
                  TlaNodeType,
                  | 'status'
                  | 'assistType'
                  | 'deducted'
                  | 'groupCode'
                  | 'price'
                  | 'endDate'
                  | 'referralCode'
                  | 'startDate'
                  | 'tlaNumber'
                  | 'insertedAt'
                > & {
                    travelers?: Maybe<
                      Array<
                        Maybe<
                          { __typename?: 'TravelerType' } & Pick<
                            TravelerType,
                            'email' | 'fullName' | 'firstName' | 'lastName'
                          > & {
                              countryOfResidency?: Maybe<
                                { __typename?: 'SkinnedCountryType' } & Pick<
                                  SkinnedCountryType,
                                  'code' | 'name'
                                >
                              >;
                            }
                        >
                      >
                    >;
                    user: { __typename?: 'User' } & Pick<User, 'email'> & {
                        profile: { __typename?: 'UserProfile' } & Pick<
                          UserProfile,
                          'avatar' | 'dob' | 'firstName' | 'lastName' | 'phone'
                        >;
                      };
                    destinations: Array<
                      Maybe<
                        { __typename?: 'TlaDestinationType' } & {
                          country: { __typename?: 'SkinnedCountryType' } & Pick<
                            SkinnedCountryType,
                            'name'
                          >;
                        }
                      >
                    >;
                    purchase?: Maybe<
                      { __typename?: 'PurchaseType' } & {
                        details?: Maybe<
                          { __typename?: 'PurchaseDetailsType' } & Pick<
                            PurchaseDetailsType,
                            'total'
                          >
                        >;
                      }
                    >;
                  }
              >;
            }
          >
        >
      >;
      pageInfo: { __typename?: 'PageInfo' } & Pick<
        PageInfo,
        'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'
      >;
      tlaSummary?: Maybe<
        { __typename?: 'TlaReportSummaryType' } & Pick<
          TlaReportSummaryType,
          'amount' | 'total'
        >
      >;
    }
  >;
};

export type GetMyGroupTlaQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
}>;

export type GetMyGroupTlaQuery = { __typename?: 'RootQueryType' } & {
  listTlasGroups?: Maybe<
    { __typename?: 'TlaGroupBuyConnection' } & {
      edges?: Maybe<
        Array<
          Maybe<
            { __typename?: 'TlaGroupBuyEdge' } & {
              node?: Maybe<
                { __typename?: 'TlaGroupBuy' } & Pick<
                  TlaGroupBuy,
                  'groupCode' | 'totalTlas' | 'total' | 'description'
                >
              >;
            }
          >
        >
      >;
      pageInfo: { __typename?: 'PageInfo' } & Pick<
        PageInfo,
        'endCursor' | 'startCursor' | 'hasNextPage' | 'hasPreviousPage'
      >;
    }
  >;
};

export type GetAllLegalAssistsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  filter?: Maybe<TlaFilterType>;
}>;

export type GetAllLegalAssistsQuery = { __typename?: 'RootQueryType' } & {
  getAllLegalAssists?: Maybe<
    { __typename?: 'TlaNodeTypeConnection' } & {
      pageInfo: { __typename?: 'PageInfo' } & Pick<
        PageInfo,
        'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'
      >;
      edges?: Maybe<
        Array<
          Maybe<
            { __typename?: 'TlaNodeTypeEdge' } & {
              node?: Maybe<
                { __typename?: 'TlaNodeType' } & Pick<
                  TlaNodeType,
                  | 'id'
                  | 'insertedAt'
                  | 'tlaNumber'
                  | 'startDate'
                  | 'endDate'
                  | 'status'
                  | 'assistType'
                  | 'referralCode'
                  | 'deducted'
                  | 'price'
                > & {
                    destinations: Array<
                      Maybe<
                        { __typename?: 'TlaDestinationType' } & {
                          country: { __typename?: 'SkinnedCountryType' } & Pick<
                            SkinnedCountryType,
                            'code' | 'name'
                          >;
                        }
                      >
                    >;
                    travelers?: Maybe<
                      Array<
                        Maybe<
                          { __typename?: 'TravelerType' } & Pick<
                            TravelerType,
                            'email' | 'firstName' | 'lastName'
                          > & {
                              countryOfResidency?: Maybe<
                                { __typename?: 'SkinnedCountryType' } & Pick<
                                  SkinnedCountryType,
                                  'code' | 'name'
                                >
                              >;
                            }
                        >
                      >
                    >;
                    purchase?: Maybe<
                      { __typename?: 'PurchaseType' } & {
                        details?: Maybe<
                          { __typename?: 'PurchaseDetailsType' } & Pick<
                            PurchaseDetailsType,
                            'total'
                          >
                        >;
                      }
                    >;
                  }
              >;
            }
          >
        >
      >;
    }
  >;
};

export type GetMonthlyAssistQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  filter?: Maybe<TlaFilterType>;
}>;

export type GetMonthlyAssistQuery = { __typename?: 'RootQueryType' } & {
  getMonthlyTlas?: Maybe<
    { __typename?: 'TlaNodeTypeConnection' } & {
      pageInfo: { __typename?: 'PageInfo' } & Pick<
        PageInfo,
        'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'
      >;
      edges?: Maybe<
        Array<
          Maybe<
            { __typename?: 'TlaNodeTypeEdge' } & {
              node?: Maybe<
                { __typename?: 'TlaNodeType' } & Pick<
                  TlaNodeType,
                  | 'id'
                  | 'insertedAt'
                  | 'tlaNumber'
                  | 'startDate'
                  | 'endDate'
                  | 'status'
                  | 'assistType'
                  | 'referralCode'
                > & {
                    destinations: Array<
                      Maybe<
                        { __typename?: 'TlaDestinationType' } & {
                          country: { __typename?: 'SkinnedCountryType' } & Pick<
                            SkinnedCountryType,
                            'code' | 'name'
                          >;
                        }
                      >
                    >;
                    user: { __typename?: 'User' } & Pick<User, 'email'> & {
                        profile: { __typename?: 'UserProfile' } & Pick<
                          UserProfile,
                          'firstName' | 'lastName'
                        >;
                      };
                    travelers?: Maybe<
                      Array<
                        Maybe<
                          { __typename?: 'TravelerType' } & Pick<
                            TravelerType,
                            'email' | 'fullName' | 'firstName' | 'lastName'
                          > & {
                              countryOfResidency?: Maybe<
                                { __typename?: 'SkinnedCountryType' } & Pick<
                                  SkinnedCountryType,
                                  'code' | 'name'
                                >
                              >;
                            }
                        >
                      >
                    >;
                    purchase?: Maybe<
                      { __typename?: 'PurchaseType' } & {
                        details?: Maybe<
                          { __typename?: 'PurchaseDetailsType' } & Pick<
                            PurchaseDetailsType,
                            'total'
                          >
                        >;
                      }
                    >;
                  }
              >;
            }
          >
        >
      >;
    }
  >;
};

export type GetMyTravelManagersQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
}>;

export type GetMyTravelManagersQuery = { __typename?: 'RootQueryType' } & {
  getMyTravelManagers?: Maybe<
    { __typename?: 'TravelManagerNodeTypeConnection' } & {
      edges?: Maybe<
        Array<
          Maybe<
            { __typename?: 'TravelManagerNodeTypeEdge' } & {
              node?: Maybe<
                { __typename?: 'TravelManagerNodeType' } & Pick<
                  TravelManagerNodeType,
                  'email' | 'id'
                > & {
                    profile: { __typename?: 'UserProfile' } & Pick<
                      UserProfile,
                      'fullName' | 'phone' | 'avatar'
                    >;
                  }
              >;
            }
          >
        >
      >;
    }
  >;
};

export type QuoteTlaQueryVariables = Exact<{
  destinationCodes: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
  endDate: Scalars['String'];
  startDate: Scalars['String'];
}>;

export type QuoteTlaQuery = { __typename?: 'RootQueryType' } & {
  basicTLAQuote: { __typename?: 'QuoteType' } & Pick<
    QuoteType,
    'days' | 'endDate' | 'months' | 'startDate' | 'total'
  > & {
      destinations: Array<
        Maybe<
          { __typename?: 'SkinnedCountryType' } & Pick<
            SkinnedCountryType,
            'code' | 'flag' | 'name' | 'slug'
          >
        >
      >;
    };
  vipTLAQuote: { __typename?: 'QuoteType' } & Pick<
    QuoteType,
    'days' | 'endDate' | 'months' | 'startDate' | 'total'
  > & {
      destinations: Array<
        Maybe<
          { __typename?: 'SkinnedCountryType' } & Pick<
            SkinnedCountryType,
            'code' | 'flag' | 'name' | 'slug'
          >
        >
      >;
    };
};

export type BuyTravelLegalAssistancesMutationVariables = Exact<{
  paymentMethod: Scalars['String'];
  input: Array<Maybe<MultiTlaInput>> | Maybe<MultiTlaInput>;
}>;

export type BuyTravelLegalAssistancesMutation = {
  __typename?: 'RootMutationType';
} & {
  buyTlasAsGift?: Maybe<
    { __typename?: 'MultiTlaPayload' } & Pick<MultiTlaPayload, 'successful'> & {
        messages?: Maybe<
          Array<
            Maybe<
              { __typename?: 'ValidationMessage' } & Pick<
                ValidationMessage,
                'code' | 'field' | 'message' | 'template'
              > & {
                  options?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'ValidationOption' } & Pick<
                          ValidationOption,
                          'key' | 'value'
                        >
                      >
                    >
                  >;
                }
            >
          >
        >;
        result?: Maybe<
          Array<
            Maybe<
              { __typename?: 'TlaType' } & Pick<
                TlaType,
                | 'endDate'
                | 'assistType'
                | 'referralCode'
                | 'startDate'
                | 'tlaNumber'
                | 'groupCode'
              > & {
                  destinations: Array<
                    Maybe<
                      { __typename?: 'TlaDestinationType' } & {
                        country: { __typename?: 'SkinnedCountryType' } & Pick<
                          SkinnedCountryType,
                          'code' | 'flag' | 'name' | 'slug'
                        >;
                      }
                    >
                  >;
                  purchase?: Maybe<
                    { __typename?: 'PurchaseType' } & Pick<
                      PurchaseType,
                      'bankStatement' | 'description' | 'invoiceUrl' | 'paid'
                    > & {
                        details?: Maybe<
                          { __typename?: 'PurchaseDetailsType' } & Pick<
                            PurchaseDetailsType,
                            'total'
                          >
                        >;
                      }
                  >;
                  travelers?: Maybe<
                    Array<
                      { __typename?: 'TravelerType' } & Pick<
                        TravelerType,
                        'email' | 'fullName'
                      >
                    >
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type BuyTravelLegalAssistanceMutationVariables = Exact<{
  paymentMethod: Scalars['String'];
  destinationCodes: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
  endDate: Scalars['String'];
  startDate: Scalars['String'];
  type: QuoteTypesEnum;
  referralCode?: Maybe<Scalars['String']>;
}>;

export type BuyTravelLegalAssistanceMutation = {
  __typename?: 'RootMutationType';
} & {
  buyTravelLegalAssistance?: Maybe<
    { __typename?: 'TlaPayload' } & Pick<TlaPayload, 'successful'> & {
        messages?: Maybe<
          Array<
            Maybe<
              { __typename?: 'ValidationMessage' } & Pick<
                ValidationMessage,
                'code' | 'field' | 'message' | 'template'
              > & {
                  options?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'ValidationOption' } & Pick<
                          ValidationOption,
                          'key' | 'value'
                        >
                      >
                    >
                  >;
                }
            >
          >
        >;
        result?: Maybe<
          { __typename?: 'TlaType' } & Pick<
            TlaType,
            'endDate' | 'referralCode' | 'startDate' | 'tlaNumber'
          > & {
              destinations: Array<
                Maybe<
                  { __typename?: 'TlaDestinationType' } & {
                    country: { __typename?: 'SkinnedCountryType' } & Pick<
                      SkinnedCountryType,
                      'code' | 'flag' | 'name' | 'slug'
                    >;
                  }
                >
              >;
              purchase?: Maybe<
                { __typename?: 'PurchaseType' } & Pick<
                  PurchaseType,
                  'bankStatement' | 'description' | 'invoiceUrl' | 'paid'
                > & {
                    details?: Maybe<
                      { __typename?: 'PurchaseDetailsType' } & Pick<
                        PurchaseDetailsType,
                        'total'
                      >
                    >;
                  }
              >;
              user: { __typename?: 'User' } & Pick<User, 'email'> & {
                  profile: { __typename?: 'UserProfile' } & Pick<
                    UserProfile,
                    'avatar' | 'dob' | 'firstName' | 'lastName' | 'phone'
                  >;
                };
            }
        >;
      }
  >;
};

export type BuyMultiTlaMutationVariables = Exact<{
  input?: Maybe<Array<Maybe<MultiTlaInput>> | Maybe<MultiTlaInput>>;
  paymentMethod: Scalars['String'];
  referralCode?: Maybe<Scalars['String']>;
  quoteType: QuoteTypesEnum;
}>;

export type BuyMultiTlaMutation = { __typename?: 'RootMutationType' } & {
  buyMultiTla?: Maybe<
    { __typename?: 'MultiTlaPayload' } & Pick<MultiTlaPayload, 'successful'> & {
        messages?: Maybe<
          Array<
            Maybe<
              { __typename?: 'ValidationMessage' } & Pick<
                ValidationMessage,
                'code' | 'message'
              >
            >
          >
        >;
        result?: Maybe<
          Array<
            Maybe<
              { __typename?: 'TlaType' } & Pick<
                TlaType,
                | 'endDate'
                | 'startDate'
                | 'referralCode'
                | 'assistType'
                | 'tlaNumber'
              > & {
                  destinations: Array<
                    Maybe<
                      { __typename?: 'TlaDestinationType' } & {
                        country: { __typename?: 'SkinnedCountryType' } & Pick<
                          SkinnedCountryType,
                          'code'
                        >;
                      }
                    >
                  >;
                  travelers?: Maybe<
                    Array<
                      { __typename?: 'TravelerType' } & Pick<
                        TravelerType,
                        'email' | 'firstName' | 'lastName' | 'fullName'
                      > & {
                          countryOfResidency?: Maybe<
                            { __typename?: 'SkinnedCountryType' } & Pick<
                              SkinnedCountryType,
                              'id' | 'code' | 'name'
                            >
                          >;
                        }
                    >
                  >;
                  purchase?: Maybe<
                    { __typename?: 'PurchaseType' } & Pick<
                      PurchaseType,
                      'bankStatement' | 'id'
                    > & {
                        details?: Maybe<
                          { __typename?: 'PurchaseDetailsType' } & Pick<
                            PurchaseDetailsType,
                            'total'
                          > & {
                              paymentSource: {
                                __typename?: 'PaymentMethodType';
                              } & Pick<
                                PaymentMethodType,
                                | 'cardBrand'
                                | 'cardLast4'
                                | 'expYear'
                                | 'expMonth'
                                | 'id'
                              >;
                            }
                        >;
                      }
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type BuyMultiTlaWithReferralMutationVariables = Exact<{
  input?: Maybe<Array<Maybe<MultiTlaInput>> | Maybe<MultiTlaInput>>;
  paymentMethod: Scalars['String'];
  referralCode?: Maybe<Scalars['String']>;
  quoteType: QuoteTypesEnum;
}>;

export type BuyMultiTlaWithReferralMutation = {
  __typename?: 'RootMutationType';
} & {
  buyMultiTla?: Maybe<
    { __typename?: 'MultiTlaPayload' } & Pick<MultiTlaPayload, 'successful'> & {
        messages?: Maybe<
          Array<
            Maybe<
              { __typename?: 'ValidationMessage' } & Pick<
                ValidationMessage,
                'code' | 'message'
              >
            >
          >
        >;
        result?: Maybe<
          Array<
            Maybe<
              { __typename?: 'TlaType' } & Pick<
                TlaType,
                'endDate' | 'startDate' | 'tlaNumber' | 'assistType'
              > & {
                  destinations: Array<
                    Maybe<
                      { __typename?: 'TlaDestinationType' } & {
                        country: { __typename?: 'SkinnedCountryType' } & Pick<
                          SkinnedCountryType,
                          'code'
                        >;
                      }
                    >
                  >;
                  travelers?: Maybe<
                    Array<
                      { __typename?: 'TravelerType' } & Pick<
                        TravelerType,
                        'email' | 'firstName' | 'lastName'
                      > & {
                          countryOfResidency?: Maybe<
                            { __typename?: 'SkinnedCountryType' } & Pick<
                              SkinnedCountryType,
                              'id' | 'code' | 'name'
                            >
                          >;
                        }
                    >
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type ViewerQueryVariables = Exact<{ [key: string]: never }>;

export type ViewerQuery = { __typename?: 'RootQueryType' } & {
  viewer?: Maybe<
    { __typename?: 'User' } & Pick<
      User,
      'email' | 'permissions' | 'roles' | 'settings'
    > & {
        subscription?: Maybe<
          { __typename?: 'UserSubscription' } & Pick<UserSubscription, 'type'>
        >;
        affiliate?: Maybe<{ __typename?: 'Affiliate' } & Pick<Affiliate, 'id'>>;
        profile: { __typename?: 'UserProfile' } & Pick<
          UserProfile,
          'firstName' | 'lastName' | 'fullName' | 'avatar' | 'dob' | 'phone'
        >;
      }
  >;
};

export type SigninMutationVariables = Exact<{
  accessToken: Scalars['String'];
}>;

export type SigninMutation = { __typename?: 'RootMutationType' } & {
  authenticate?: Maybe<
    { __typename?: 'AuthActionPayload' } & Pick<
      AuthActionPayload,
      'successful'
    > & {
        messages?: Maybe<
          Array<
            Maybe<
              { __typename?: 'ValidationMessage' } & ValidationMessageFragment
            >
          >
        >;
      }
  >;
};

export type SignoutMutationVariables = Exact<{ [key: string]: never }>;

export type SignoutMutation = { __typename?: 'RootMutationType' } & Pick<
  RootMutationType,
  'signOut'
>;

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type ResetPasswordMutation = { __typename?: 'RootMutationType' } & Pick<
  RootMutationType,
  'resetPassword'
>;

export type UpdateProfileMutationVariables = Exact<{
  input?: Maybe<UpdateProfileInput>;
}>;

export type UpdateProfileMutation = { __typename?: 'RootMutationType' } & {
  updateProfile?: Maybe<
    { __typename?: 'ProfilePayload' } & Pick<ProfilePayload, 'successful'> & {
        messages?: Maybe<
          Array<
            Maybe<
              { __typename?: 'ValidationMessage' } & ValidationMessageFragment
            >
          >
        >;
        result?: Maybe<
          { __typename?: 'User' } & {
            profile: { __typename?: 'UserProfile' } & ProfileFragment;
          }
        >;
      }
  >;
};

export type SendVerificationEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type SendVerificationEmailMutation = {
  __typename?: 'RootMutationType';
} & {
  sendVerificationEmail?: Maybe<
    { __typename?: 'AuthActionPayload' } & Pick<
      AuthActionPayload,
      'successful' | 'result'
    > & {
        messages?: Maybe<
          Array<
            Maybe<
              { __typename?: 'ValidationMessage' } & ValidationMessageFragment
            >
          >
        >;
      }
  >;
};

export const ProfileFragmentDoc = gql`
  fragment profile on UserProfile {
    firstName
    lastName
    avatar
    dob
    phone
  }
`;
export const UserFragmentDoc = gql`
  fragment user on User {
    email
    permissions
    roles
    subscription {
      type
    }
    affiliate {
      id
    }
    profile {
      firstName
      lastName
      avatar
      dob
      phone
    }
  }
`;
export const LanguageFragmentDoc = gql`
  fragment language on LanguageType {
    code
    name
    slug
  }
`;
export const ValidationMessageFragmentDoc = gql`
  fragment validationMessage on ValidationMessage {
    code
    message
    field
    template
    options {
      key
      value
    }
  }
`;
export const PaymentMethodFragmentDoc = gql`
  fragment paymentMethod on PaymentMethodType {
    id
    cardBrand
    cardLast4
    isDefault
    expMonth
    expYear
  }
`;
export const CreatedReferralFragmentDoc = gql`
  fragment createdReferral on ReferralType {
    code
    expires
    active
    user {
      email
    }
  }
`;
export const GetOwnersTlaDocument = gql`
  query getOwnersTla($input: String!) {
    getOwnersTla(input: $input) {
      destinations {
        country {
          code
          flag
          name
          slug
        }
      }
      endDate
      purchase {
        bankStatement
        description
        details {
          total
        }
        invoiceUrl
        paid
        insertedAt
      }
      referralCode
      startDate
      tlaNumber
      travelers {
        email
        firstName
        lastName
        countryOfResidency {
          code
          id
          flag
          name
          slug
        }
      }
      user {
        email
        profile {
          avatar
          dob
          firstName
          lastName
          phone
        }
      }
    }
  }
`;

/**
 * __useGetOwnersTlaQuery__
 *
 * To run a query within a React component, call `useGetOwnersTlaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnersTlaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnersTlaQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOwnersTlaQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOwnersTlaQuery,
    GetOwnersTlaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOwnersTlaQuery, GetOwnersTlaQueryVariables>(
    GetOwnersTlaDocument,
    options
  );
}
export function useGetOwnersTlaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOwnersTlaQuery,
    GetOwnersTlaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOwnersTlaQuery, GetOwnersTlaQueryVariables>(
    GetOwnersTlaDocument,
    options
  );
}
export type GetOwnersTlaQueryHookResult = ReturnType<
  typeof useGetOwnersTlaQuery
>;
export type GetOwnersTlaLazyQueryHookResult = ReturnType<
  typeof useGetOwnersTlaLazyQuery
>;
export type GetOwnersTlaQueryResult = Apollo.QueryResult<
  GetOwnersTlaQuery,
  GetOwnersTlaQueryVariables
>;
export const GetPaymentMethodsDocument = gql`
  query getPaymentMethods {
    getMyPaymentSources {
      ...paymentMethod
    }
  }
  ${PaymentMethodFragmentDoc}
`;

/**
 * __useGetPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useGetPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPaymentMethodsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPaymentMethodsQuery,
    GetPaymentMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPaymentMethodsQuery,
    GetPaymentMethodsQueryVariables
  >(GetPaymentMethodsDocument, options);
}
export function useGetPaymentMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaymentMethodsQuery,
    GetPaymentMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPaymentMethodsQuery,
    GetPaymentMethodsQueryVariables
  >(GetPaymentMethodsDocument, options);
}
export type GetPaymentMethodsQueryHookResult = ReturnType<
  typeof useGetPaymentMethodsQuery
>;
export type GetPaymentMethodsLazyQueryHookResult = ReturnType<
  typeof useGetPaymentMethodsLazyQuery
>;
export type GetPaymentMethodsQueryResult = Apollo.QueryResult<
  GetPaymentMethodsQuery,
  GetPaymentMethodsQueryVariables
>;
export const CreatePaymentSourceDocument = gql`
  mutation CreatePaymentSource(
    $paymentMethodId: String!
    $isDefault: Boolean = false
  ) {
    createPaymentSource(
      paymentMethodId: $paymentMethodId
      isDefault: $isDefault
    ) {
      successful
      messages {
        ...validationMessage
      }
      result {
        ...paymentMethod
      }
    }
  }
  ${ValidationMessageFragmentDoc}
  ${PaymentMethodFragmentDoc}
`;
export type CreatePaymentSourceMutationFn = Apollo.MutationFunction<
  CreatePaymentSourceMutation,
  CreatePaymentSourceMutationVariables
>;

/**
 * __useCreatePaymentSourceMutation__
 *
 * To run a mutation, you first call `useCreatePaymentSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentSourceMutation, { data, loading, error }] = useCreatePaymentSourceMutation({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *      isDefault: // value for 'isDefault'
 *   },
 * });
 */
export function useCreatePaymentSourceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePaymentSourceMutation,
    CreatePaymentSourceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePaymentSourceMutation,
    CreatePaymentSourceMutationVariables
  >(CreatePaymentSourceDocument, options);
}
export type CreatePaymentSourceMutationHookResult = ReturnType<
  typeof useCreatePaymentSourceMutation
>;
export type CreatePaymentSourceMutationResult = Apollo.MutationResult<
  CreatePaymentSourceMutation
>;
export type CreatePaymentSourceMutationOptions = Apollo.BaseMutationOptions<
  CreatePaymentSourceMutation,
  CreatePaymentSourceMutationVariables
>;
export const DeletePaymentSourceDocument = gql`
  mutation DeletePaymentSource($paymentMethodId: String!) {
    deletePaymentSource(paymentMethodId: $paymentMethodId) {
      messages {
        ...validationMessage
      }
      result
      successful
    }
  }
  ${ValidationMessageFragmentDoc}
`;
export type DeletePaymentSourceMutationFn = Apollo.MutationFunction<
  DeletePaymentSourceMutation,
  DeletePaymentSourceMutationVariables
>;

/**
 * __useDeletePaymentSourceMutation__
 *
 * To run a mutation, you first call `useDeletePaymentSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentSourceMutation, { data, loading, error }] = useDeletePaymentSourceMutation({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useDeletePaymentSourceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePaymentSourceMutation,
    DeletePaymentSourceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePaymentSourceMutation,
    DeletePaymentSourceMutationVariables
  >(DeletePaymentSourceDocument, options);
}
export type DeletePaymentSourceMutationHookResult = ReturnType<
  typeof useDeletePaymentSourceMutation
>;
export type DeletePaymentSourceMutationResult = Apollo.MutationResult<
  DeletePaymentSourceMutation
>;
export type DeletePaymentSourceMutationOptions = Apollo.BaseMutationOptions<
  DeletePaymentSourceMutation,
  DeletePaymentSourceMutationVariables
>;
export const SetDefaultPaymentSourceDocument = gql`
  mutation SetDefaultPaymentSource($paymentMethodId: String!) {
    setDefaultPaymentSource(paymentMethodId: $paymentMethodId) {
      messages {
        ...validationMessage
      }
      result {
        ...paymentMethod
      }
      successful
    }
  }
  ${ValidationMessageFragmentDoc}
  ${PaymentMethodFragmentDoc}
`;
export type SetDefaultPaymentSourceMutationFn = Apollo.MutationFunction<
  SetDefaultPaymentSourceMutation,
  SetDefaultPaymentSourceMutationVariables
>;

/**
 * __useSetDefaultPaymentSourceMutation__
 *
 * To run a mutation, you first call `useSetDefaultPaymentSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDefaultPaymentSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDefaultPaymentSourceMutation, { data, loading, error }] = useSetDefaultPaymentSourceMutation({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useSetDefaultPaymentSourceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetDefaultPaymentSourceMutation,
    SetDefaultPaymentSourceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetDefaultPaymentSourceMutation,
    SetDefaultPaymentSourceMutationVariables
  >(SetDefaultPaymentSourceDocument, options);
}
export type SetDefaultPaymentSourceMutationHookResult = ReturnType<
  typeof useSetDefaultPaymentSourceMutation
>;
export type SetDefaultPaymentSourceMutationResult = Apollo.MutationResult<
  SetDefaultPaymentSourceMutation
>;
export type SetDefaultPaymentSourceMutationOptions = Apollo.BaseMutationOptions<
  SetDefaultPaymentSourceMutation,
  SetDefaultPaymentSourceMutationVariables
>;
export const GetQuestionAnswerForLocationDocument = gql`
  query getQuestionAnswerForLocation($input: AnswerInputType!) {
    results: getQuestionAnswerForLocation(input: $input) {
      id
      answer
      publishedAt
      lawfirm {
        commercialName
        id
        legalName
        logoUrl
        website
      }
    }
  }
`;

/**
 * __useGetQuestionAnswerForLocationQuery__
 *
 * To run a query within a React component, call `useGetQuestionAnswerForLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionAnswerForLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionAnswerForLocationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetQuestionAnswerForLocationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetQuestionAnswerForLocationQuery,
    GetQuestionAnswerForLocationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetQuestionAnswerForLocationQuery,
    GetQuestionAnswerForLocationQueryVariables
  >(GetQuestionAnswerForLocationDocument, options);
}
export function useGetQuestionAnswerForLocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetQuestionAnswerForLocationQuery,
    GetQuestionAnswerForLocationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetQuestionAnswerForLocationQuery,
    GetQuestionAnswerForLocationQueryVariables
  >(GetQuestionAnswerForLocationDocument, options);
}
export type GetQuestionAnswerForLocationQueryHookResult = ReturnType<
  typeof useGetQuestionAnswerForLocationQuery
>;
export type GetQuestionAnswerForLocationLazyQueryHookResult = ReturnType<
  typeof useGetQuestionAnswerForLocationLazyQuery
>;
export type GetQuestionAnswerForLocationQueryResult = Apollo.QueryResult<
  GetQuestionAnswerForLocationQuery,
  GetQuestionAnswerForLocationQueryVariables
>;
export const GetCategoriesWithExistingAnswersDocument = gql`
  query getCategoriesWithExistingAnswers($input: CategoriesInput!) {
    results: getCategoriesWithExistingAnswers(input: $input) {
      name
      slug
      topics {
        name
        slug
      }
    }
  }
`;

/**
 * __useGetCategoriesWithExistingAnswersQuery__
 *
 * To run a query within a React component, call `useGetCategoriesWithExistingAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesWithExistingAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesWithExistingAnswersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCategoriesWithExistingAnswersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCategoriesWithExistingAnswersQuery,
    GetCategoriesWithExistingAnswersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCategoriesWithExistingAnswersQuery,
    GetCategoriesWithExistingAnswersQueryVariables
  >(GetCategoriesWithExistingAnswersDocument, options);
}
export function useGetCategoriesWithExistingAnswersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCategoriesWithExistingAnswersQuery,
    GetCategoriesWithExistingAnswersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCategoriesWithExistingAnswersQuery,
    GetCategoriesWithExistingAnswersQueryVariables
  >(GetCategoriesWithExistingAnswersDocument, options);
}
export type GetCategoriesWithExistingAnswersQueryHookResult = ReturnType<
  typeof useGetCategoriesWithExistingAnswersQuery
>;
export type GetCategoriesWithExistingAnswersLazyQueryHookResult = ReturnType<
  typeof useGetCategoriesWithExistingAnswersLazyQuery
>;
export type GetCategoriesWithExistingAnswersQueryResult = Apollo.QueryResult<
  GetCategoriesWithExistingAnswersQuery,
  GetCategoriesWithExistingAnswersQueryVariables
>;
export const GetClubMembershipDocument = gql`
  query GetClubMembership(
    $filterBy: EnumMembershipFilterBy
    $filterValue: String!
  ) {
    getClubMembership(filterBy: $filterBy, filterValue: $filterValue) {
      id
      memberId
      email
      firstName
      lastName
      mailingCountry
      membershipEnrollDate
      membershipStatus
      membershipTier
      inactiveAt
      ownerId
    }
  }
`;

/**
 * __useGetClubMembershipQuery__
 *
 * To run a query within a React component, call `useGetClubMembershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubMembershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubMembershipQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      filterValue: // value for 'filterValue'
 *   },
 * });
 */
export function useGetClubMembershipQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClubMembershipQuery,
    GetClubMembershipQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClubMembershipQuery,
    GetClubMembershipQueryVariables
  >(GetClubMembershipDocument, options);
}
export function useGetClubMembershipLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClubMembershipQuery,
    GetClubMembershipQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClubMembershipQuery,
    GetClubMembershipQueryVariables
  >(GetClubMembershipDocument, options);
}
export type GetClubMembershipQueryHookResult = ReturnType<
  typeof useGetClubMembershipQuery
>;
export type GetClubMembershipLazyQueryHookResult = ReturnType<
  typeof useGetClubMembershipLazyQuery
>;
export type GetClubMembershipQueryResult = Apollo.QueryResult<
  GetClubMembershipQuery,
  GetClubMembershipQueryVariables
>;
export const GetAllClubMembershipsDocument = gql`
  query getAllClubMemberships(
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    getAllClubMemberships(
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      edges {
        node {
          id
          memberId
          email
          firstName
          accountName
          lastName
          mailingCountry
          membershipEnrollDate
          membershipStatus
          membershipTier
          inactiveAt
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

/**
 * __useGetAllClubMembershipsQuery__
 *
 * To run a query within a React component, call `useGetAllClubMembershipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllClubMembershipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllClubMembershipsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useGetAllClubMembershipsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllClubMembershipsQuery,
    GetAllClubMembershipsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllClubMembershipsQuery,
    GetAllClubMembershipsQueryVariables
  >(GetAllClubMembershipsDocument, options);
}
export function useGetAllClubMembershipsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllClubMembershipsQuery,
    GetAllClubMembershipsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllClubMembershipsQuery,
    GetAllClubMembershipsQueryVariables
  >(GetAllClubMembershipsDocument, options);
}
export type GetAllClubMembershipsQueryHookResult = ReturnType<
  typeof useGetAllClubMembershipsQuery
>;
export type GetAllClubMembershipsLazyQueryHookResult = ReturnType<
  typeof useGetAllClubMembershipsLazyQuery
>;
export type GetAllClubMembershipsQueryResult = Apollo.QueryResult<
  GetAllClubMembershipsQuery,
  GetAllClubMembershipsQueryVariables
>;
export const ClubMembershipReportDocument = gql`
  query ClubMembershipReport(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filters: MembershipFilters!
  ) {
    clubMembershipReport(
      first: $first
      last: $last
      before: $before
      after: $after
      filters: $filters
    ) {
      edges {
        cursor
        node {
          accountName
          certificateid
          contactid
          memberId
          email
          firstName
          lastName
          mailingCountry
          membershipEnrollDate
          membershipStatus
          membershipTier
          inactiveAt
          providerid
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
    }
    clubMembershipReportCount(filters: $filters)
  }
`;

/**
 * __useClubMembershipReportQuery__
 *
 * To run a query within a React component, call `useClubMembershipReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useClubMembershipReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClubMembershipReportQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useClubMembershipReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClubMembershipReportQuery,
    ClubMembershipReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClubMembershipReportQuery,
    ClubMembershipReportQueryVariables
  >(ClubMembershipReportDocument, options);
}
export function useClubMembershipReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClubMembershipReportQuery,
    ClubMembershipReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClubMembershipReportQuery,
    ClubMembershipReportQueryVariables
  >(ClubMembershipReportDocument, options);
}
export type ClubMembershipReportQueryHookResult = ReturnType<
  typeof useClubMembershipReportQuery
>;
export type ClubMembershipReportLazyQueryHookResult = ReturnType<
  typeof useClubMembershipReportLazyQuery
>;
export type ClubMembershipReportQueryResult = Apollo.QueryResult<
  ClubMembershipReportQuery,
  ClubMembershipReportQueryVariables
>;
export const GetClubMembershipsByProviderIdPaginatedDocument = gql`
  query getClubMembershipsByProviderIdPaginated(
    $first: Int = 10
    $last: Int = 10
    $before: String
    $after: String
    $providerid: String!
  ) {
    getClubMembershipsByProvideridPaginated(
      first: $first
      last: $last
      before: $before
      after: $after
      providerid: $providerid
    ) {
      edges {
        cursor
        node {
          id
          accountName
          certificateid
          contactid
          memberId
          email
          firstName
          lastName
          mailingCountry
          membershipEnrollDate
          membershipStatus
          membershipTier
          inactiveAt
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

/**
 * __useGetClubMembershipsByProviderIdPaginatedQuery__
 *
 * To run a query within a React component, call `useGetClubMembershipsByProviderIdPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubMembershipsByProviderIdPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubMembershipsByProviderIdPaginatedQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      providerid: // value for 'providerid'
 *   },
 * });
 */
export function useGetClubMembershipsByProviderIdPaginatedQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClubMembershipsByProviderIdPaginatedQuery,
    GetClubMembershipsByProviderIdPaginatedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClubMembershipsByProviderIdPaginatedQuery,
    GetClubMembershipsByProviderIdPaginatedQueryVariables
  >(GetClubMembershipsByProviderIdPaginatedDocument, options);
}
export function useGetClubMembershipsByProviderIdPaginatedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClubMembershipsByProviderIdPaginatedQuery,
    GetClubMembershipsByProviderIdPaginatedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClubMembershipsByProviderIdPaginatedQuery,
    GetClubMembershipsByProviderIdPaginatedQueryVariables
  >(GetClubMembershipsByProviderIdPaginatedDocument, options);
}
export type GetClubMembershipsByProviderIdPaginatedQueryHookResult = ReturnType<
  typeof useGetClubMembershipsByProviderIdPaginatedQuery
>;
export type GetClubMembershipsByProviderIdPaginatedLazyQueryHookResult = ReturnType<
  typeof useGetClubMembershipsByProviderIdPaginatedLazyQuery
>;
export type GetClubMembershipsByProviderIdPaginatedQueryResult = Apollo.QueryResult<
  GetClubMembershipsByProviderIdPaginatedQuery,
  GetClubMembershipsByProviderIdPaginatedQueryVariables
>;
export const GetClubMembershipsByCertificateIdPaginatedDocument = gql`
  query getClubMembershipsByCertificateIdPaginated(
    $first: Int = 10
    $last: Int = 10
    $before: String
    $after: String
    $certificateid: String!
  ) {
    getClubMembershipsByCertificateidPaginated(
      first: $first
      last: $last
      before: $before
      after: $after
      certificateid: $certificateid
    ) {
      edges {
        cursor
        node {
          id
          accountName
          certificateid
          contactid
          memberId
          email
          firstName
          lastName
          mailingCountry
          membershipEnrollDate
          membershipStatus
          membershipTier
          inactiveAt
          tripDuration
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

/**
 * __useGetClubMembershipsByCertificateIdPaginatedQuery__
 *
 * To run a query within a React component, call `useGetClubMembershipsByCertificateIdPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubMembershipsByCertificateIdPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubMembershipsByCertificateIdPaginatedQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      certificateid: // value for 'certificateid'
 *   },
 * });
 */
export function useGetClubMembershipsByCertificateIdPaginatedQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClubMembershipsByCertificateIdPaginatedQuery,
    GetClubMembershipsByCertificateIdPaginatedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClubMembershipsByCertificateIdPaginatedQuery,
    GetClubMembershipsByCertificateIdPaginatedQueryVariables
  >(GetClubMembershipsByCertificateIdPaginatedDocument, options);
}
export function useGetClubMembershipsByCertificateIdPaginatedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClubMembershipsByCertificateIdPaginatedQuery,
    GetClubMembershipsByCertificateIdPaginatedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClubMembershipsByCertificateIdPaginatedQuery,
    GetClubMembershipsByCertificateIdPaginatedQueryVariables
  >(GetClubMembershipsByCertificateIdPaginatedDocument, options);
}
export type GetClubMembershipsByCertificateIdPaginatedQueryHookResult = ReturnType<
  typeof useGetClubMembershipsByCertificateIdPaginatedQuery
>;
export type GetClubMembershipsByCertificateIdPaginatedLazyQueryHookResult = ReturnType<
  typeof useGetClubMembershipsByCertificateIdPaginatedLazyQuery
>;
export type GetClubMembershipsByCertificateIdPaginatedQueryResult = Apollo.QueryResult<
  GetClubMembershipsByCertificateIdPaginatedQuery,
  GetClubMembershipsByCertificateIdPaginatedQueryVariables
>;
export const GetClubMembershipOwnersDocument = gql`
  query GetClubMembershipOwners {
    getClubMembershipOwners {
      id
      email
      profile {
        fullName
      }
    }
  }
`;

/**
 * __useGetClubMembershipOwnersQuery__
 *
 * To run a query within a React component, call `useGetClubMembershipOwnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubMembershipOwnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubMembershipOwnersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClubMembershipOwnersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClubMembershipOwnersQuery,
    GetClubMembershipOwnersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClubMembershipOwnersQuery,
    GetClubMembershipOwnersQueryVariables
  >(GetClubMembershipOwnersDocument, options);
}
export function useGetClubMembershipOwnersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClubMembershipOwnersQuery,
    GetClubMembershipOwnersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClubMembershipOwnersQuery,
    GetClubMembershipOwnersQueryVariables
  >(GetClubMembershipOwnersDocument, options);
}
export type GetClubMembershipOwnersQueryHookResult = ReturnType<
  typeof useGetClubMembershipOwnersQuery
>;
export type GetClubMembershipOwnersLazyQueryHookResult = ReturnType<
  typeof useGetClubMembershipOwnersLazyQuery
>;
export type GetClubMembershipOwnersQueryResult = Apollo.QueryResult<
  GetClubMembershipOwnersQuery,
  GetClubMembershipOwnersQueryVariables
>;
export const UploadClubMembershipDocument = gql`
  mutation UploadClubMembership($csv: [ClubMembershipInputType]!) {
    createClubMemberships(input: $csv) {
      result {
        email
        memberId
      }
      successful
      messages {
        code
        field
        message
      }
    }
  }
`;
export type UploadClubMembershipMutationFn = Apollo.MutationFunction<
  UploadClubMembershipMutation,
  UploadClubMembershipMutationVariables
>;

/**
 * __useUploadClubMembershipMutation__
 *
 * To run a mutation, you first call `useUploadClubMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadClubMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadClubMembershipMutation, { data, loading, error }] = useUploadClubMembershipMutation({
 *   variables: {
 *      csv: // value for 'csv'
 *   },
 * });
 */
export function useUploadClubMembershipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadClubMembershipMutation,
    UploadClubMembershipMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UploadClubMembershipMutation,
    UploadClubMembershipMutationVariables
  >(UploadClubMembershipDocument, options);
}
export type UploadClubMembershipMutationHookResult = ReturnType<
  typeof useUploadClubMembershipMutation
>;
export type UploadClubMembershipMutationResult = Apollo.MutationResult<
  UploadClubMembershipMutation
>;
export type UploadClubMembershipMutationOptions = Apollo.BaseMutationOptions<
  UploadClubMembershipMutation,
  UploadClubMembershipMutationVariables
>;
export const DeleteMembershipDocument = gql`
  mutation DeleteMembership($email: String!) {
    deleteMembership(email: $email) {
      result
      successful
      messages {
        code
        field
        message
      }
    }
  }
`;
export type DeleteMembershipMutationFn = Apollo.MutationFunction<
  DeleteMembershipMutation,
  DeleteMembershipMutationVariables
>;

/**
 * __useDeleteMembershipMutation__
 *
 * To run a mutation, you first call `useDeleteMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMembershipMutation, { data, loading, error }] = useDeleteMembershipMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useDeleteMembershipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMembershipMutation,
    DeleteMembershipMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMembershipMutation,
    DeleteMembershipMutationVariables
  >(DeleteMembershipDocument, options);
}
export type DeleteMembershipMutationHookResult = ReturnType<
  typeof useDeleteMembershipMutation
>;
export type DeleteMembershipMutationResult = Apollo.MutationResult<
  DeleteMembershipMutation
>;
export type DeleteMembershipMutationOptions = Apollo.BaseMutationOptions<
  DeleteMembershipMutation,
  DeleteMembershipMutationVariables
>;
export const DeleteMembershipByMemberIdDocument = gql`
  mutation DeleteMembershipByMemberId($memberId: String!) {
    deleteMembershipByMemberId(memberId: $memberId) {
      result
      successful
      messages {
        code
        field
        message
      }
    }
  }
`;
export type DeleteMembershipByMemberIdMutationFn = Apollo.MutationFunction<
  DeleteMembershipByMemberIdMutation,
  DeleteMembershipByMemberIdMutationVariables
>;

/**
 * __useDeleteMembershipByMemberIdMutation__
 *
 * To run a mutation, you first call `useDeleteMembershipByMemberIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMembershipByMemberIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMembershipByMemberIdMutation, { data, loading, error }] = useDeleteMembershipByMemberIdMutation({
 *   variables: {
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useDeleteMembershipByMemberIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMembershipByMemberIdMutation,
    DeleteMembershipByMemberIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMembershipByMemberIdMutation,
    DeleteMembershipByMemberIdMutationVariables
  >(DeleteMembershipByMemberIdDocument, options);
}
export type DeleteMembershipByMemberIdMutationHookResult = ReturnType<
  typeof useDeleteMembershipByMemberIdMutation
>;
export type DeleteMembershipByMemberIdMutationResult = Apollo.MutationResult<
  DeleteMembershipByMemberIdMutation
>;
export type DeleteMembershipByMemberIdMutationOptions = Apollo.BaseMutationOptions<
  DeleteMembershipByMemberIdMutation,
  DeleteMembershipByMemberIdMutationVariables
>;
export const DeleteClubMembershipsDocument = gql`
  mutation deleteClubMemberships($emails: [String!]!) {
    deleteClubMemberships(emails: $emails) {
      result
      successful
      messages {
        code
        field
        message
      }
    }
  }
`;
export type DeleteClubMembershipsMutationFn = Apollo.MutationFunction<
  DeleteClubMembershipsMutation,
  DeleteClubMembershipsMutationVariables
>;

/**
 * __useDeleteClubMembershipsMutation__
 *
 * To run a mutation, you first call `useDeleteClubMembershipsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClubMembershipsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClubMembershipsMutation, { data, loading, error }] = useDeleteClubMembershipsMutation({
 *   variables: {
 *      emails: // value for 'emails'
 *   },
 * });
 */
export function useDeleteClubMembershipsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteClubMembershipsMutation,
    DeleteClubMembershipsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteClubMembershipsMutation,
    DeleteClubMembershipsMutationVariables
  >(DeleteClubMembershipsDocument, options);
}
export type DeleteClubMembershipsMutationHookResult = ReturnType<
  typeof useDeleteClubMembershipsMutation
>;
export type DeleteClubMembershipsMutationResult = Apollo.MutationResult<
  DeleteClubMembershipsMutation
>;
export type DeleteClubMembershipsMutationOptions = Apollo.BaseMutationOptions<
  DeleteClubMembershipsMutation,
  DeleteClubMembershipsMutationVariables
>;
export const GetTlaContractDocument = gql`
  query GetTlaContract {
    result: getTlaContract {
      bucketDays
      bucketMonths
      bucketYears
      startDate
      endDate
    }
  }
`;

/**
 * __useGetTlaContractQuery__
 *
 * To run a query within a React component, call `useGetTlaContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTlaContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTlaContractQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTlaContractQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTlaContractQuery,
    GetTlaContractQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTlaContractQuery, GetTlaContractQueryVariables>(
    GetTlaContractDocument,
    options
  );
}
export function useGetTlaContractLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTlaContractQuery,
    GetTlaContractQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTlaContractQuery, GetTlaContractQueryVariables>(
    GetTlaContractDocument,
    options
  );
}
export type GetTlaContractQueryHookResult = ReturnType<
  typeof useGetTlaContractQuery
>;
export type GetTlaContractLazyQueryHookResult = ReturnType<
  typeof useGetTlaContractLazyQuery
>;
export type GetTlaContractQueryResult = Apollo.QueryResult<
  GetTlaContractQuery,
  GetTlaContractQueryVariables
>;
export const GetAllTlaContractsDocument = gql`
  query GetAllTlaContracts {
    result: getAllTlaContracts {
      startDate
      bucketDays
      bucketMonths
      bucketYears
      user {
        id
        email
        profile {
          fullName
        }
      }
    }
  }
`;

/**
 * __useGetAllTlaContractsQuery__
 *
 * To run a query within a React component, call `useGetAllTlaContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTlaContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTlaContractsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllTlaContractsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllTlaContractsQuery,
    GetAllTlaContractsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllTlaContractsQuery,
    GetAllTlaContractsQueryVariables
  >(GetAllTlaContractsDocument, options);
}
export function useGetAllTlaContractsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllTlaContractsQuery,
    GetAllTlaContractsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllTlaContractsQuery,
    GetAllTlaContractsQueryVariables
  >(GetAllTlaContractsDocument, options);
}
export type GetAllTlaContractsQueryHookResult = ReturnType<
  typeof useGetAllTlaContractsQuery
>;
export type GetAllTlaContractsLazyQueryHookResult = ReturnType<
  typeof useGetAllTlaContractsLazyQuery
>;
export type GetAllTlaContractsQueryResult = Apollo.QueryResult<
  GetAllTlaContractsQuery,
  GetAllTlaContractsQueryVariables
>;
export const CreateTmDocument = gql`
  mutation CreateTM($email: String!) {
    result: createTm(email: $email) {
      messages {
        message
        code
      }
      successful
      result
    }
  }
`;
export type CreateTmMutationFn = Apollo.MutationFunction<
  CreateTmMutation,
  CreateTmMutationVariables
>;

/**
 * __useCreateTmMutation__
 *
 * To run a mutation, you first call `useCreateTmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTmMutation, { data, loading, error }] = useCreateTmMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreateTmMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTmMutation,
    CreateTmMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTmMutation, CreateTmMutationVariables>(
    CreateTmDocument,
    options
  );
}
export type CreateTmMutationHookResult = ReturnType<typeof useCreateTmMutation>;
export type CreateTmMutationResult = Apollo.MutationResult<CreateTmMutation>;
export type CreateTmMutationOptions = Apollo.BaseMutationOptions<
  CreateTmMutation,
  CreateTmMutationVariables
>;
export const CountriesDocument = gql`
  query countries {
    countries {
      id
      name
      slug
      flag
      code
    }
  }
`;

/**
 * __useCountriesQuery__
 *
 * To run a query within a React component, call `useCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountriesQuery(
  baseOptions?: Apollo.QueryHookOptions<CountriesQuery, CountriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CountriesQuery, CountriesQueryVariables>(
    CountriesDocument,
    options
  );
}
export function useCountriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CountriesQuery,
    CountriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CountriesQuery, CountriesQueryVariables>(
    CountriesDocument,
    options
  );
}
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<
  typeof useCountriesLazyQuery
>;
export type CountriesQueryResult = Apollo.QueryResult<
  CountriesQuery,
  CountriesQueryVariables
>;
export const GetAllActiveCountriesGroupedDocument = gql`
  query getAllActiveCountriesGrouped {
    getAllActiveCountriesGrouped {
      countries {
        code
        flag
        id
        name
        slug
        tlaAvailable
        regions {
          code
          id
          name
          slug
          tlaAvailable
        }
      }
      name
      slug
    }
  }
`;

/**
 * __useGetAllActiveCountriesGroupedQuery__
 *
 * To run a query within a React component, call `useGetAllActiveCountriesGroupedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllActiveCountriesGroupedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllActiveCountriesGroupedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllActiveCountriesGroupedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllActiveCountriesGroupedQuery,
    GetAllActiveCountriesGroupedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllActiveCountriesGroupedQuery,
    GetAllActiveCountriesGroupedQueryVariables
  >(GetAllActiveCountriesGroupedDocument, options);
}
export function useGetAllActiveCountriesGroupedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllActiveCountriesGroupedQuery,
    GetAllActiveCountriesGroupedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllActiveCountriesGroupedQuery,
    GetAllActiveCountriesGroupedQueryVariables
  >(GetAllActiveCountriesGroupedDocument, options);
}
export type GetAllActiveCountriesGroupedQueryHookResult = ReturnType<
  typeof useGetAllActiveCountriesGroupedQuery
>;
export type GetAllActiveCountriesGroupedLazyQueryHookResult = ReturnType<
  typeof useGetAllActiveCountriesGroupedLazyQuery
>;
export type GetAllActiveCountriesGroupedQueryResult = Apollo.QueryResult<
  GetAllActiveCountriesGroupedQuery,
  GetAllActiveCountriesGroupedQueryVariables
>;
export const GetAllCountriesWithAssistanceGroupedDocument = gql`
  query getAllCountriesWithAssistanceGrouped {
    getAllCountriesWithAssistanceGrouped {
      countries {
        code
        flag
        id
        name
        slug
        tlaAvailable
        regions {
          code
          id
          name
          slug
          tlaAvailable
        }
      }
      name
      slug
    }
  }
`;

/**
 * __useGetAllCountriesWithAssistanceGroupedQuery__
 *
 * To run a query within a React component, call `useGetAllCountriesWithAssistanceGroupedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCountriesWithAssistanceGroupedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCountriesWithAssistanceGroupedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCountriesWithAssistanceGroupedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllCountriesWithAssistanceGroupedQuery,
    GetAllCountriesWithAssistanceGroupedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllCountriesWithAssistanceGroupedQuery,
    GetAllCountriesWithAssistanceGroupedQueryVariables
  >(GetAllCountriesWithAssistanceGroupedDocument, options);
}
export function useGetAllCountriesWithAssistanceGroupedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllCountriesWithAssistanceGroupedQuery,
    GetAllCountriesWithAssistanceGroupedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllCountriesWithAssistanceGroupedQuery,
    GetAllCountriesWithAssistanceGroupedQueryVariables
  >(GetAllCountriesWithAssistanceGroupedDocument, options);
}
export type GetAllCountriesWithAssistanceGroupedQueryHookResult = ReturnType<
  typeof useGetAllCountriesWithAssistanceGroupedQuery
>;
export type GetAllCountriesWithAssistanceGroupedLazyQueryHookResult = ReturnType<
  typeof useGetAllCountriesWithAssistanceGroupedLazyQuery
>;
export type GetAllCountriesWithAssistanceGroupedQueryResult = Apollo.QueryResult<
  GetAllCountriesWithAssistanceGroupedQuery,
  GetAllCountriesWithAssistanceGroupedQueryVariables
>;
export const GetAllCountriesWithAnswersDocument = gql`
  query getAllCountriesWithAnswers {
    results: getAllCountriesWithAnswers {
      name
      slug
      countries {
        code
        flag
        id
        name
        slug
        tlaAvailable
        regions {
          code
          id
          name
          slug
          tlaAvailable
        }
      }
    }
  }
`;

/**
 * __useGetAllCountriesWithAnswersQuery__
 *
 * To run a query within a React component, call `useGetAllCountriesWithAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCountriesWithAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCountriesWithAnswersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCountriesWithAnswersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllCountriesWithAnswersQuery,
    GetAllCountriesWithAnswersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllCountriesWithAnswersQuery,
    GetAllCountriesWithAnswersQueryVariables
  >(GetAllCountriesWithAnswersDocument, options);
}
export function useGetAllCountriesWithAnswersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllCountriesWithAnswersQuery,
    GetAllCountriesWithAnswersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllCountriesWithAnswersQuery,
    GetAllCountriesWithAnswersQueryVariables
  >(GetAllCountriesWithAnswersDocument, options);
}
export type GetAllCountriesWithAnswersQueryHookResult = ReturnType<
  typeof useGetAllCountriesWithAnswersQuery
>;
export type GetAllCountriesWithAnswersLazyQueryHookResult = ReturnType<
  typeof useGetAllCountriesWithAnswersLazyQuery
>;
export type GetAllCountriesWithAnswersQueryResult = Apollo.QueryResult<
  GetAllCountriesWithAnswersQuery,
  GetAllCountriesWithAnswersQueryVariables
>;
export const GetCountryBySlugDocument = gql`
  query getCountryBySlug($slug: String!) {
    results: getCountryBySlug(slug: $slug) {
      code
      flag
      id
      name
      slug
    }
  }
`;

/**
 * __useGetCountryBySlugQuery__
 *
 * To run a query within a React component, call `useGetCountryBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetCountryBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCountryBySlugQuery,
    GetCountryBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCountryBySlugQuery, GetCountryBySlugQueryVariables>(
    GetCountryBySlugDocument,
    options
  );
}
export function useGetCountryBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCountryBySlugQuery,
    GetCountryBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCountryBySlugQuery,
    GetCountryBySlugQueryVariables
  >(GetCountryBySlugDocument, options);
}
export type GetCountryBySlugQueryHookResult = ReturnType<
  typeof useGetCountryBySlugQuery
>;
export type GetCountryBySlugLazyQueryHookResult = ReturnType<
  typeof useGetCountryBySlugLazyQuery
>;
export type GetCountryBySlugQueryResult = Apollo.QueryResult<
  GetCountryBySlugQuery,
  GetCountryBySlugQueryVariables
>;
export const SearchUserDocument = gql`
  query SearchUser($email: String!) {
    results: getUserByEmail(email: $email) {
      email
      profile {
        firstName
        lastName
      }
    }
  }
`;

/**
 * __useSearchUserQuery__
 *
 * To run a query within a React component, call `useSearchUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUserQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSearchUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchUserQuery,
    SearchUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchUserQuery, SearchUserQueryVariables>(
    SearchUserDocument,
    options
  );
}
export function useSearchUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchUserQuery,
    SearchUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchUserQuery, SearchUserQueryVariables>(
    SearchUserDocument,
    options
  );
}
export type SearchUserQueryHookResult = ReturnType<typeof useSearchUserQuery>;
export type SearchUserLazyQueryHookResult = ReturnType<
  typeof useSearchUserLazyQuery
>;
export type SearchUserQueryResult = Apollo.QueryResult<
  SearchUserQuery,
  SearchUserQueryVariables
>;
export const GrantRoleDocument = gql`
  mutation GrantRole($email: String!, $role: RoleGrants!) {
    result: grantRole(email: $email, role: $role) {
      messages {
        message
      }
      successful
    }
  }
`;
export type GrantRoleMutationFn = Apollo.MutationFunction<
  GrantRoleMutation,
  GrantRoleMutationVariables
>;

/**
 * __useGrantRoleMutation__
 *
 * To run a mutation, you first call `useGrantRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGrantRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [grantRoleMutation, { data, loading, error }] = useGrantRoleMutation({
 *   variables: {
 *      email: // value for 'email'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useGrantRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GrantRoleMutation,
    GrantRoleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GrantRoleMutation, GrantRoleMutationVariables>(
    GrantRoleDocument,
    options
  );
}
export type GrantRoleMutationHookResult = ReturnType<
  typeof useGrantRoleMutation
>;
export type GrantRoleMutationResult = Apollo.MutationResult<GrantRoleMutation>;
export type GrantRoleMutationOptions = Apollo.BaseMutationOptions<
  GrantRoleMutation,
  GrantRoleMutationVariables
>;
export const GetEmergencyContactsDocument = gql`
  query GetEmergencyContacts {
    result: getMyContacts {
      lastName
      firstName
      email
      phone
      id
    }
  }
`;

/**
 * __useGetEmergencyContactsQuery__
 *
 * To run a query within a React component, call `useGetEmergencyContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmergencyContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmergencyContactsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmergencyContactsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEmergencyContactsQuery,
    GetEmergencyContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEmergencyContactsQuery,
    GetEmergencyContactsQueryVariables
  >(GetEmergencyContactsDocument, options);
}
export function useGetEmergencyContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEmergencyContactsQuery,
    GetEmergencyContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEmergencyContactsQuery,
    GetEmergencyContactsQueryVariables
  >(GetEmergencyContactsDocument, options);
}
export type GetEmergencyContactsQueryHookResult = ReturnType<
  typeof useGetEmergencyContactsQuery
>;
export type GetEmergencyContactsLazyQueryHookResult = ReturnType<
  typeof useGetEmergencyContactsLazyQuery
>;
export type GetEmergencyContactsQueryResult = Apollo.QueryResult<
  GetEmergencyContactsQuery,
  GetEmergencyContactsQueryVariables
>;
export const GetTravelerContactsByEmailDocument = gql`
  query GetTravelerContactsByEmail($email: String!) {
    result: getTravelerContactsByEmail(email: $email) {
      lastName
      firstName
      email
      phone
      id
    }
  }
`;

/**
 * __useGetTravelerContactsByEmailQuery__
 *
 * To run a query within a React component, call `useGetTravelerContactsByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTravelerContactsByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTravelerContactsByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetTravelerContactsByEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTravelerContactsByEmailQuery,
    GetTravelerContactsByEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTravelerContactsByEmailQuery,
    GetTravelerContactsByEmailQueryVariables
  >(GetTravelerContactsByEmailDocument, options);
}
export function useGetTravelerContactsByEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTravelerContactsByEmailQuery,
    GetTravelerContactsByEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTravelerContactsByEmailQuery,
    GetTravelerContactsByEmailQueryVariables
  >(GetTravelerContactsByEmailDocument, options);
}
export type GetTravelerContactsByEmailQueryHookResult = ReturnType<
  typeof useGetTravelerContactsByEmailQuery
>;
export type GetTravelerContactsByEmailLazyQueryHookResult = ReturnType<
  typeof useGetTravelerContactsByEmailLazyQuery
>;
export type GetTravelerContactsByEmailQueryResult = Apollo.QueryResult<
  GetTravelerContactsByEmailQuery,
  GetTravelerContactsByEmailQueryVariables
>;
export const CreateEmergencyContactDocument = gql`
  mutation CreateEmergencyContact($input: UserContactsInput) {
    result: createContacts(input: $input) {
      messages {
        message
        code
        field
      }
      successful
    }
  }
`;
export type CreateEmergencyContactMutationFn = Apollo.MutationFunction<
  CreateEmergencyContactMutation,
  CreateEmergencyContactMutationVariables
>;

/**
 * __useCreateEmergencyContactMutation__
 *
 * To run a mutation, you first call `useCreateEmergencyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmergencyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmergencyContactMutation, { data, loading, error }] = useCreateEmergencyContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEmergencyContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEmergencyContactMutation,
    CreateEmergencyContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateEmergencyContactMutation,
    CreateEmergencyContactMutationVariables
  >(CreateEmergencyContactDocument, options);
}
export type CreateEmergencyContactMutationHookResult = ReturnType<
  typeof useCreateEmergencyContactMutation
>;
export type CreateEmergencyContactMutationResult = Apollo.MutationResult<
  CreateEmergencyContactMutation
>;
export type CreateEmergencyContactMutationOptions = Apollo.BaseMutationOptions<
  CreateEmergencyContactMutation,
  CreateEmergencyContactMutationVariables
>;
export const UpdateEmergencyContactDocument = gql`
  mutation UpdateEmergencyContact($id: ID, $input: UserContactsInput) {
    result: updateContacts(id: $id, input: $input) {
      messages {
        message
        code
        field
      }
      successful
    }
  }
`;
export type UpdateEmergencyContactMutationFn = Apollo.MutationFunction<
  UpdateEmergencyContactMutation,
  UpdateEmergencyContactMutationVariables
>;

/**
 * __useUpdateEmergencyContactMutation__
 *
 * To run a mutation, you first call `useUpdateEmergencyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmergencyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmergencyContactMutation, { data, loading, error }] = useUpdateEmergencyContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmergencyContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEmergencyContactMutation,
    UpdateEmergencyContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateEmergencyContactMutation,
    UpdateEmergencyContactMutationVariables
  >(UpdateEmergencyContactDocument, options);
}
export type UpdateEmergencyContactMutationHookResult = ReturnType<
  typeof useUpdateEmergencyContactMutation
>;
export type UpdateEmergencyContactMutationResult = Apollo.MutationResult<
  UpdateEmergencyContactMutation
>;
export type UpdateEmergencyContactMutationOptions = Apollo.BaseMutationOptions<
  UpdateEmergencyContactMutation,
  UpdateEmergencyContactMutationVariables
>;
export const DeleteEmergencyContactDocument = gql`
  mutation DeleteEmergencyContact($id: ID) {
    result: deleteContacts(id: $id) {
      messages {
        message
        code
        field
      }
      successful
    }
  }
`;
export type DeleteEmergencyContactMutationFn = Apollo.MutationFunction<
  DeleteEmergencyContactMutation,
  DeleteEmergencyContactMutationVariables
>;

/**
 * __useDeleteEmergencyContactMutation__
 *
 * To run a mutation, you first call `useDeleteEmergencyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmergencyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmergencyContactMutation, { data, loading, error }] = useDeleteEmergencyContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEmergencyContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteEmergencyContactMutation,
    DeleteEmergencyContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteEmergencyContactMutation,
    DeleteEmergencyContactMutationVariables
  >(DeleteEmergencyContactDocument, options);
}
export type DeleteEmergencyContactMutationHookResult = ReturnType<
  typeof useDeleteEmergencyContactMutation
>;
export type DeleteEmergencyContactMutationResult = Apollo.MutationResult<
  DeleteEmergencyContactMutation
>;
export type DeleteEmergencyContactMutationOptions = Apollo.BaseMutationOptions<
  DeleteEmergencyContactMutation,
  DeleteEmergencyContactMutationVariables
>;
export const GetMyInvitedUsersDocument = gql`
  query getMyInvitedUsers {
    getMyInvitedUsers {
      email
    }
  }
`;

/**
 * __useGetMyInvitedUsersQuery__
 *
 * To run a query within a React component, call `useGetMyInvitedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyInvitedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyInvitedUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyInvitedUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyInvitedUsersQuery,
    GetMyInvitedUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyInvitedUsersQuery,
    GetMyInvitedUsersQueryVariables
  >(GetMyInvitedUsersDocument, options);
}
export function useGetMyInvitedUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyInvitedUsersQuery,
    GetMyInvitedUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyInvitedUsersQuery,
    GetMyInvitedUsersQueryVariables
  >(GetMyInvitedUsersDocument, options);
}
export type GetMyInvitedUsersQueryHookResult = ReturnType<
  typeof useGetMyInvitedUsersQuery
>;
export type GetMyInvitedUsersLazyQueryHookResult = ReturnType<
  typeof useGetMyInvitedUsersLazyQuery
>;
export type GetMyInvitedUsersQueryResult = Apollo.QueryResult<
  GetMyInvitedUsersQuery,
  GetMyInvitedUsersQueryVariables
>;
export const CreateUserInvitationDocument = gql`
  mutation createUserInvitation($email: String!) {
    createUserInvitation(email: $email) {
      messages {
        message
      }
      result
      successful
    }
  }
`;
export type CreateUserInvitationMutationFn = Apollo.MutationFunction<
  CreateUserInvitationMutation,
  CreateUserInvitationMutationVariables
>;

/**
 * __useCreateUserInvitationMutation__
 *
 * To run a mutation, you first call `useCreateUserInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserInvitationMutation, { data, loading, error }] = useCreateUserInvitationMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreateUserInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserInvitationMutation,
    CreateUserInvitationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUserInvitationMutation,
    CreateUserInvitationMutationVariables
  >(CreateUserInvitationDocument, options);
}
export type CreateUserInvitationMutationHookResult = ReturnType<
  typeof useCreateUserInvitationMutation
>;
export type CreateUserInvitationMutationResult = Apollo.MutationResult<
  CreateUserInvitationMutation
>;
export type CreateUserInvitationMutationOptions = Apollo.BaseMutationOptions<
  CreateUserInvitationMutation,
  CreateUserInvitationMutationVariables
>;
export const GetInvitedUserByTokenDocument = gql`
  query getInvitedUserByToken($token: String!) {
    getInvitedUserByToken(invitationToken: $token) {
      email
    }
  }
`;

/**
 * __useGetInvitedUserByTokenQuery__
 *
 * To run a query within a React component, call `useGetInvitedUserByTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvitedUserByTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvitedUserByTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetInvitedUserByTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInvitedUserByTokenQuery,
    GetInvitedUserByTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetInvitedUserByTokenQuery,
    GetInvitedUserByTokenQueryVariables
  >(GetInvitedUserByTokenDocument, options);
}
export function useGetInvitedUserByTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvitedUserByTokenQuery,
    GetInvitedUserByTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInvitedUserByTokenQuery,
    GetInvitedUserByTokenQueryVariables
  >(GetInvitedUserByTokenDocument, options);
}
export type GetInvitedUserByTokenQueryHookResult = ReturnType<
  typeof useGetInvitedUserByTokenQuery
>;
export type GetInvitedUserByTokenLazyQueryHookResult = ReturnType<
  typeof useGetInvitedUserByTokenLazyQuery
>;
export type GetInvitedUserByTokenQueryResult = Apollo.QueryResult<
  GetInvitedUserByTokenQuery,
  GetInvitedUserByTokenQueryVariables
>;
export const AcceptUserInvitationDocument = gql`
  mutation acceptUserInvitation(
    $authId: String!
    $invitationToken: String!
    $firstName: String!
    $lastName: String!
  ) {
    acceptUserInvitation(
      authId: $authId
      invitationToken: $invitationToken
      firstName: $firstName
      lastName: $lastName
    ) {
      messages {
        message
      }
      result
      successful
    }
  }
`;
export type AcceptUserInvitationMutationFn = Apollo.MutationFunction<
  AcceptUserInvitationMutation,
  AcceptUserInvitationMutationVariables
>;

/**
 * __useAcceptUserInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptUserInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptUserInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptUserInvitationMutation, { data, loading, error }] = useAcceptUserInvitationMutation({
 *   variables: {
 *      authId: // value for 'authId'
 *      invitationToken: // value for 'invitationToken'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useAcceptUserInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptUserInvitationMutation,
    AcceptUserInvitationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcceptUserInvitationMutation,
    AcceptUserInvitationMutationVariables
  >(AcceptUserInvitationDocument, options);
}
export type AcceptUserInvitationMutationHookResult = ReturnType<
  typeof useAcceptUserInvitationMutation
>;
export type AcceptUserInvitationMutationResult = Apollo.MutationResult<
  AcceptUserInvitationMutation
>;
export type AcceptUserInvitationMutationOptions = Apollo.BaseMutationOptions<
  AcceptUserInvitationMutation,
  AcceptUserInvitationMutationVariables
>;
export const ActiveLanguagesDocument = gql`
  query activeLanguages {
    getActiveLanguages {
      ...language
    }
  }
  ${LanguageFragmentDoc}
`;

/**
 * __useActiveLanguagesQuery__
 *
 * To run a query within a React component, call `useActiveLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveLanguagesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ActiveLanguagesQuery,
    ActiveLanguagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActiveLanguagesQuery, ActiveLanguagesQueryVariables>(
    ActiveLanguagesDocument,
    options
  );
}
export function useActiveLanguagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActiveLanguagesQuery,
    ActiveLanguagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ActiveLanguagesQuery,
    ActiveLanguagesQueryVariables
  >(ActiveLanguagesDocument, options);
}
export type ActiveLanguagesQueryHookResult = ReturnType<
  typeof useActiveLanguagesQuery
>;
export type ActiveLanguagesLazyQueryHookResult = ReturnType<
  typeof useActiveLanguagesLazyQuery
>;
export type ActiveLanguagesQueryResult = Apollo.QueryResult<
  ActiveLanguagesQuery,
  ActiveLanguagesQueryVariables
>;
export const GetAllLanguagesDocument = gql`
  query GetAllLanguages {
    getAllLanguages {
      ...language
    }
  }
  ${LanguageFragmentDoc}
`;

/**
 * __useGetAllLanguagesQuery__
 *
 * To run a query within a React component, call `useGetAllLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllLanguagesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllLanguagesQuery,
    GetAllLanguagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllLanguagesQuery, GetAllLanguagesQueryVariables>(
    GetAllLanguagesDocument,
    options
  );
}
export function useGetAllLanguagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllLanguagesQuery,
    GetAllLanguagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllLanguagesQuery,
    GetAllLanguagesQueryVariables
  >(GetAllLanguagesDocument, options);
}
export type GetAllLanguagesQueryHookResult = ReturnType<
  typeof useGetAllLanguagesQuery
>;
export type GetAllLanguagesLazyQueryHookResult = ReturnType<
  typeof useGetAllLanguagesLazyQuery
>;
export type GetAllLanguagesQueryResult = Apollo.QueryResult<
  GetAllLanguagesQuery,
  GetAllLanguagesQueryVariables
>;
export const GetMyPurchasesDocument = gql`
  query GetMyPurchases {
    results: getMyPurchases {
      description
      details {
        paymentSource {
          cardBrand
          cardLast4
          id
        }
        total
      }
      id
      insertedAt
      invoiceUrl
      paid
    }
  }
`;

/**
 * __useGetMyPurchasesQuery__
 *
 * To run a query within a React component, call `useGetMyPurchasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyPurchasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyPurchasesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyPurchasesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyPurchasesQuery,
    GetMyPurchasesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyPurchasesQuery, GetMyPurchasesQueryVariables>(
    GetMyPurchasesDocument,
    options
  );
}
export function useGetMyPurchasesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyPurchasesQuery,
    GetMyPurchasesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyPurchasesQuery, GetMyPurchasesQueryVariables>(
    GetMyPurchasesDocument,
    options
  );
}
export type GetMyPurchasesQueryHookResult = ReturnType<
  typeof useGetMyPurchasesQuery
>;
export type GetMyPurchasesLazyQueryHookResult = ReturnType<
  typeof useGetMyPurchasesLazyQuery
>;
export type GetMyPurchasesQueryResult = Apollo.QueryResult<
  GetMyPurchasesQuery,
  GetMyPurchasesQueryVariables
>;
export const GetActiveQuestionsByCategoryTopicDocument = gql`
  query getActiveQuestionsByCategoryTopic(
    $first: Int
    $last: Int
    $filter: QuestionFilterType
    $after: String
    $before: String
    $order: EnumSortOrder
  ) {
    results: getActiveQuestionsByCategoryTopic(
      first: $first
      last: $last
      filter: $filter
      after: $after
      before: $before
      order: $order
    ) {
      edges {
        cursor
        node {
          id
          question
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

/**
 * __useGetActiveQuestionsByCategoryTopicQuery__
 *
 * To run a query within a React component, call `useGetActiveQuestionsByCategoryTopicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveQuestionsByCategoryTopicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveQuestionsByCategoryTopicQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      filter: // value for 'filter'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetActiveQuestionsByCategoryTopicQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetActiveQuestionsByCategoryTopicQuery,
    GetActiveQuestionsByCategoryTopicQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActiveQuestionsByCategoryTopicQuery,
    GetActiveQuestionsByCategoryTopicQueryVariables
  >(GetActiveQuestionsByCategoryTopicDocument, options);
}
export function useGetActiveQuestionsByCategoryTopicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActiveQuestionsByCategoryTopicQuery,
    GetActiveQuestionsByCategoryTopicQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActiveQuestionsByCategoryTopicQuery,
    GetActiveQuestionsByCategoryTopicQueryVariables
  >(GetActiveQuestionsByCategoryTopicDocument, options);
}
export type GetActiveQuestionsByCategoryTopicQueryHookResult = ReturnType<
  typeof useGetActiveQuestionsByCategoryTopicQuery
>;
export type GetActiveQuestionsByCategoryTopicLazyQueryHookResult = ReturnType<
  typeof useGetActiveQuestionsByCategoryTopicLazyQuery
>;
export type GetActiveQuestionsByCategoryTopicQueryResult = Apollo.QueryResult<
  GetActiveQuestionsByCategoryTopicQuery,
  GetActiveQuestionsByCategoryTopicQueryVariables
>;
export const ListMyReferralCodesDocument = gql`
  query ListMyReferralCodes {
    results: listMyReferralCodes {
      code
      expires
      active
    }
  }
`;

/**
 * __useListMyReferralCodesQuery__
 *
 * To run a query within a React component, call `useListMyReferralCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMyReferralCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMyReferralCodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListMyReferralCodesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListMyReferralCodesQuery,
    ListMyReferralCodesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListMyReferralCodesQuery,
    ListMyReferralCodesQueryVariables
  >(ListMyReferralCodesDocument, options);
}
export function useListMyReferralCodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListMyReferralCodesQuery,
    ListMyReferralCodesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListMyReferralCodesQuery,
    ListMyReferralCodesQueryVariables
  >(ListMyReferralCodesDocument, options);
}
export type ListMyReferralCodesQueryHookResult = ReturnType<
  typeof useListMyReferralCodesQuery
>;
export type ListMyReferralCodesLazyQueryHookResult = ReturnType<
  typeof useListMyReferralCodesLazyQuery
>;
export type ListMyReferralCodesQueryResult = Apollo.QueryResult<
  ListMyReferralCodesQuery,
  ListMyReferralCodesQueryVariables
>;
export const ListCreatedReferralsDocument = gql`
  query ListCreatedReferrals {
    results: listCreatedReferrals {
      ...createdReferral
    }
  }
  ${CreatedReferralFragmentDoc}
`;

/**
 * __useListCreatedReferralsQuery__
 *
 * To run a query within a React component, call `useListCreatedReferralsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCreatedReferralsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCreatedReferralsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListCreatedReferralsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListCreatedReferralsQuery,
    ListCreatedReferralsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListCreatedReferralsQuery,
    ListCreatedReferralsQueryVariables
  >(ListCreatedReferralsDocument, options);
}
export function useListCreatedReferralsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListCreatedReferralsQuery,
    ListCreatedReferralsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListCreatedReferralsQuery,
    ListCreatedReferralsQueryVariables
  >(ListCreatedReferralsDocument, options);
}
export type ListCreatedReferralsQueryHookResult = ReturnType<
  typeof useListCreatedReferralsQuery
>;
export type ListCreatedReferralsLazyQueryHookResult = ReturnType<
  typeof useListCreatedReferralsLazyQuery
>;
export type ListCreatedReferralsQueryResult = Apollo.QueryResult<
  ListCreatedReferralsQuery,
  ListCreatedReferralsQueryVariables
>;
export const GenerateReferralCodeDocument = gql`
  mutation GenerateReferralCode($email: String!, $input: CreateReferralInput) {
    results: createReferralCodeFor(for: $email, input: $input) {
      successful
      result {
        code
      }
      messages {
        message
      }
    }
  }
`;
export type GenerateReferralCodeMutationFn = Apollo.MutationFunction<
  GenerateReferralCodeMutation,
  GenerateReferralCodeMutationVariables
>;

/**
 * __useGenerateReferralCodeMutation__
 *
 * To run a mutation, you first call `useGenerateReferralCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateReferralCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateReferralCodeMutation, { data, loading, error }] = useGenerateReferralCodeMutation({
 *   variables: {
 *      email: // value for 'email'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateReferralCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateReferralCodeMutation,
    GenerateReferralCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateReferralCodeMutation,
    GenerateReferralCodeMutationVariables
  >(GenerateReferralCodeDocument, options);
}
export type GenerateReferralCodeMutationHookResult = ReturnType<
  typeof useGenerateReferralCodeMutation
>;
export type GenerateReferralCodeMutationResult = Apollo.MutationResult<
  GenerateReferralCodeMutation
>;
export type GenerateReferralCodeMutationOptions = Apollo.BaseMutationOptions<
  GenerateReferralCodeMutation,
  GenerateReferralCodeMutationVariables
>;
export const ToggleReferralCodeDocument = gql`
  mutation ToggleReferralCode($referralCode: String!) {
    results: toggleReferralCode(referralCode: $referralCode) {
      successful
      result {
        ...createdReferral
      }
    }
  }
  ${CreatedReferralFragmentDoc}
`;
export type ToggleReferralCodeMutationFn = Apollo.MutationFunction<
  ToggleReferralCodeMutation,
  ToggleReferralCodeMutationVariables
>;

/**
 * __useToggleReferralCodeMutation__
 *
 * To run a mutation, you first call `useToggleReferralCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleReferralCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleReferralCodeMutation, { data, loading, error }] = useToggleReferralCodeMutation({
 *   variables: {
 *      referralCode: // value for 'referralCode'
 *   },
 * });
 */
export function useToggleReferralCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleReferralCodeMutation,
    ToggleReferralCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ToggleReferralCodeMutation,
    ToggleReferralCodeMutationVariables
  >(ToggleReferralCodeDocument, options);
}
export type ToggleReferralCodeMutationHookResult = ReturnType<
  typeof useToggleReferralCodeMutation
>;
export type ToggleReferralCodeMutationResult = Apollo.MutationResult<
  ToggleReferralCodeMutation
>;
export type ToggleReferralCodeMutationOptions = Apollo.BaseMutationOptions<
  ToggleReferralCodeMutation,
  ToggleReferralCodeMutationVariables
>;
export const ListMyTravelLegalAssistanceDocument = gql`
  query ListMyTravelLegalAssistance {
    results: listMyTravelLegalAssistance {
      status
      destinations {
        country {
          code
          id
          flag
          name
          slug
        }
      }
      endDate
      assistType
      purchase {
        details {
          total
        }
      }
      referralCode
      startDate
      tlaNumber
    }
  }
`;

/**
 * __useListMyTravelLegalAssistanceQuery__
 *
 * To run a query within a React component, call `useListMyTravelLegalAssistanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMyTravelLegalAssistanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMyTravelLegalAssistanceQuery({
 *   variables: {
 *   },
 * });
 */
export function useListMyTravelLegalAssistanceQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListMyTravelLegalAssistanceQuery,
    ListMyTravelLegalAssistanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListMyTravelLegalAssistanceQuery,
    ListMyTravelLegalAssistanceQueryVariables
  >(ListMyTravelLegalAssistanceDocument, options);
}
export function useListMyTravelLegalAssistanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListMyTravelLegalAssistanceQuery,
    ListMyTravelLegalAssistanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListMyTravelLegalAssistanceQuery,
    ListMyTravelLegalAssistanceQueryVariables
  >(ListMyTravelLegalAssistanceDocument, options);
}
export type ListMyTravelLegalAssistanceQueryHookResult = ReturnType<
  typeof useListMyTravelLegalAssistanceQuery
>;
export type ListMyTravelLegalAssistanceLazyQueryHookResult = ReturnType<
  typeof useListMyTravelLegalAssistanceLazyQuery
>;
export type ListMyTravelLegalAssistanceQueryResult = Apollo.QueryResult<
  ListMyTravelLegalAssistanceQuery,
  ListMyTravelLegalAssistanceQueryVariables
>;
export const ListMyPurchasedTravelLegalAssistanceDocument = gql`
  query ListMyPurchasedTravelLegalAssistance {
    results: listMyPurchasedTravelLegalAssistance {
      status
      referralCode
      startDate
      tlaNumber
      endDate
      assistType
      insertedAt
      destinations {
        country {
          code
          id
          flag
          name
          slug
        }
      }
      purchase {
        details {
          total
        }
        insertedAt
      }
      travelers {
        email
        fullName
        firstName
        lastName
        countryOfResidency {
          code
          name
        }
      }
      deducted
      user {
        profile {
          fullName
          firstName
          lastName
        }
        email
      }
    }
  }
`;

/**
 * __useListMyPurchasedTravelLegalAssistanceQuery__
 *
 * To run a query within a React component, call `useListMyPurchasedTravelLegalAssistanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMyPurchasedTravelLegalAssistanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMyPurchasedTravelLegalAssistanceQuery({
 *   variables: {
 *   },
 * });
 */
export function useListMyPurchasedTravelLegalAssistanceQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListMyPurchasedTravelLegalAssistanceQuery,
    ListMyPurchasedTravelLegalAssistanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListMyPurchasedTravelLegalAssistanceQuery,
    ListMyPurchasedTravelLegalAssistanceQueryVariables
  >(ListMyPurchasedTravelLegalAssistanceDocument, options);
}
export function useListMyPurchasedTravelLegalAssistanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListMyPurchasedTravelLegalAssistanceQuery,
    ListMyPurchasedTravelLegalAssistanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListMyPurchasedTravelLegalAssistanceQuery,
    ListMyPurchasedTravelLegalAssistanceQueryVariables
  >(ListMyPurchasedTravelLegalAssistanceDocument, options);
}
export type ListMyPurchasedTravelLegalAssistanceQueryHookResult = ReturnType<
  typeof useListMyPurchasedTravelLegalAssistanceQuery
>;
export type ListMyPurchasedTravelLegalAssistanceLazyQueryHookResult = ReturnType<
  typeof useListMyPurchasedTravelLegalAssistanceLazyQuery
>;
export type ListMyPurchasedTravelLegalAssistanceQueryResult = Apollo.QueryResult<
  ListMyPurchasedTravelLegalAssistanceQuery,
  ListMyPurchasedTravelLegalAssistanceQueryVariables
>;
export const GetTlaByRefNumberDocument = gql`
  query GetTlaByRefNumber($ref: String!) {
    getTlaByRefNumber(refNumber: $ref) {
      status
      referralCode
      assistType
      startDate
      tlaNumber
      destinations {
        country {
          code
          id
          flag
          name
          slug
        }
      }
      endDate
      purchase {
        details {
          total
        }
        insertedAt
      }
      insertedAt
      destinations {
        country {
          code
          name
        }
      }
      travelers {
        email
        fullName
        firstName
        lastName
        countryOfResidency {
          code
          name
        }
      }
      user {
        profile {
          firstName
          lastName
        }
        email
      }
      isMonthlyTla
    }
  }
`;

/**
 * __useGetTlaByRefNumberQuery__
 *
 * To run a query within a React component, call `useGetTlaByRefNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTlaByRefNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTlaByRefNumberQuery({
 *   variables: {
 *      ref: // value for 'ref'
 *   },
 * });
 */
export function useGetTlaByRefNumberQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTlaByRefNumberQuery,
    GetTlaByRefNumberQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTlaByRefNumberQuery,
    GetTlaByRefNumberQueryVariables
  >(GetTlaByRefNumberDocument, options);
}
export function useGetTlaByRefNumberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTlaByRefNumberQuery,
    GetTlaByRefNumberQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTlaByRefNumberQuery,
    GetTlaByRefNumberQueryVariables
  >(GetTlaByRefNumberDocument, options);
}
export type GetTlaByRefNumberQueryHookResult = ReturnType<
  typeof useGetTlaByRefNumberQuery
>;
export type GetTlaByRefNumberLazyQueryHookResult = ReturnType<
  typeof useGetTlaByRefNumberLazyQuery
>;
export type GetTlaByRefNumberQueryResult = Apollo.QueryResult<
  GetTlaByRefNumberQuery,
  GetTlaByRefNumberQueryVariables
>;
export const GetTlaReportsDocument = gql`
  query GetTLAReports($first: Int!, $referralCode: String!) {
    results: getTlaReports(first: $first, referralCode: $referralCode) {
      edges {
        node {
          travelers {
            email
            fullName
            firstName
            lastName
            countryOfResidency {
              code
              name
            }
          }
          user {
            email
            profile {
              avatar
              dob
              firstName
              lastName
              phone
            }
          }
          status
          assistType
          deducted
          groupCode
          price
          destinations {
            country {
              name
            }
          }
          endDate
          referralCode
          startDate
          tlaNumber
          insertedAt
          purchase {
            details {
              total
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      tlaSummary(referralCode: $referralCode) {
        amount
        total
      }
    }
  }
`;

/**
 * __useGetTlaReportsQuery__
 *
 * To run a query within a React component, call `useGetTlaReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTlaReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTlaReportsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      referralCode: // value for 'referralCode'
 *   },
 * });
 */
export function useGetTlaReportsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTlaReportsQuery,
    GetTlaReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTlaReportsQuery, GetTlaReportsQueryVariables>(
    GetTlaReportsDocument,
    options
  );
}
export function useGetTlaReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTlaReportsQuery,
    GetTlaReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTlaReportsQuery, GetTlaReportsQueryVariables>(
    GetTlaReportsDocument,
    options
  );
}
export type GetTlaReportsQueryHookResult = ReturnType<
  typeof useGetTlaReportsQuery
>;
export type GetTlaReportsLazyQueryHookResult = ReturnType<
  typeof useGetTlaReportsLazyQuery
>;
export type GetTlaReportsQueryResult = Apollo.QueryResult<
  GetTlaReportsQuery,
  GetTlaReportsQueryVariables
>;
export const GetMyGroupTlaDocument = gql`
  query GetMyGroupTla(
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    listTlasGroups(first: $first, last: $last, before: $before, after: $after) {
      edges {
        node {
          groupCode
          totalTlas
          total
          description
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

/**
 * __useGetMyGroupTlaQuery__
 *
 * To run a query within a React component, call `useGetMyGroupTlaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyGroupTlaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyGroupTlaQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useGetMyGroupTlaQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyGroupTlaQuery,
    GetMyGroupTlaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyGroupTlaQuery, GetMyGroupTlaQueryVariables>(
    GetMyGroupTlaDocument,
    options
  );
}
export function useGetMyGroupTlaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyGroupTlaQuery,
    GetMyGroupTlaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyGroupTlaQuery, GetMyGroupTlaQueryVariables>(
    GetMyGroupTlaDocument,
    options
  );
}
export type GetMyGroupTlaQueryHookResult = ReturnType<
  typeof useGetMyGroupTlaQuery
>;
export type GetMyGroupTlaLazyQueryHookResult = ReturnType<
  typeof useGetMyGroupTlaLazyQuery
>;
export type GetMyGroupTlaQueryResult = Apollo.QueryResult<
  GetMyGroupTlaQuery,
  GetMyGroupTlaQueryVariables
>;
export const GetAllLegalAssistsDocument = gql`
  query getAllLegalAssists(
    $first: Int = 50
    $last: Int = 10
    $before: String
    $after: String
    $filter: TlaFilterType
  ) {
    getAllLegalAssists(
      first: $first
      last: $last
      before: $before
      after: $after
      filter: $filter
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        node {
          id
          insertedAt
          tlaNumber
          startDate
          endDate
          status
          assistType
          referralCode
          deducted
          price
          destinations {
            country {
              code
              name
            }
          }
          travelers {
            email
            firstName
            lastName
            countryOfResidency {
              code
              name
            }
          }
          purchase {
            details {
              total
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetAllLegalAssistsQuery__
 *
 * To run a query within a React component, call `useGetAllLegalAssistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllLegalAssistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllLegalAssistsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAllLegalAssistsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllLegalAssistsQuery,
    GetAllLegalAssistsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllLegalAssistsQuery,
    GetAllLegalAssistsQueryVariables
  >(GetAllLegalAssistsDocument, options);
}
export function useGetAllLegalAssistsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllLegalAssistsQuery,
    GetAllLegalAssistsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllLegalAssistsQuery,
    GetAllLegalAssistsQueryVariables
  >(GetAllLegalAssistsDocument, options);
}
export type GetAllLegalAssistsQueryHookResult = ReturnType<
  typeof useGetAllLegalAssistsQuery
>;
export type GetAllLegalAssistsLazyQueryHookResult = ReturnType<
  typeof useGetAllLegalAssistsLazyQuery
>;
export type GetAllLegalAssistsQueryResult = Apollo.QueryResult<
  GetAllLegalAssistsQuery,
  GetAllLegalAssistsQueryVariables
>;
export const GetMonthlyAssistDocument = gql`
  query getMonthlyAssist(
    $first: Int = 50
    $last: Int = 10
    $before: String
    $after: String
    $filter: TlaFilterType
  ) {
    getMonthlyTlas(
      first: $first
      last: $last
      before: $before
      after: $after
      filter: $filter
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        node {
          id
          insertedAt
          tlaNumber
          startDate
          endDate
          status
          assistType
          referralCode
          destinations {
            country {
              code
              name
            }
          }
          user {
            email
            profile {
              firstName
              lastName
            }
          }
          travelers {
            email
            fullName
            firstName
            lastName
            countryOfResidency {
              code
              name
            }
          }
          purchase {
            details {
              total
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetMonthlyAssistQuery__
 *
 * To run a query within a React component, call `useGetMonthlyAssistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMonthlyAssistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMonthlyAssistQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetMonthlyAssistQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMonthlyAssistQuery,
    GetMonthlyAssistQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMonthlyAssistQuery, GetMonthlyAssistQueryVariables>(
    GetMonthlyAssistDocument,
    options
  );
}
export function useGetMonthlyAssistLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMonthlyAssistQuery,
    GetMonthlyAssistQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMonthlyAssistQuery,
    GetMonthlyAssistQueryVariables
  >(GetMonthlyAssistDocument, options);
}
export type GetMonthlyAssistQueryHookResult = ReturnType<
  typeof useGetMonthlyAssistQuery
>;
export type GetMonthlyAssistLazyQueryHookResult = ReturnType<
  typeof useGetMonthlyAssistLazyQuery
>;
export type GetMonthlyAssistQueryResult = Apollo.QueryResult<
  GetMonthlyAssistQuery,
  GetMonthlyAssistQueryVariables
>;
export const GetMyTravelManagersDocument = gql`
  query GetMyTravelManagers($first: Int) {
    getMyTravelManagers(first: $first) {
      edges {
        node {
          email
          id
          profile {
            fullName
            phone
            avatar
          }
        }
      }
    }
  }
`;

/**
 * __useGetMyTravelManagersQuery__
 *
 * To run a query within a React component, call `useGetMyTravelManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyTravelManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyTravelManagersQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetMyTravelManagersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyTravelManagersQuery,
    GetMyTravelManagersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyTravelManagersQuery,
    GetMyTravelManagersQueryVariables
  >(GetMyTravelManagersDocument, options);
}
export function useGetMyTravelManagersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyTravelManagersQuery,
    GetMyTravelManagersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyTravelManagersQuery,
    GetMyTravelManagersQueryVariables
  >(GetMyTravelManagersDocument, options);
}
export type GetMyTravelManagersQueryHookResult = ReturnType<
  typeof useGetMyTravelManagersQuery
>;
export type GetMyTravelManagersLazyQueryHookResult = ReturnType<
  typeof useGetMyTravelManagersLazyQuery
>;
export type GetMyTravelManagersQueryResult = Apollo.QueryResult<
  GetMyTravelManagersQuery,
  GetMyTravelManagersQueryVariables
>;
export const QuoteTlaDocument = gql`
  query quoteTla(
    $destinationCodes: [String]!
    $endDate: String!
    $startDate: String!
  ) {
    basicTLAQuote: quoteTravelLegalAssistance(
      input: {
        destinationCodes: $destinationCodes
        endDate: $endDate
        startDate: $startDate
        quoteType: RETAIL_BASIC
      }
    ) {
      days
      destinations {
        code
        flag
        name
        slug
      }
      endDate
      months
      startDate
      total
    }
    vipTLAQuote: quoteTravelLegalAssistance(
      input: {
        destinationCodes: $destinationCodes
        endDate: $endDate
        startDate: $startDate
        quoteType: RETAIL_VIP
      }
    ) {
      days
      destinations {
        code
        flag
        name
        slug
      }
      endDate
      months
      startDate
      total
    }
  }
`;

/**
 * __useQuoteTlaQuery__
 *
 * To run a query within a React component, call `useQuoteTlaQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuoteTlaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuoteTlaQuery({
 *   variables: {
 *      destinationCodes: // value for 'destinationCodes'
 *      endDate: // value for 'endDate'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useQuoteTlaQuery(
  baseOptions: Apollo.QueryHookOptions<QuoteTlaQuery, QuoteTlaQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QuoteTlaQuery, QuoteTlaQueryVariables>(
    QuoteTlaDocument,
    options
  );
}
export function useQuoteTlaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QuoteTlaQuery,
    QuoteTlaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QuoteTlaQuery, QuoteTlaQueryVariables>(
    QuoteTlaDocument,
    options
  );
}
export type QuoteTlaQueryHookResult = ReturnType<typeof useQuoteTlaQuery>;
export type QuoteTlaLazyQueryHookResult = ReturnType<
  typeof useQuoteTlaLazyQuery
>;
export type QuoteTlaQueryResult = Apollo.QueryResult<
  QuoteTlaQuery,
  QuoteTlaQueryVariables
>;
export const BuyTravelLegalAssistancesDocument = gql`
  mutation buyTravelLegalAssistances(
    $paymentMethod: String!
    $input: [MultiTlaInput]!
  ) {
    buyTlasAsGift(input: $input, paymentMethod: $paymentMethod) {
      messages {
        code
        field
        message
        options {
          key
          value
        }
        template
      }
      result {
        destinations {
          country {
            code
            flag
            name
            slug
          }
        }
        endDate
        purchase {
          bankStatement
          description
          details {
            total
          }
          invoiceUrl
          paid
        }
        assistType
        referralCode
        startDate
        tlaNumber
        groupCode
        travelers {
          email
          fullName
        }
      }
      successful
    }
  }
`;
export type BuyTravelLegalAssistancesMutationFn = Apollo.MutationFunction<
  BuyTravelLegalAssistancesMutation,
  BuyTravelLegalAssistancesMutationVariables
>;

/**
 * __useBuyTravelLegalAssistancesMutation__
 *
 * To run a mutation, you first call `useBuyTravelLegalAssistancesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuyTravelLegalAssistancesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buyTravelLegalAssistancesMutation, { data, loading, error }] = useBuyTravelLegalAssistancesMutation({
 *   variables: {
 *      paymentMethod: // value for 'paymentMethod'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuyTravelLegalAssistancesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BuyTravelLegalAssistancesMutation,
    BuyTravelLegalAssistancesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BuyTravelLegalAssistancesMutation,
    BuyTravelLegalAssistancesMutationVariables
  >(BuyTravelLegalAssistancesDocument, options);
}
export type BuyTravelLegalAssistancesMutationHookResult = ReturnType<
  typeof useBuyTravelLegalAssistancesMutation
>;
export type BuyTravelLegalAssistancesMutationResult = Apollo.MutationResult<
  BuyTravelLegalAssistancesMutation
>;
export type BuyTravelLegalAssistancesMutationOptions = Apollo.BaseMutationOptions<
  BuyTravelLegalAssistancesMutation,
  BuyTravelLegalAssistancesMutationVariables
>;
export const BuyTravelLegalAssistanceDocument = gql`
  mutation buyTravelLegalAssistance(
    $paymentMethod: String!
    $destinationCodes: [String]!
    $endDate: String!
    $startDate: String!
    $type: QuoteTypesEnum!
    $referralCode: String
  ) {
    buyTravelLegalAssistance(
      input: {
        destinationCodes: $destinationCodes
        endDate: $endDate
        startDate: $startDate
        quoteType: $type
        referralCode: $referralCode
      }
      paymentMethod: $paymentMethod
    ) {
      messages {
        code
        field
        message
        options {
          key
          value
        }
        template
      }
      result {
        destinations {
          country {
            code
            flag
            name
            slug
          }
        }
        endDate
        purchase {
          bankStatement
          description
          details {
            total
          }
          invoiceUrl
          paid
        }
        referralCode
        startDate
        tlaNumber
        user {
          email
          profile {
            avatar
            dob
            firstName
            lastName
            phone
          }
        }
      }
      successful
    }
  }
`;
export type BuyTravelLegalAssistanceMutationFn = Apollo.MutationFunction<
  BuyTravelLegalAssistanceMutation,
  BuyTravelLegalAssistanceMutationVariables
>;

/**
 * __useBuyTravelLegalAssistanceMutation__
 *
 * To run a mutation, you first call `useBuyTravelLegalAssistanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuyTravelLegalAssistanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buyTravelLegalAssistanceMutation, { data, loading, error }] = useBuyTravelLegalAssistanceMutation({
 *   variables: {
 *      paymentMethod: // value for 'paymentMethod'
 *      destinationCodes: // value for 'destinationCodes'
 *      endDate: // value for 'endDate'
 *      startDate: // value for 'startDate'
 *      type: // value for 'type'
 *      referralCode: // value for 'referralCode'
 *   },
 * });
 */
export function useBuyTravelLegalAssistanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BuyTravelLegalAssistanceMutation,
    BuyTravelLegalAssistanceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BuyTravelLegalAssistanceMutation,
    BuyTravelLegalAssistanceMutationVariables
  >(BuyTravelLegalAssistanceDocument, options);
}
export type BuyTravelLegalAssistanceMutationHookResult = ReturnType<
  typeof useBuyTravelLegalAssistanceMutation
>;
export type BuyTravelLegalAssistanceMutationResult = Apollo.MutationResult<
  BuyTravelLegalAssistanceMutation
>;
export type BuyTravelLegalAssistanceMutationOptions = Apollo.BaseMutationOptions<
  BuyTravelLegalAssistanceMutation,
  BuyTravelLegalAssistanceMutationVariables
>;
export const BuyMultiTlaDocument = gql`
  mutation buyMultiTla(
    $input: [MultiTlaInput]
    $paymentMethod: String!
    $referralCode: String
    $quoteType: QuoteTypesEnum!
  ) {
    buyMultiTla(
      input: $input
      paymentMethod: $paymentMethod
      quoteType: $quoteType
      referralCode: $referralCode
    ) {
      messages {
        code
        message
      }
      successful
      result {
        destinations {
          country {
            code
          }
        }
        endDate
        startDate
        referralCode
        assistType
        tlaNumber
        travelers {
          countryOfResidency {
            id
            code
            name
          }
          email
          firstName
          lastName
          fullName
        }
        purchase {
          bankStatement
          details {
            paymentSource {
              cardBrand
              cardLast4
              expYear
              expMonth
              id
            }
            total
          }
          id
        }
      }
    }
  }
`;
export type BuyMultiTlaMutationFn = Apollo.MutationFunction<
  BuyMultiTlaMutation,
  BuyMultiTlaMutationVariables
>;

/**
 * __useBuyMultiTlaMutation__
 *
 * To run a mutation, you first call `useBuyMultiTlaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuyMultiTlaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buyMultiTlaMutation, { data, loading, error }] = useBuyMultiTlaMutation({
 *   variables: {
 *      input: // value for 'input'
 *      paymentMethod: // value for 'paymentMethod'
 *      referralCode: // value for 'referralCode'
 *      quoteType: // value for 'quoteType'
 *   },
 * });
 */
export function useBuyMultiTlaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BuyMultiTlaMutation,
    BuyMultiTlaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BuyMultiTlaMutation, BuyMultiTlaMutationVariables>(
    BuyMultiTlaDocument,
    options
  );
}
export type BuyMultiTlaMutationHookResult = ReturnType<
  typeof useBuyMultiTlaMutation
>;
export type BuyMultiTlaMutationResult = Apollo.MutationResult<
  BuyMultiTlaMutation
>;
export type BuyMultiTlaMutationOptions = Apollo.BaseMutationOptions<
  BuyMultiTlaMutation,
  BuyMultiTlaMutationVariables
>;
export const BuyMultiTlaWithReferralDocument = gql`
  mutation buyMultiTlaWithReferral(
    $input: [MultiTlaInput]
    $paymentMethod: String!
    $referralCode: String
    $quoteType: QuoteTypesEnum!
  ) {
    buyMultiTla(
      input: $input
      paymentMethod: $paymentMethod
      quoteType: $quoteType
      referralCode: $referralCode
    ) {
      messages {
        code
        message
      }
      successful
      result {
        destinations {
          country {
            code
          }
        }
        endDate
        startDate
        tlaNumber
        assistType
        travelers {
          countryOfResidency {
            id
            code
            name
          }
          email
          firstName
          lastName
        }
      }
    }
  }
`;
export type BuyMultiTlaWithReferralMutationFn = Apollo.MutationFunction<
  BuyMultiTlaWithReferralMutation,
  BuyMultiTlaWithReferralMutationVariables
>;

/**
 * __useBuyMultiTlaWithReferralMutation__
 *
 * To run a mutation, you first call `useBuyMultiTlaWithReferralMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuyMultiTlaWithReferralMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buyMultiTlaWithReferralMutation, { data, loading, error }] = useBuyMultiTlaWithReferralMutation({
 *   variables: {
 *      input: // value for 'input'
 *      paymentMethod: // value for 'paymentMethod'
 *      referralCode: // value for 'referralCode'
 *      quoteType: // value for 'quoteType'
 *   },
 * });
 */
export function useBuyMultiTlaWithReferralMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BuyMultiTlaWithReferralMutation,
    BuyMultiTlaWithReferralMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BuyMultiTlaWithReferralMutation,
    BuyMultiTlaWithReferralMutationVariables
  >(BuyMultiTlaWithReferralDocument, options);
}
export type BuyMultiTlaWithReferralMutationHookResult = ReturnType<
  typeof useBuyMultiTlaWithReferralMutation
>;
export type BuyMultiTlaWithReferralMutationResult = Apollo.MutationResult<
  BuyMultiTlaWithReferralMutation
>;
export type BuyMultiTlaWithReferralMutationOptions = Apollo.BaseMutationOptions<
  BuyMultiTlaWithReferralMutation,
  BuyMultiTlaWithReferralMutationVariables
>;
export const ViewerDocument = gql`
  query viewer {
    viewer {
      email
      permissions
      roles
      subscription {
        type
      }
      affiliate {
        id
      }
      profile {
        firstName
        lastName
        fullName
        avatar
        dob
        phone
      }
      settings
    }
  }
`;

/**
 * __useViewerQuery__
 *
 * To run a query within a React component, call `useViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerQuery(
  baseOptions?: Apollo.QueryHookOptions<ViewerQuery, ViewerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ViewerQuery, ViewerQueryVariables>(
    ViewerDocument,
    options
  );
}
export function useViewerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ViewerQuery, ViewerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ViewerQuery, ViewerQueryVariables>(
    ViewerDocument,
    options
  );
}
export type ViewerQueryHookResult = ReturnType<typeof useViewerQuery>;
export type ViewerLazyQueryHookResult = ReturnType<typeof useViewerLazyQuery>;
export type ViewerQueryResult = Apollo.QueryResult<
  ViewerQuery,
  ViewerQueryVariables
>;
export const SigninDocument = gql`
  mutation signin($accessToken: String!) {
    authenticate(accessToken: $accessToken) {
      successful
      messages {
        ...validationMessage
      }
    }
  }
  ${ValidationMessageFragmentDoc}
`;
export type SigninMutationFn = Apollo.MutationFunction<
  SigninMutation,
  SigninMutationVariables
>;

/**
 * __useSigninMutation__
 *
 * To run a mutation, you first call `useSigninMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSigninMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signinMutation, { data, loading, error }] = useSigninMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useSigninMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SigninMutation,
    SigninMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SigninMutation, SigninMutationVariables>(
    SigninDocument,
    options
  );
}
export type SigninMutationHookResult = ReturnType<typeof useSigninMutation>;
export type SigninMutationResult = Apollo.MutationResult<SigninMutation>;
export type SigninMutationOptions = Apollo.BaseMutationOptions<
  SigninMutation,
  SigninMutationVariables
>;
export const SignoutDocument = gql`
  mutation signout {
    signOut
  }
`;
export type SignoutMutationFn = Apollo.MutationFunction<
  SignoutMutation,
  SignoutMutationVariables
>;

/**
 * __useSignoutMutation__
 *
 * To run a mutation, you first call `useSignoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signoutMutation, { data, loading, error }] = useSignoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useSignoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignoutMutation,
    SignoutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignoutMutation, SignoutMutationVariables>(
    SignoutDocument,
    options
  );
}
export type SignoutMutationHookResult = ReturnType<typeof useSignoutMutation>;
export type SignoutMutationResult = Apollo.MutationResult<SignoutMutation>;
export type SignoutMutationOptions = Apollo.BaseMutationOptions<
  SignoutMutation,
  SignoutMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation resetPassword($email: String!) {
    resetPassword(email: $email)
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult = Apollo.MutationResult<
  ResetPasswordMutation
>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const UpdateProfileDocument = gql`
  mutation UpdateProfile($input: UpdateProfileInput) {
    updateProfile(input: $input) {
      messages {
        ...validationMessage
      }
      result {
        profile {
          ...profile
        }
      }
      successful
    }
  }
  ${ValidationMessageFragmentDoc}
  ${ProfileFragmentDoc}
`;
export type UpdateProfileMutationFn = Apollo.MutationFunction<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProfileMutation,
    UpdateProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProfileMutation,
    UpdateProfileMutationVariables
  >(UpdateProfileDocument, options);
}
export type UpdateProfileMutationHookResult = ReturnType<
  typeof useUpdateProfileMutation
>;
export type UpdateProfileMutationResult = Apollo.MutationResult<
  UpdateProfileMutation
>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
>;
export const SendVerificationEmailDocument = gql`
  mutation SendVerificationEmail($email: String!) {
    sendVerificationEmail(email: $email) {
      messages {
        ...validationMessage
      }
      successful
      result
    }
  }
  ${ValidationMessageFragmentDoc}
`;
export type SendVerificationEmailMutationFn = Apollo.MutationFunction<
  SendVerificationEmailMutation,
  SendVerificationEmailMutationVariables
>;

/**
 * __useSendVerificationEmailMutation__
 *
 * To run a mutation, you first call `useSendVerificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendVerificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendVerificationEmailMutation, { data, loading, error }] = useSendVerificationEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendVerificationEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendVerificationEmailMutation,
    SendVerificationEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendVerificationEmailMutation,
    SendVerificationEmailMutationVariables
  >(SendVerificationEmailDocument, options);
}
export type SendVerificationEmailMutationHookResult = ReturnType<
  typeof useSendVerificationEmailMutation
>;
export type SendVerificationEmailMutationResult = Apollo.MutationResult<
  SendVerificationEmailMutation
>;
export type SendVerificationEmailMutationOptions = Apollo.BaseMutationOptions<
  SendVerificationEmailMutation,
  SendVerificationEmailMutationVariables
>;
