import { createClient } from 'contentful';
import { capitalize } from '@roo_src/helpers';
import { gql } from '@apollo/client';

export const cClient = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});

export const initialValues: any = {
  contentful: {
    featureBox: [],
    featuredLawyer: [],
    inside: [],
    testimonials: [],
    __typename: 'Contentful',
  },
  fetched: false,
};

interface CustomEntry {
  contentType: string;
  order?: any;
}
interface ContentfulQueries {
  contentful(_: any, { contentTypes }: any, { cache }: any): Promise<any>;
}

export const parseFields = (item: any, key?: string): CustomEntry | null => {
  const f: any = {};
  if (item == null) {
    return null;
  }
  if (item.sys && item.sys.contentType) {
    f['contentType'] = item.sys.contentType.sys.id;
    f['__typename'] = capitalize(f['contentType']);
  }
  if (
    item.sys &&
    item.sys.contentType &&
    item.sys.contentType.sys.id === 'featureBox'
  ) {
    if (item.fields['path'] === undefined) item.fields['path'] = null;
    if (item.fields['background'] === undefined) {
      item.fields['background'] = null;
    }
  }

  if (
    key === 'file' ||
    (item.contentType && item.contentType.includes('image'))
  ) {
    f['url'] = item.url;
    f['__typename'] = 'Image';
  }

  if (key === 'countryImage' || (item.sys && item.sys.type === 'Asset')) {
    f['__typename'] = 'Background';
  }
  if (item.fields) {
    Object.keys(item.fields).map((key: string) => {
      if (typeof item.fields[key] === 'object' && key !== 'details') {
        f[key] = parseFields(item.fields[key], key);
      } else {
        f[key] = item.fields[key];
      }
    });
  }

  if (Array.isArray(item)) {
    return item.reduce((acc, i) => ({
      ...acc,
      [i.contentType]: parseFields(i),
    }));
  }
  return f;
};

export const sortContent = (
  types: Array<{ contentType: string; random?: boolean; sorted?: boolean }>,
  data: CustomEntry[]
): CustomEntry[] => {
  const result: any = {};
  types.map(type => {
    const contentType = type.contentType;
    let items: any = data.filter((item: CustomEntry) => {
      return item.contentType === contentType;
    });
    items['__typename'] = type.contentType;
    if (type.random) {
      items = items[Math.floor(Math.random() * items.length)];
    }
    if (type.sorted) {
      items = items.sort((entryA: CustomEntry, entryB: CustomEntry) => {
        return entryA.order - entryB.order;
      });
    }
    result[contentType] = items;
  });
  result.__typename = 'Contentful';
  return result;
};

export const CONTENTFUL_QUERY = gql`
  query getContentful($contentTypes: [String]!) {
    contentful(contentTypes: $contentTypes) @client
  }
`;

export const getContentFulQueries = (): ContentfulQueries => {
  return {
    contentful: async (
      _: any,
      { contentTypes }: any,
      { cache }: any
    ): Promise<any> => {
      const mapped = contentTypes.map((type: any) => type.contentType);
      try {
        const result = await cClient.getEntries({
          'sys.contentType.sys.id[in]': mapped.join(','),
        });
        const parsedFields = result.items.map(item => parseFields(item));
        const content = sortContent(contentTypes, parsedFields);
        // cache
        cache.writeQuery({
          query: CONTENTFUL_QUERY,
          data: {
            contentful: content,
          },
        });
        return content;
      } catch (e) {
        return e;
      }
    },
  };
};
