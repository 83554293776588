import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { HStack, IconButton } from '@chakra-ui/react';
// import { ReactComponent as FBIcon } from '@roo_src/icons/facebook-icon.svg';
import { ReactComponent as GoogleIcon } from '@roo_src/icons/google-icon.svg';
import { useAuth0 } from '@roo_src/hooks';
import { setCookie } from '@roo_src/helpers';

const AuthSocialButtons = () => {
  const { authorize } = useAuth0();
  const location = useLocation();
  const pathname = location.pathname;
  const onAuthorize = useCallback(
    connection => {
      setCookie('path', pathname);
      authorize({
        connection,
      });
    },
    [authorize, pathname]
  );

  return (
    <HStack ml={3} spacing={2}>
      {/* <IconButton
        aria-label="Signin with FB"
        color="facebook.400"
        size="lg"
        shadow="base"
        icon={<FBIcon />}
        onClick={() => onAuthorize('facebook')}
      /> */}
      <IconButton
        size="lg"
        aria-label="Signin with Google"
        shadow="base"
        icon={<GoogleIcon />}
        onClick={() => onAuthorize('google-oauth2')}
      />
    </HStack>
  );
};

export default AuthSocialButtons;
