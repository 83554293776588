import React from 'react';
import { useField, UseFieldConfig } from 'react-final-form';
import Cascader, { CascaderProps, CascaderValueType } from '../../Cascader';
import FormControl, { BaseProps } from '../FormControl';

export type CascaderControlProps<FieldValue> = BaseProps &
  CascaderProps & {
    config?: UseFieldConfig<FieldValue>;
  };

export function CascaderControl({
  name,
  label,
  helperText,
  formLabelProps,
  formControlProps,
  config,
  ...props
}: CascaderControlProps<CascaderValueType>) {
  const { input } = useField<CascaderValueType>(name, config);

  return (
    <FormControl
      name={name}
      label={label}
      helperText={helperText}
      formLabelProps={formLabelProps}
      formControlProps={formControlProps}
    >
      <Cascader {...input} {...props} />
    </FormControl>
  );
}
