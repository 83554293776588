import React from 'react';
import { InputProps } from '@chakra-ui/react';
import { useFormValidation } from '@roo_src/hooks';
import { TextControl, TextControlProps } from '../TextControl';

export type PasswordControlProps<FieldValue> = TextControlProps<FieldValue>;

export function PasswordControl<FieldValue extends InputProps['value']>({
  name,
  label,
  helperText,
  formControlProps,
  formLabelProps,
  config,
  ...props
}: PasswordControlProps<FieldValue>) {
  const { password } = useFormValidation();
  const _config = { type: 'password', validate: password(), ...config };
  return (
    <TextControl
      name={name}
      label={label}
      helperText={helperText}
      formControlProps={formControlProps}
      formLabelProps={formLabelProps}
      config={_config}
      {...props}
    />
  );
}
