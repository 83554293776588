import { useCallback } from 'react';
import { useSignoutMutation } from '@roo_src/apollo/_hooks.generated';
import useAuth0 from './useAuth0';

export function useSignout() {
  const { signout: auth0Signout } = useAuth0();
  const [signout] = useSignoutMutation({
    onCompleted: () => {
      auth0Signout({
        clientID: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
        returnTo: process.env.REACT_APP_REDIRECT_DEFAULT,
      });
    },
  });
  const onSignout = useCallback(() => signout(), [signout]);
  return onSignout;
}

export default useSignout;
