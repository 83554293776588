/* eslint-disable */
import { useEffect } from 'react';

export const useFacebookPixel = (pixelId: string) => {
  const setupFacebookPixelTag = () => {
    if (!pixelId) return;
    const noScript = document.createElement('noscript');
    const image = document.createElement('img');
    image.src = `https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1`;
    image.height = 1;
    image.width = 1;
    noScript.append(image);
    document.head.append(noScript);
    return noScript;
  };

  useEffect(() => {
    const pixelTag = setupFacebookPixelTag();
    if (pixelId) {
      (function(f: any, b: any, e: any, v: any, n?: any, t?: any, s?: any) {
        if (f.fbq) return;
        n = f.fbq = function() {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js'
      );

      (window as any).fbq('init', pixelId);
      (window as any).fbq('track', 'PageView');
    }

    return () => {
      if (pixelTag) pixelTag.remove();
    };
  }, []);
};
